import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'
import { DefaultAvatar } from '../../../../../components/ui/DefaultAvatar'
import {
  COLORS,
  SOURCE,
  labelsBySalaryCurrency,
} from '../../../../../constants'
import { ReactComponent as CoinsSvg } from '../../../../../icons/coins.svg'
import classes from './CandidateCard.module.scss'

const CandidateCard = ({ candidateInfo }) => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.userSlice.user)
  const candidate = candidateInfo.candidate
  const resume = candidate.resume
  const [state, setState] = useState({
    avatar: null,
    loaded: false,
  })
  const clickButtonHandler = () => {
    if (candidate.id) navigate(`/candidates/${candidate.id}`)
  }
  if (!candidate) return <Navigate to='/not-found' replace />

  let skills = [...(candidate?.resume?.skills || [])]
  skills = skills
    .sort((a, b) => candidateInfo.vacancy.keySkills.indexOf(b) >= 0)
    .slice(0, skills.reduce((s, c) => s + c.length, 0) > 21 ? 2 : 3)
  return user.access_token === undefined ? (
    <Navigate to='/' replace />
  ) : (
    <div className='col-xl-4'>
      <div
        className='bg-white w-sm-max p-4 rounded-3 h-100'
        style={{ cursor: 'pointer' }}
        onClick={clickButtonHandler}
      >
        <div className={classes.TitleSection + ' mb-4'}>
          <DefaultAvatar
            className={classes.Avatar + ' img-fluid rounded'}
            src={SOURCE.new_url + `applicants/${candidate.id}/avatar`}
            style={{ objectFit: 'cover' }}
            alt='user'
            type='b2c'
          />

          <div className={classes.NameContainer}>
            <div className='fs-5' style={{ fontWeight: '600' }}>
              {`${candidate.firstName} ${candidate.lastName}`}
            </div>

            <div className='d-flex fs-6' style={{ color: '#849095' }}>
              <CoinsSvg className='me-2' />
              {!resume?.desiredSalary
                ? 'з/п не указана'
                : `${resume?.desiredSalary.toLocaleString()} ${
                    labelsBySalaryCurrency[resume?.desiredSalaryCurrency]
                  }`}
            </div>
          </div>
        </div>

        <div className='row g-3'>
          {skills.map((s, i) => {
            const active = candidateInfo.vacancy.keySkills.indexOf(s) >= 0
            return (
              <div className='col-auto' key={i}>
                <p
                  className='rounded px-3 py-2'
                  style={{
                    color: active ? 'white' : COLORS.text,
                    backgroundColor: active
                      ? COLORS.warning
                      : COLORS.light_neutral,
                  }}
                >
                  {s}
                </p>
              </div>
            )
          }) || <p className='fs-6'>Нет подходящих навыков!</p>}
        </div>
      </div>
    </div>
  )
}
export const CandidateCardPlaceholder = () => {
  return (
    <div className='col-xl-4 placeholder-glow'>
      <div
        className='bg-white w-sm-max p-4 rounded-3 h-100 d-grid'
        style={{ cursor: 'pointer' }}
      >
        <div
          className='mb-4 d-flex col'
          style={{ gap: '23px', height: '55px' }}
        >
          <div
            className='rounded img-fluid placeholder'
            style={{
              objectFit: 'cover',
              width: '70px',
              height: '70px',
              boxShadow: '0 0 6px 0 #00000010',
            }}
          />
          <div
            className='d-flex'
            style={{
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-evenly',
            }}
          >
            <div className='fs-5'>
              <span class='placeholder' style={{ width: '7rem' }}></span>
            </div>

            <div className='d-flex fs-6' style={{ color: '#849095' }}>
              <span class='placeholder' style={{ width: '5rem' }}></span>
            </div>
          </div>
        </div>

        <div className='g-0 row gap-3 fs-3 mt-1'>
          <span class='placeholder col-1'></span>
          <span class='placeholder col-1'></span>
          <span class='placeholder col-1'></span>
        </div>
      </div>
    </div>
  )
}
export default CandidateCard
