import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slices/userSlice'
import vacanciesSlice from "./slices/vacanciesSlice"
import meetingsReducer from "./slices/meetingsSlice"
import candidateReducer from "./slices/candidateSlice"
import alertSlice from './slices/alertSlice'
export default configureStore({
  reducer: {
    userSlice: userSlice,
    vacanciesSlice: vacanciesSlice,
    meetingsSlice: meetingsReducer,
    candidateSlice: candidateReducer,
    alertSlice: alertSlice,
  },
})