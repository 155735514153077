export const AppTextarea = (
    {
        value,
        onChange,
        label = '',
        className = '',
        name,
        placeholder,
        maxLength
    }) => {
    return (
        <div className={className} style={{position: "relative", paddingTop: label ? "12px" : "0"}}>

            {label && <label className="ms-3 position-absolute top-0 left-0">
                <span className="h6 small bg-white text-muted px-1">{label}</span>
            </label>}

            <textarea
                className="form-control d-flex px-3 py-2 text-wrap overflow-hidden"
                onChange={(e) => onChange(e)}
                value={value}
                name={name}
                onInput={e => {
                    e.target.style.height = 'auto'
                    e.target.style.height = e.target.scrollHeight + 'px'
                }}
                placeholder={placeholder}
                maxLength={maxLength}
            />
        </div>
    )
}