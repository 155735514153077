import {useState} from 'react'
import {BookMeetingModal, MeetingInfoModal} from '../../../components/Modals'
import {COLORS, MEETING_TYPES} from '../../../constants'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router'
import {ReactComponent as ExclamationOutlineGreenSvg} from '../../../icons/exclamation-outline-green.svg'
import {ReactComponent as ScreamMaskSvg} from '../../../icons/scream-mask.svg'
import {ReactComponent as SberJazzSvg} from '../../../icons/sberjazz.svg'
import {ReactComponent as EditPenSvg} from '../../../icons/edit-pen.svg'

export const Meetings = () => {
    const user = useSelector(state => state.userSlice.user)

    const [state, setState] = useState({
        bookMeetingModalShown: false,
        meetingType: ''
    })

    return (
        <div className="w-100">

            {state.bookMeetingModalShown && (
                <BookMeetingModal
                    shown={state.bookMeetingModalShown}
                    move={state.move}
                    set={setState}
                    type={state.meetingType}
                />
            )}

            <div
                className="row justify-content-center gap-2 mb-3 rounded-4 p-3 mb-3 mx-1"
                style={{border: '1.5px solid ' + COLORS.success}}
            >

                <div className="col-sm-1 col-auto my-auto">
                    <ExclamationOutlineGreenSvg
                        style={{height: 'auto', width: '100%', maxWidth: '54px', minWidth: '44px'}}/>
                </div>

                <div className="col my-auto">

                    <p className="my-auto fs-5" style={{fontWeight: '500', cursor: 'pointer'}}>

                        Все встречи в рамках проекта

                        <span
                            style={{color: COLORS.primary}}
                            onClick={() => window.open('https://hurtle.ru/')}
                            className='underline'
                        >
                            хартл
                        </span>

                        проходят на платформе

                        <span className='me-3'></span>

                        <span
                            onClick={() => window.open('https://jazz.sber.ru/')}
                            style={{color: COLORS.success}}
                            className='underline text-nowrap'
                        >
                            <SberJazzSvg
                                className='me-3'
                                style={{height: '26px'}}
                                alt=''
                            />
                            SberJazz
                        </span>

                    </p>

                </div>

            </div>

            <p className='mb-3 fs-3' style={{fontWeight: '600'}}>Предстоящие встречи</p>

            {user.meetings.filter(m => m.is_active).length > 0 ? (
                <div className='row'>

                    {user.meetings.filter(m => m.is_active).map((meeting, i) => (
                        <Meeting
                            set={setState}
                            meeting={meeting}
                            key={i}
                        />
                    ))}

                </div>
            ) : (
                <div
                    className='d-flex flex-row align-items-center justify-content-center rounded-3 p-4 mb-4'
                    style={{border: '1px solid ' + COLORS.primary}}
                >
                    <ScreamMaskSvg className='me-3' style={{height: '48px'}}/>
                    <p className='fs-5'>Пока их нет, но всё впереди</p>

                </div>
            )}

            {user.meetings.filter(m => !m.is_active).length > 0 && (
                <>

                    <p className='mb-3 fs-3' style={{fontWeight: '600'}}>Прошедшие встречи</p>

                    <div className='row'>
                        {user.meetings.filter(m => !m.is_active).map((meeting, i) => (
                            <PastMeeting
                                meeting={meeting} key={i} access_token={user.access_token}
                            />
                        ))}
                    </div>
                </>
            )}

            <button
                className='btn primary px-3 py-2 rounded-3 ms-auto'
                onClick={() => setState(p => ({
                    ...p,
                    move: false,
                    bookMeetingModalShown: true,
                    meetingType: MEETING_TYPES.consultation_b2b
                }))}
            >
                Забронировать консультацию
            </button>
        </div>
    )
}

const Meeting = ({meeting, set}) => {

    const [state, setState] = useState({
        infoModalShown: false,
        meeting: meeting
    })

    return (
        <div className="col-md col-xxl-4 col-md-6 mb-3">

            <MeetingInfoModal
                state={{infoModalShown: state.infoModalShown, meeting: meeting}}
                setState={setState}
            />

            <div
                className="d-flex flex-column px-3 px-sm-4 mb-2 rounded-4 pt-3 pb-2 w-100 light_light_primary"
                style={{border: '1.5px solid ' + COLORS.primary, cursor: 'pointer'}}
                onClick={() => setState(p => ({...p, infoModalShown: true}))}
            >

                <p className='fs-6' style={{color: COLORS.header_text, fontWeight: '300'}}>
                    {meeting.type}
                </p>

                <p className='mb-3 fs-5' style={{fontWeight: '600'}}>
                    {meeting.name}
                </p>

                <p className='fs-6'>
                    Дата и время

                    <span style={{color: COLORS.primary, fontWeight: '600'}}>
                        {meeting.time}
                    </span>
                </p>

                <p className='mb-4 fs-6'>
                    Менеджер:

                    <span style={{color: COLORS.primary, fontWeight: '600'}}>
                        {meeting.manager_id}
                    </span>
                </p>

                <div className='d-flex flex-row flex-wrap align-items-center'>

                    <div
                        className='py-2 px-4 rounded-3 me-4 justify-content-center mb-3 success'
                        onClick={() => window.open(meeting.link)}
                        style={{backgroundColor: COLORS.success, color: '#fff', cursor: 'pointer'}}
                    >
                        <SberJazzSvg alt='' className='me-3'/>Подключиться
                    </div>

                    <p
                        style={{color: COLORS.primary, cursor: 'pointer'}}
                        className='underline mb-3'
                        onClick={e => {
                            e.stopPropagation()
                            set(p => ({...p, move: true, bookMeetingModalShown: true, meetingType: meeting.type}))
                        }}
                    >
                        <span>
                            <EditPenSvg
                                className='me-1'
                                alt=''
                                style={{height: '20px'}}
                            />
                        </span>

                        Перенести
                    </p>

                </div>

            </div>

        </div>
    )
}

const PastMeeting = ({meeting}) => (
    <div className="col-md col-xxl-4 col-md-6 mb-3">
        <div
            className="d-flex flex-column px-4 mb-2 rounded-4 pt-4 pb-2 w-100 light_light_primary"
            style={{border: '1.5px solid ' + COLORS.primary, cursor: 'pointer'}}
        >

            <p className='fs-6' style={{color: COLORS.header_text, fontWeight: '300'}}>
                {meeting.type}
            </p>

            <p className='mb-4 fs-5' style={{fontWeight: '600'}}>
                {meeting.name}
            </p>

            <p className='fs-5'>
                Дата и время

                <span style={{color: COLORS.primary, fontWeight: '600'}}>
                    {meeting.time}
                </span>
            </p>

            <p className='mb-4 fs-5'>
                Менеджер:

                <span style={{color: COLORS.primary, fontWeight: '600'}}>
                    {meeting.manager_id}
                </span>
            </p>

        </div>
    </div>
)

