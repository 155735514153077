import { ReactComponent as Eye } from '../../icons/eye.svg'
import { ReactComponent as EyeClosed } from '../../icons/eye-closed.svg'
import { useSelector } from 'react-redux'
import { useState } from 'react'

export const AppInput = ({
  label,
  onChange,
  value,
  type = '',
  disabled = false,
  placeholder = '',
  style = {},
  showAlert = false,
  className = '',
  showEye = false,
  backgroundColor = '#fff',
  ...props
}) => {
  const [passType, setPassType] = useState('password')
  const showAlerts = useSelector((state) => state.alertSlice.alert.showAlerts)

  return (
    <div className={'form-floating mb-md-3 mb-2 ' + className} {...props}>
      <div
        className='my-auto position-absolute'
        style={{ right: '20px', top: '28%', cursor: 'pointer' }}
      >
        {showEye &&
          (passType === 'text' ? ( 
            <Eye
              onClick={() => setPassType('password')}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <EyeClosed
              onClick={() => setPassType('text')}
              style={{ cursor: 'pointer' }}
            />
          ))}
      </div>
      <input
        id='floatingInput'
        placeholder={placeholder || label}
        className={
          'form-control rounded-3 ' +
          (showAlerts && showAlert ? ' alert-input' : '')
        }
        style={{ backgroundColor }}
        disabled={disabled}
        type={showEye ? passType : type}
        value={value}
        onChange={(e) => onChange(e.target.value.toString())}
        onClick={(e) => e.preventDefault()}
      />
      {label && (
        <label
          for='floatingInput'
          className='fs-6'
          style={{ fontWeight: '200' }}
        >
          {label}
        </label>
      )}

    </div>
  )
}
