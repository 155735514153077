import {useEffect, useState} from 'react'
import {COLORS, FULL_MONTHS} from '../constants'
import {ReactComponent as ArrowLeft} from '../icons/arrow-left.svg'
import {ReactComponent as ArrowRight} from '../icons/arrow-right.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSlot } from '../store/slices/meetingsSlice'


const todayDate = new Date()
export const MonthCalendar = ({selectDate, selectedDate, datesArray}) => {
    const today = [(todayDate.getMonth() + 1).toString().padStart(2,'0'), todayDate.getDate().toString(), todayDate.getFullYear().toString()].join('-')
    const [state, setState] = useState({
        // current: {
        //     year: today.getFullYear(),
        //     month: today.getMonth() + 1,
        //     day: today.getDate(),
        // },
        selected: {
            year: todayDate.getFullYear(),
            month: todayDate.getMonth() + 1,
        },
    })
    return  <div className="d-flex flex-column rounded-4 p-4 h-100" style={{border: '1px solid #D0D0D0'}}>
            <div className="d-flex flex-row mb-4">
                <p className="me-auto"
                   style={{fontSize: '22px'}}>{FULL_MONTHS[state.selected.month - 1] + ' ' + state.selected.year}</p>
                <div className="d-flex flex-row my-auto">
                    <ArrowLeft cursor='pointer' className='me-5'
                               fill={(todayDate.getMonth() + 1) === state.selected.month ? '#B4B4B4' : COLORS.primary}
                               onClick={() => setState(p => {
                                   let [newMonth, newYear] = lowerMonth(todayDate.getMonth() + 1, todayDate.getFullYear(), p.selected.month, p.selected.year)
                                   return {
                                       ...p, selected: {
                                           ...p.selected,
                                           month: newMonth,
                                           year: newYear
                                       }
                                   }
                               })}/>
                    <ArrowRight cursor='pointer' onClick={() => setState(p => ({
                        ...p, selected: {
                            ...p.selected,
                            year: p.selected.month + 1 === 13 ? p.selected.year + 1 : p.selected.year,
                            month: p.selected.month + 1 === 13 ? 1 : p.selected.month + 1,
                        }
                    }))}/>
                </div>
            </div>

            <div className="row cols-7 flex-nowrap">
                <div className='col text-center px-0'><p>пн</p></div>
                <div className='col text-center px-0'><p>вт</p></div>
                <div className='col text-center px-0'><p>ср</p></div>
                <div className='col text-center px-0'><p>чт</p></div>
                <div className='col text-center px-0'><p>пт</p></div>
                <div className='col text-center px-0'><p>сб</p></div>
                <div className='col text-center px-0'><p>вс</p></div>
            </div>
            <div className='row h-100'>
                {(new Date(state.selected.year, state.selected.month - 1, 1)).getDay() !== 0 ?
                    [...Array((new Date(state.selected.year, state.selected.month - 1, 1)).getDay() - 1).keys()].map((d, i) =>
                        <div className='col text-center' style={{minWidth: 'calc(100%/7)'}} key={i}><p></p></div>)
                    : <></>}
                {[...Array(daysInMonth(state.selected.month, state.selected.year)).keys()].map((d, i) => {
                    let thisDate = [state.selected.month.toString().padStart(2, "0"), (i + 1).toString().padStart(2, "0"), state.selected.year].join('-')
                    return <div className='d-flex align-items-end px-0 mb-2' style={{width: '14.2857143%'}} key={i}
                                onClick={() => datesArray.indexOf(thisDate) >= 0 && selectDate(p => ({
                                    ...p,
                                    selectedDate: thisDate,
                                    selectedTimeId: -1
                                }))}>
                        <p className='m-auto d-flex align-items-center justify-content-center rounded-3'
                           style={{
                               width: '35px',
                               height: '35px',
                               color: thisDate === selectedDate ? '#fff' :
                                   (datesArray.indexOf(thisDate) < 0 ? (today === thisDate ? 'white' : COLORS.header_text) : COLORS.text),
                               cursor: datesArray.indexOf(thisDate) < 0 ? 'auto' : 'pointer',
                               backgroundColor: thisDate === selectedDate ? COLORS.primary : (datesArray.indexOf(thisDate) < 0 ? 
                               (today === thisDate ? COLORS.danger : '')
                                : COLORS.light_primary)
                           }}
                        >{i + 1}</p>
                    </div>
                })}
            </div>
        </div>
}

function daysInMonth(month, year) { // Use 1 for January, 2 for February, etc.
    return new Date(year, month, 0).getDate()
}

function lowerMonth(cMonth, cYear, selMonth, selYear) {
    if (cMonth === selMonth) {
        if (cYear === selYear) {
            return [selMonth, selYear]
        }
        selMonth -= 1
        if (selMonth === 1) {
            selYear -= 1
            selMonth = 12
        }
        return [selMonth, selYear]
    } else if (selMonth === 1) {
        selYear -= 1
        selMonth = 13
    }
    return [selMonth - 1, selYear]
}

// export const SlotTime = ({slot, state, setState}) => {
//     return <div style={{
//         backgroundColor: slot.id === state.selectedTimeId ? COLORS.primary : COLORS.light_primary,
//         color: slot.id === state.selectedTimeId ? '#fff' : COLORS.text, cursor: 'pointer'
//     }}
//                 onClick={() => setState(p => ({...p, selectedTimeId: slot.id}))}
//                 className="col-3 text-center px-sm-4 px-2 py-2 py-lg-4   rounded-3 text-nowrap time fw-bolded fs-4">
//         {new Date(slot.dateTime).toLocaleTimeString().slice(0,5)}
//     </div>
// }

export const SlotTime = ({slot}) => {
    const dispatch = useDispatch()
    const selectedSlot = useSelector((state) => state.meetings.selectedSlot)

    const clickHandler = () => dispatch(setSelectedSlot(slot.id))

    return (
        <div
            className={`col-3 text-center px-sm-4 px-2 py-2 py-lg-4 rounded-3 text-nowrap fs-5`}
            onClick={clickHandler}
            style={{
                backgroundColor: slot.id === selectedSlot ? COLORS.primary : COLORS.light_primary,
                color: slot.id === selectedSlot ? '#fff' : COLORS.text,
                cursor: 'pointer',
            }}
        >
            {slot.dateTime.split('T')[1].substring(0, 5)}
        </div>
    )
}