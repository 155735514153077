export const AppSelect = (
    {
        className = "",
        selectClassName = "",
        padding = 'p-sm-3 py-2',
        style,
        label,
        value,
        onChange,
        options,
        disabled = false,
        name,
    }) => {

    return (

        <div className={'mb-md-3 mb-2 ' + className} style={{position: "relative", ...style}}>
            <select
                style={{minWidth: '120px'}}
                className={` form-select ${padding}  ` + selectClassName }
                onChange={e => onChange(e)}
                value={value}
                disabled={disabled}
                name={name}
            >
                {options.map((el, i) => (
                    <option key={i} value={el.value}>{el.label}</option>
                ))}
            </select>

        </div>
    )
}