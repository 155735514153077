import {SOURCE} from "../constants"

export async function createAccessToken(data) {
    try {
        return await fetch(`${SOURCE.new_url}auth/accessToken?role=EMPLOYER`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    } catch (e) {
        console.error(e)
        return null
    }
}

export async function registerEmployer(data) {
    try {
        return await fetch(`${SOURCE.new_url}auth/employer`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    } catch (e) {
        console.error(e)
        return null
    }
}