import {SOURCE} from "../constants"

export async function sendVerificationCode(access_token) {
    try {
        return await fetch(
            `${SOURCE.new_url}emailVerifications`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: access_token,
                },
            }
        )
    } catch (e) {
        console.error(e)
        return null
    }
}

export async function verifyEmail(access_token, code) {
    try {
        return await fetch(
            `${SOURCE.new_url}emailVerifications/${code}`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: access_token,
                },
            }
        )
    } catch (e) {
        console.error(e)
        return null
    }
}