import {SOURCE} from "../constants"

export async function getAllApplicants(access_token, query = '?') {
    try {
        const res = await fetch(`${SOURCE.new_url}applicants${query === '?' ? '' : '?' + new URLSearchParams(query).toString()}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
        })
        if (!res) return null
        return await res.json()
    } catch (e) {
        console.error(e)
        return null
    }
}

export async function getApplicantById(access_token, id, include = []) {
    try {
        let query = "?"
        query += include.length ? "include=" + include.join("&include=") : ""
        const res = await fetch(`${SOURCE.new_url}applicants/${id}${query}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
        })
        if (!res) return null
        return await res.json()
    } catch (e) {
        console.error(e)
        return null
    }
}