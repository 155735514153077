import {SOURCE} from "../constants"

export async function createMeeting(access_token, data) {
    try {
        return await fetch(`${SOURCE.new_url}meetings`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
            body: JSON.stringify(data),
        })
    } catch (e) {
        console.error(e)
        return null
    }
}