import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Loader } from '../../../../components/Loader'
import { getAllVacancyResponsesAsync } from '../../../../store/slices/candidateSlice'
import MyVacancies from './MyVacancies/MyVacancies'
import CandidateCard, {
	CandidateCardPlaceholder,
} from './myCandidates/CandidateCard'
import CompanyCard from './myCompanyCard/CompanyCard'

export const Main = () => {
	const navigate = useNavigate()
	const user = useSelector(state => state.userSlice.user)
	const candidatesData = useSelector(
		state => state.candidateSlice.candidatesData
	)
	const dispatch = useDispatch()

	useEffect(() => {
		(async () => {
			dispatch(
				getAllVacancyResponsesAsync({
					include: ['candidate.resume', 'vacancy'],
				})
			)
		})()
	}, [user.access_token])

	return !user.loaded ? (
		<Loader />
	) : (
		<div className='d-flex flex-column gap-3 pb-5 placeholder-glow'>
			<div className='row gx-4 '>
				<div className='col-lg-5 col-xxl-4 mb-3 mb-lg-0'>
					<CompanyCard canEdit={true} />
				</div>
				<div className='col ps-sm-5'>
					<MyVacancies short={true} />
				</div>
			</div>
			{!candidatesData.loaded ? (
				<div className='d-flex flex-column mt-3'>
					<div className='fs-5 mb-1' style={{ fontWeight: '600' }}>
						<span class='placeholder'>Новые кандидаты</span>
					</div>

					<div>
						<p
							style={{ cursor: 'pointer' }}
							className='text-decoration-underline mb-4 fs-5'
						>
							<span class='placeholder '>Посмотрите всех кандидатов</span>
						</p>

						<div className='row g-sm-5 g-3'>
							<CandidateCardPlaceholder />
							<CandidateCardPlaceholder />
							<CandidateCardPlaceholder />
						</div>
					</div>
				</div>
			) : (
				candidatesData.candidates?.length > 0 && (
					<div className='d-flex flex-column mt-3'>
						<div className='fs-3' style={{ fontWeight: '600' }}>
							Новые кандидаты
						</div>

						<div>
							<p
								style={{ cursor: 'pointer' }}
								className='text-decoration-underline mb-4'
								onClick={() => navigate('/candidates')}
							>
								Посмотрите всех кандидатов
							</p>

							<div className='row g-sm-5 g-3'>
								{(candidatesData.candidates || []).slice(0, 3).map(el => (
									<CandidateCard key={el.id} candidateInfo={el} />
								))}
							</div>
						</div>
					</div>
				)
			)}
		</div>
	)
}
