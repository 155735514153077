import {useDispatch, useSelector} from "react-redux"
import {COLORS, MAX_WIDTH} from "../constants"
import {setCookieAcception} from "../store/slices/userSlice"

export const AcceptCookie = () => {
    const dispatch = useDispatch()
    const cookie_accepted = useSelector(state => state.userSlice.cookie_accepted)
    return cookie_accepted ? <></> : <div className="d-flex justify-content-center w-100 px-3" style={{position: 'fixed', bottom: '0px', zIndex: '3'}}>
        <div className="d-flex flex-row align-items-center px-3 px-sm-4 py-2 rounded-3 mb-3 w-100"
             style={{backgroundColor: COLORS.primary, minHeight: '66px', maxWidth: MAX_WIDTH}}>
            <p className="fs-6 m-auto p-2" style={{color: '#fff'}}>Оставаясь на сайте, вы соглашаетесь с <a
                target="_blank" rel="noopener noreferrer" href='https://hurtle.ru/policy' className="underline"
                style={{cursor: 'pointer', color: '#fff', textDecoration: 'none'}}>политикой конфиденциальности</a></p>
            <img src={require("../icons/close.svg").default} style={{width: '14px', height: '14px', cursor: 'pointer'}}
                 onClick={() => {
                     dispatch(setCookieAcception({accepted: true}))
                 }}/>
        </div>
    </div>
}