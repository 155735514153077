import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Loader } from '../../../../components/Loader'
import { NavbarToBackSample } from '../../../../components/NavbarToBackSample'
import { AppInput } from '../../../../components/ui/AppInput'
import { AppSwitch } from '../../../../components/ui/AppSwitch/AppSwitch'
import { AppTextarea } from '../../../../components/ui/AppTextarea'
import { DefaultAvatar } from '../../../../components/ui/DefaultAvatar'
import {
	COLORS,
	SOURCE,
	teamSizeLabels,
	teamSizeOptions,
	toDate,
} from '../../../../constants'
import { ReactComponent as EditPenSvg } from '../../../../icons/edit-pen.svg'
import { changeAvatarAsync } from '../../../../store/slices/userSlice'
import { patchMyAccount } from '../utils'

export const EditUserCard = () => {
	const user = useSelector(state => state.userSlice.user)
	const dispatch = useDispatch()
	const [state, setState] = useState({
		citiesAvailable: [],
		patchUser: {
			description: user.description,
			size: user.size || 'LESS_THAN_15',
			city: user.city,
			isStartup: user.isStartup,
			website: user.website,
		},
	})

	useEffect(() => {
		console.log(state.patchUser)
	}, [state.patchUser])

	const [seed, setSeed] = useState(1)
	const reset = () => {
		setSeed(Math.random())
	}

	useEffect(() => {
		fetch(
			'https://raw.githubusercontent.com/pensnarik/russian-cities/master/russian-cities.json'
		).then(r =>
			r
				.json()
				.then(data =>
					setState(p => ({
						...p,
						citiesAvailable: data.map(e => ({ label: e.name, value: e.name })),
					}))
				)
		)
	}, [])

	return !user.loaded ? (
		<Loader />
	) : (
		<>
			<NavbarToBackSample>
				<h1 className='fs-5 fw-bold d-none d-md-flex mb-0 align-items-center'>Страница компании</h1>
			</NavbarToBackSample>
			<div
				className='bg-white rounded-3 p-4 p-md-5 d-flex flex-column gap-4 w-sm-max w-100 mx-auto'
				style={{ maxWidth: '750px' }}
			>
				<div className='d-flex flex-row w-100 position-relative gap-4'>
					<DefaultAvatar
						key={seed}
						src={SOURCE.new_url + `employers/${user.id}/avatar`}
						style={{
							width: 'min(110px, 20vw)',
							height: 'min(110px, 20vw)',
							objectFit: 'cover',
						}}
					/>
					<button
						className='rounded-circle position-absolute bg-white d-flex justify-content-center align-items-center'
						style={{
							width: 'min(35px, 7vw)',
							height: 'min(35px, 7vw)',
							top: 'calc(min(110px, 20vw) - min(40px, 8vw) / 2)',
							left: 'calc(min(110px, 20vw) - min(40px, 8vw) / 2)',
							boxShadow: '0 4px 4px 0 #00000008',
						}}
					>
						<input
							type='file'
							accept='image/png,image/jpeg,image/webp'
							className='position-absolute opacity-0 top-0 start-0'
							style={{ width: 'min(35px, 7vw)', height: 'min(35px, 7vw)' }}
							onInput={e => {
								const formData = new FormData()
								formData.append('file', e.target.files[0])
								dispatch(changeAvatarAsync({ formData, reset }))
							}}
						/>
						<EditPenSvg style={{ width: '60%', height: '60%' }} fill='#000' />
					</button>
					<div className='flex-grow-1 d-flex flex-column'>
						<span className='fs-3 fw-semibold'>{user.name}</span>
						<span className='fs-6 fw-medium' style={{ color: '#616161' }}>
							договор №{user.agreementNumber} от {toDate(user.createdAt)}
						</span>
					</div>
				</div>
				<div className='w-100'>
					<span className='fs-5 fw-semibold'>Описание</span>
					<AppTextarea
						className='mt-1'
						placeholder='Расскажите о вашей компании'
						value={state.patchUser.description}
						maxLength={512}
						onChange={e => {
							setState(p => ({
								...p,
								patchUser: { ...p.patchUser, description: e.target.value },
							}))
						}}
					/>
				</div>
				<div className='w-100'>
					<span className='fs-5 fw-semibold'>Объем</span>
					<Select
						className='mt-1'
						options={teamSizeOptions}
						placeholder='Выберите объем'
						theme={t => ({
							...t,
							colors: { ...t.colors, primary: COLORS.primary },
						})}
						value={{
							value: state.patchUser.size,
							label: teamSizeLabels[state.patchUser.size],
						}}
						onChange={v => {
							setState(p => ({
								...p,
								patchUser: { ...p.patchUser, size: v.value },
							}))
						}}
					/>
				</div>
				<div className='w-100'>
					<span className='fs-5 fw-semibold'>Город</span>
					<Select
						className='mt-1'
						isSearchable={true}
						options={state.citiesAvailable}
						placeholder='Выберите город'
						theme={t => ({
							...t,
							colors: { ...t.colors, primary: COLORS.primary },
						})}
						value={{ label: state.patchUser.city, value: state.patchUser.city }}
						onChange={v => {
							setState(p => ({
								...p,
								patchUser: { ...p.patchUser, city: v.value },
							}))
						}}
					/>
				</div>
				<div className='w-100 d-flex align-items-center'>
					<AppSwitch
						value={state.patchUser.isStartup}
						onChange={value => {
							setState(p => ({
								...p,
								patchUser: {
									...p.patchUser,
									isStartup: !p.patchUser.isStartup,
								},
							}))
						}}
					/>
					<span className='fs-6 fw-semibold ms-2'>Стартап</span>
				</div>
				<div className='w-100'>
					<span className='fs-5 fw-semibold'>Наш сайт</span>
					<AppInput
						inputClassName='py-2'
						placeholder='https://...'
						value={state.patchUser.website}
						className='mt-1'
						onChange={value => {
							setState(p => ({
								...p,
								patchUser: { ...p.patchUser, website: value },
							}))
						}}
					/>
				</div>
				<div className='w-100 d-flex flex-column flex-md-row gap-2 gap-md-4 justify-content-end'>
					<button
						className='btn success fs-6 fs-medium px-4 py-2 col-12 col-md'
						onClick={() =>
							patchMyAccount(user.access_token, user, state.patchUser, dispatch)
						}
					>
						Сохранить изменения
					</button>
					<button
						className='btn danger fs-6 fs-medium px-4 py-2 col-12 col-md'
						onClick={() =>
							setState(p => ({
								...p,
								patchUser: {
									description: '',
									size: 'LESS_THAN_15',
									city: '',
									isStartup: false,
									website: '',
								},
							}))
						}
					>
						Стереть все
					</button>
				</div>
			</div>
		</>
	)
}
