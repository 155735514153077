import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { SOURCE } from '../../constants'

const url = SOURCE.url

// export const getAvatarAsync = createAsyncThunk(
//     "getAvatar",
//     async (_, {getState, dispatch}) => {
//         const state = getState();
//         const access_token = state.userSlice.user.access_token;
//         const id = state.userSlice.user.id;

//         const response = await fetch(`${SOURCE.new_url}employers/${id}/avatar`, {
//             method: 'GET',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'image/jpeg',
//                 'Authorization': access_token,
//             }
//         });
//         if (!response.ok) return dispatch(setAvatar(''));
//         const img = await response.blob();
//         const url = URL.createObjectURL(img);

//         dispatch(setAvatar(url));
//     }
// );

export const changeAvatarAsync = createAsyncThunk(
	'changeAvatar',
	async ({ formData, reset }, { getState, dispatch }) => {
		const state = getState()
		const TOKEN = state.userSlice.user.access_token
		const id = state.userSlice.user.id

		await fetch(`${SOURCE.new_url}employers/${id}/avatar`, {
			method: 'PUT',
			headers: {
				Authorization: TOKEN,
			},
			body: formData,
		})
		reset()
		dispatch(updateUser())
		// await dispatch(getAvatarAsync());
	}
)

export const userSlice = createSlice({
	name: 'user',

	initialState: {
		user: {
			access_token: Cookies.get('access_token') || undefined,
			loaded: false,
			id: '',
			createdAt: '',
			updatedAt: '',
			email: '',
			login: '',
			contact: '',
			firstName: '',
			middleName: '',
			lastName: '',
			phone: '',
			isConfirmedByManager: false,
			isEmailConfirmed: false,
			name: '',
			inn: '',
			ogrn: '',
			legalForm: true,
			agreementNumber: '',
			agreementDate: '',
			vacancies: [],
			meetings: [],
		},
		avatar: {
			isLoaded: false,
			src: null,
		},
		cookie_accepted: Cookies.get('cookie_accepted'),
	},

	reducers: {
		setAuth: (state, action) => {
			Cookies.set('access_token', action.payload.access_token, { expires: 365 })
			state.user.access_token = action.payload.access_token
		},
		setUser: (state, action) => {
			state.user = action.payload
		},
		setCookieAcception: (state, action) => {
			Cookies.set('cookie_accepted', action.payload.accepted)
			state.cookie_accepted = action.payload.accepted
		},
		setAvatar: (state, action) => {
			return {
				...state,
				avatar: {
					isLoaded: true,
					src: action.payload,
				},
			}
		},
		updateUser: (state, action) => {
			console.log(action.payload)
			state.user =  {
				...state.user,
				...action.payload
			}
		},
	},
})

export const { setAuth, setUser, setCookieAcception, setAvatar, changeAvatar, updateUser } =
	userSlice.actions

export default userSlice.reducer
