export const SelectVacancies = (
    {
        className = '',
        label,
        value,
        onChange,
        disabled = false,
        vacancies,
    }) => {

    const options = vacancies.map(vacancy => {
        return {
            value: [vacancy.id],
            label: `${vacancy.name} - ${vacancy.salary} ${vacancy.salaryCurrency}`,
        }
    })

    return (
        <div className={'mb-md-3 mb-2 ' + className}>

            <label className="ms-3 position-absolute">
                <span className="h6 small bg-white text-muted px-1">
                    {label}
                </span>
            </label>

            <select
                style={{marginTop: '12px', minWidth: '120px'}}
                className="form-select p-sm-3 py-2"
                onChange={(e) => onChange(e)}
                disabled={disabled}
                value={value}
                defaultValue={options.id}
            >
                {options.map((el) => (
                    <option
                        key={el.value}
                        value={el.value}
                    >
                        {el.label}
                    </option>
                ))}
            </select>

        </div>
    )
}