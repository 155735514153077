import {useState} from 'react'
import ReactInputMask from 'react-input-mask'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router'
import {ConfirmMailModal} from '../../../../components/Modals'
import {AppInput} from '../../../../components/ui/AppInput'
import {
    COLORS,
    SOURCE,
    Validation
} from '../../../../constants'
import {Loader} from '../../../../components/Loader'
import {setUser} from "../../../../store/slices/userSlice"
import { showErrorMsg, showSuccessMsg } from '../../../../store/slices/alertSlice'
import { deleteMyAccount, patchMyAccount } from '../utils'

const url = SOURCE.url
export const Settings = () => {
    const user = useSelector(state => state.userSlice.user)
    const [state, setState] = useState({
        patchUser: {
            firstName: user.firstName,
            lastName: user.lastName,
            contact: user.contact,
            name: user.name
        },
        publishModalShown: false,
        confirmMailModalShown: false,
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return !user.loaded ? <Loader/> : <>
        <p className='fs-3 mb-3' style={{fontWeight: '600'}}>Общие настройки</p>
        <div className='bg-white w-sm-max rounded-3 px-4 px-sm-5 py-4'>
            {state.confirmMailModalShown &&
                <ConfirmMailModal state={state} setState={setState} access_token={user.access_token}/>}
            <div className='row'>
                <div className='col-md'>
                    <AppInput label='Имя'
                        showAlert={!Validation.validName(state.patchUser.firstName)} value={state.patchUser.firstName}
                        onChange={e => setState(p => ({...p, patchUser: {...p.patchUser, firstName: e.target.value.trim()}}))} />
                </div>
                <div className='col'>
                    <AppInput label='Фамилия'
                            showAlert={!Validation.validName(state.patchUser.lastName)} value={state.patchUser.lastName}
                            onChange={e => setState(p => ({...p, patchUser: {...p.patchUser, lastName: e.target.value.trim()}}))} />

                </div>
            </div>
            <div className='row'>
                <div className='col-md'>
                    <AppInput label='Отчество'
                        showAlert={state.patchUser.middleName !== undefined && !Validation.validName(state.patchUser.middleName)}
                        onChange={e => setState(p => ({...p, patchUser: {...p.patchUser, middleName: e.target.value.trim()}}))}
                        value={state.patchUser.middleName === undefined ? user.middleName : state.patchUser.middleName}/>

                </div>
                <div className='col'>
                    <AppInput label='Телеграм' showAlert={!Validation.validTelegram(state.patchUser.contact)}
                        value={state.patchUser.contact}
                        onChange={e => setState(p => ({...p, patchUser: {...p.patchUser, contact: e.target.value.trim()}}))}/>
                </div>
                
            </div>
            <div className='row'>
                <div className='col'>
                    <AppInput label={'Электронная почта (' + (!user.isEmailConfirmed ? 'Не подтверждена)' : 'Подтверждена)')} aria-describedby="emailHelp" type='email'
                        value={user.email}
                        disabled={true} />
                    
                </div>
                <div className='col-md'>
                    <AppInput label='Название'
                            showAlert={state.patchUser.name !== undefined && state.patchUser.name === 0}
                            value={state.patchUser.name === undefined ? user.name : state.patchUser.name}
                            onChange={e => setState(p => ({...p, patchUser: {...p.patchUser, name: e.target.value.trim()}}))} />
                </div>
            </div>

            <div className='d-flex flex-row flex-wrap mt-sm-3 mt-3'>
                <button className='btn px-3 py-2 rounded-3 me-3 primary mb-2 fs-6'
                        style={{backgroundColor: COLORS.primary, color: '#fff', minWidth: '240px'}}
                        onClick={() => patchMyAccount(user.access_token, user, state.patchUser, dispatch)
                            .catch(e => {console.error(e); dispatch(showErrorMsg('Произошла ошибка на сервере'))})}>Сохранить изменения
                </button>
                {/* <button className='btn px-3 py-2 rounded-3 light_red_primary mb-2 fs-6'
                        onClick={() => window.confirm('Вы действительно хотите удалить ваш аккаунт?') && deleteMyAccount(user.access_token, navigate)
                            .catch(e => {console.error(e); dispatch(showErrorMsg('Произошла ошибка на сервере'))})}
                        style={{
                            backgroundColor: COLORS.red_light_primary,
                            color: COLORS.red_primary,
                            minWidth: '240px'
                        }}>Удалить аккаунт
                </button> */}
            </div>
        </div>
    </>
}