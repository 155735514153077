import React from 'react'
import { useSelector } from 'react-redux'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from 'react-router'
import { Loader } from '../../../components/Loader'
import { ReactComponent as PeopleStatsSvg } from '../../../icons/people_stats.svg'
import { ReactComponent as VacancyListSvg } from '../../../icons/vacancy_list.svg'
import { EditVacancy } from './EditVacancy/EditVacancy'
import classes from './Vacancy.module.scss'
import { VacancyFunnelBlock } from './VacancyFunnelBlock/VacancyFunnelBlock'
import { VacancyInfoBlock } from './VacancyInfoBlock/VacancyInfoBlock'

import { NavbarToBackSample } from '../../../components/NavbarToBackSample'
import { ReactComponent as Arrow } from '../../../icons/long-arrow.svg'
import { NotFoundPage } from '../../system/NotFoundPage'

export const Vacancy = () => {
  const { id } = useParams()
  const loc = useLocation()
  const navigate = useNavigate()
  const loaded = useSelector(
    (state) => state.vacanciesSlice.vacanciesInfo.loaded
  )
  const vacancy = useSelector((state) =>
    (state.vacanciesSlice.vacanciesInfo.vacancies || []).find(
      (v) => v.id === id
    )
  )

  if (!vacancy) return <Navigate to='/account/vacancies' replace />

  return !loaded ? (
    <Loader />
  ) : (
    <div className='pt-4'>
      <div className='d-flex align-items-center gap-1 mb-2'>
        <div
          className='d-md-none d-block text-left'
          style={{ flex: 1 }}
          onClick={() => navigate(-1)}
        >
          <Arrow fill='#212529' />
        </div>
        <h2 className={classes.Title + ' mb-0'}>{vacancy.name}</h2>
        <div style={{ flex: 1 }}></div>
      </div>
      <div className={classes.Navbar}>
        <NavbarToBackSample link_classes={'d-none d-md-block'}>
          <button
            className={
              !loc.pathname.includes('funnel')
                ? classes.ButtonActive
                : classes.Button
            }
            onClick={() => navigate('/account/vacancies/' + id)}
          >
            <VacancyListSvg />
            Вакансия
          </button>
          <button
            className={
              loc.pathname.includes('funnel')
                ? classes.ButtonActive
                : classes.Button
            }
            onClick={() => navigate(`/account/vacancies/${id}/funnel`)}
          >
            <PeopleStatsSvg />
            Кандидаты
          </button>
        </NavbarToBackSample>
      </div>

      <Routes>
        <Route path='/' element={<VacancyInfoBlock vacancy={vacancy} />} />
        <Route
          path='funnel'
          element={<VacancyFunnelBlock vacancy={vacancy} />}
        />
        <Route path='edit' element={<EditVacancy />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </div>
  )
}
