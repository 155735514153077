import classes from "./VacancyInfoBlock.module.scss"
import {headingsPlugin, listsPlugin, markdownShortcutPlugin, MDXEditor, quotePlugin} from "@mdxeditor/editor"
import {
   labelsByEmploymentType, labelsByExperience, labelsByReportingForm, labelsBySalaryCurrency, labelsByTeamRole,
    labelsByWorkplaceModel
} from "../../../../constants"
import React from "react"
import {deleteVacancyAsync} from "../../../../store/slices/vacanciesSlice"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router"

export const VacancyInfoBlock = ({vacancy}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const deleteVacancyHandler = async () => {
        if (window.confirm(`Вы действительно хотите удалить вакансию ${vacancy.name}?`)) {
            await dispatch(deleteVacancyAsync({id: vacancy.id}))
        }
    }

    return <div className={classes.VacancySection}>
        <div className={classes.VacancyMain + ' w-sm-max'}>
            <div className={classes.VacancyMainTitle}>{vacancy.name}</div>
            {vacancy.keySkills?.length && <div className='row g-2 g-sm-3 mb-3'>
                {
                     vacancy.keySkills.map((el, i) => el  && <div className="col-auto" key={i}>
                                <p
                                    className={classes.KeySkill + ' py-1 py-sm-2 px-3 rounded fs-6'}
                                    key={el}
                                >
                                    {el}
                                </p>
                        </div>)
                }
            </div>}
            <p className={classes.VacancyShortDescription}>{vacancy.shortDescription}</p>
            {vacancy.description ? <MDXEditor markdown={vacancy.description} contentEditableClassName={classes.VacancyMainDescription + ' p-0' }
                plugins={[headingsPlugin(), quotePlugin(), listsPlugin(), markdownShortcutPlugin()]} readOnly />
                : <p className="fs-4">Нет описания вакансии ;(</p>}
        </div>
        <div className={classes.VacancySide}>
            <div className={classes.VacancySideMain + ' w-sm-max'}>
                <div className={classes.VacancySideData}>
                    <p className={classes.VacancySideText}>
                        Локация:
                        <span style={{ fontWeight: '500' }}>
                            {vacancy.city ? ' ' + vacancy.city + ' + ' : ' '}
                            <span className="fst-italic" >{labelsByWorkplaceModel[vacancy.workplaceModel]}</span>
                        </span>
                    </p>
                </div>
                <div className={classes.VacancySideData}>
                    <p className={classes.VacancySideText}>Занятость:&nbsp;</p>
                    {labelsByEmploymentType[vacancy.employmentType]}
                </div>
                <div className={classes.VacancySideData}>
                    <p className={classes.VacancySideText}>Опыт:&nbsp;</p>
                    {labelsByExperience[vacancy.experience]}
                </div>
                <div className={classes.VacancySideData}>
                    <p className={classes.VacancySideText}>Зарплата:&nbsp;</p>
                    {vacancy.salary !== 0 ? `${vacancy.salary.toLocaleString()} ${labelsBySalaryCurrency[vacancy.salaryCurrency]}` : "з/п не указана"}
                </div>
                {/* <div className={classes.VacancySideData}>
                    <p className={classes.VacancySideText}>Роль в команде:&nbsp;</p>
                    {labelsByTeamRole[vacancy.teamRole]}&nbsp;
                </div>
                <div className={classes.VacancySideData}>
                    <p className={classes.VacancySideText}>Кому отчитываться:&nbsp;</p>
                    {labelsByReportingForm[vacancy.reportingForm]}&nbsp;
                </div> */}
            </div>

            <button onClick={() => navigate('edit')} className={classes.ButtonEdit}>
                Редактировать вакансию
            </button>

            <button onClick={() => deleteVacancyHandler()} className={classes.ButtonDelete}>
                Удалить вакансию
            </button>
        </div>
    </div>
}