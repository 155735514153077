import {useEffect, useState} from "react"
import {ReactComponent as ArrowDownIcon} from "../../../../icons/arrow_down.svg"
import Modal from "react-bootstrap/Modal"
import {
    COLORS,
    CONTACT_LABELS,
} from "../../../../constants"

export const ContactMenuSelect = ({ contacts }) => {
    const [modalShown, setModalShown] = useState(false)

    const showModal = () => contacts && setModalShown(true)
    const hideModal = () => setModalShown(false)

    // console.log(contacts)
    // useEffect(() => {
    //     setAvailableTypes(Object.keys(CONTACT_LABELS).filter(t => resume.contacts.findIndex(c => c.type === t) === -1));
    // }, [resume.contacts]);

    return <>
        <button className="fs-6 px-3 bg-white rounded-2" style={{height: "40px", border: "1px solid #e3e3e3"}} onClick={showModal}>
            Контакты
            <ArrowDownIcon className="ms-5" />
        </button>
        <Modal centered contentClassName='border-0 rounded-4 px-2 m-3 shadow' show={modalShown} onHide={hideModal}>
            <Modal.Header closeButton style={{ borderBottom: '0 none' }}>
                <Modal.Title className='fs-4' style={{ fontWeight: '600' }}>
                    Контакты
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <div className="w-100 d-flex flex-column gap-3 mt-1">
                   { contacts.map((c, i) => <p key={i}>
                        <span className="fw-bolded">{CONTACT_LABELS[c.type]}: </span>
                        {<a href={c.value} target="_blank">{c.value}</a> || 'Не указано'}
                    </p>) }
                </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: '0 none' }}>
                <button className="w-100 px-3 py-2 rounded-1 fs-6" style={{backgroundColor: COLORS.neutral}} onClick={hideModal}>
                    Закрыть
                </button>
            </Modal.Footer>
        </Modal>
    </>
}