import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { createAccessToken, registerEmployer } from '../../../api/auth'
import { AppInput } from '../../../components/ui/AppInput'
import {
  COLORS,
  SOURCE,
  Validation,
  errorMessagesDict,
} from '../../../constants'
import { showErrorMsg } from '../../../store/slices/alertSlice'
import { setAuth } from '../../../store/slices/userSlice'

const URL = SOURCE.new_url

export const AuthLayout = () => {
  const props = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const showAlerts = useSelector((state) => state.alertSlice.alert.showAlerts)
  const [state, setState] = useState({
    hasAccount: props.state?.isAuth || false,
    access_token: '',
    email: props.state?.isAuth === true ? props.state.email : '',
    contact: '',
    pswd: '',
    fio: '',
    name: '',
    passwordVisible: false,
    disabled: false,
  })
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      hasAccount: props.state?.isAuth || false,
      email: props.state?.isAuth === true ? props.state.email : '',
    }))
  }, [props.state?.isAuth])

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      style={{ maxWidth: '750px' }}
      className='d-flex flex-column mx-auto my-5'
    >
      <p className='text-center fs-2 mb-3' style={{ fontWeight: '600' }}>
        {state.hasAccount ? 'Вход' : 'Регистрация'}
      </p>

      {/* <p className="text-center my-3" style={{ fontSize: '20px', fontWeight: '300' }}>{ state.hasAccount ? 'Давно не виделись! Самое время искать работу, не так ли?' :
                            'Мы рады, что ты согласился найти работу с нами. Мы обещаем не подвести тебя и сделать всё максимально быстро, а пока введи адрес своей электронной почты'}
                        </p> */}

      {!state.hasAccount && (
        <AppInput
          backgroundColor={COLORS.main_bg}
          value={state.fio}
          label='Фамилия, Имя и Отчество'
          showAlert={!Validation.validFio(state.fio)}
          onChange={(v) => setState((prev) => ({ ...prev, fio: v.toString() }))}
        />
      )}

      <div className={state.hasAccount ? 'd-flex flex-column' : 'row'}>
        <div className={state.hasAccount ? '' : 'col-sm'}>
          <AppInput
            backgroundColor={COLORS.main_bg}
            label='Электронная почта'
            showAlert={!Validation.validMail(state.email)}
            value={state.email}
            onChange={(v) =>
              setState((prev) => ({ ...prev, email: v.toString().trim() }))
            }
          />
        </div>
        <div className={state.hasAccount ? '' : 'col'}>
          <AppInput
            backgroundColor={COLORS.main_bg}
            label='Пароль'
            showEye={true}
            type={state.passwordVisible ? 'text' : 'password'}
            value={state.pswd}
            showAlert={!Validation.validPassword(state.pswd)}
            switchEye={() =>
              setState((p) => ({ ...p, passwordVisible: !p.passwordVisible }))
            }
            onChange={(v) =>
              setState((prev) => ({ ...prev, pswd: v.toString().trim() }))
            }
          />
        </div>
      </div>

      {!state.hasAccount && (
        <div className='row'>
          <div className={state.hasAccount ? '' : 'col-sm'}>
            <AppInput
              backgroundColor={COLORS.main_bg}
              label='Контакт'
              value={state.contact}
              showAlert={state.contact === ''}
              onChange={(v) =>
                setState((prev) => ({ ...prev, contact: v.toString() }))
              }
            />
          </div>
          <div className={state.hasAccount ? '' : 'col'}>
            <AppInput
              backgroundColor={COLORS.main_bg}
              label='Компания'
              value={state.name}
              showAlert={!Validation.validName(state.name)}
              onChange={(v) =>
                setState((prev) => ({ ...prev, name: v.toString() }))
              }
            />
          </div>
        </div>
      )}

      <div className='d-flex flex-row flex-wrap-reverse justify-content-between align-items-center mt-4'>
        {state.hasAccount ? (
          <div className='d-flex flex-column mb-2'>
            <p
              className='mb-1'
              style={{ color: COLORS.primary, cursor: 'pointer' }}
              onClick={() => setState((p) => ({ ...p, hasAccount: false }))}
            >
              Зарегистрироваться
            </p>
            <p
              className='mb-1'
              style={{ color: COLORS.primary, cursor: 'pointer' }}
              onClick={() => navigate('enter_mail')}
            >
              Вход по почте
            </p>
            <p
              style={{ color: COLORS.danger, cursor: 'pointer' }}
              onClick={() =>
                navigate('forgot_password', { state: { email: state.email } })
              }
            >
              Забыли пароль?
            </p>
          </div>
        ) : (
          <div className='d-flex flex-column mb-2'>
            <p
              className='mb-1'
              style={{ color: COLORS.primary, cursor: 'pointer' }}
              onClick={() => setState((p) => ({ ...p, hasAccount: true }))}
            >
              Уже есть аккаунт
            </p>
            {/*color: COLORS.danger*/}
            <p
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => window.open('https://hurtle.ru/')}
            >
              Я ищу работу
            </p>
          </div>
        )}

        <button
          className='btn primary py-2 px-4 mb-2 rounded-pill'
          onClick={() =>
            login(
              state,
              setState,
              state.hasAccount ? 'token' : 'register',
              navigate,
              dispatch
            )
          }
        >
          {state.hasAccount ? 'Войти' : 'Продолжить'}
        </button>
      </div>
    </form>
  )
}

async function login(state, setState, type, navigate, dispatch) {
  const [lastName, firstName, middleName] = state.fio.trim().split(' ')

  if (
    !state.hasAccount &&
    !Validation.validFIO(lastName, firstName, middleName)
  ) {
    dispatch(
      showErrorMsg(
        'Неверно указано ФИО\nФормат: минимум 2 символа на имя/фамилию\nОтчество не обязательно'
      )
    )
  } else if (!Validation.validMail(state.email)) {
    dispatch(showErrorMsg('Неверный формат почты'))
  } else if (!Validation.validPassword(state.pswd)) {
    dispatch(
      showErrorMsg(
        'Пароль должен содержать латинские заглавные и прописные буквы и цифры и быть не короче 8 символов'
      )
    )
  } else if (!state.hasAccount && !Validation.validCompanyName(state.name)) {
    dispatch(
      showErrorMsg('Имя компании не может быть длинной менее 2-х символов')
    )
  } else if (!state.hasAccount && state.contact.trim() === '') {
    dispatch(showErrorMsg('Укажите контакты'))
  } else {
    let response
    if (type === 'register') {
      response = await registerEmployer({
        email: state.email,
        lastName: lastName,
        firstName: firstName,
        middleName: middleName || null,
        contact: state.contact,
        name: state.name.trim(),
        password: state.pswd,
      })
    } else {
      response = await createAccessToken({
        login: state.email,
        password: state.pswd,
      })
    }
    if (response === null) {
      dispatch(showErrorMsg('Произошла ошибка, повторите попытку'))
    } else if (response.ok) {
      if (type === 'register')
        await login(
          { ...state, hasAccount: true },
          setState,
          'token',
          navigate,
          dispatch
        )
      else {
        const data = await response.json()
        await dispatch(setAuth({ access_token: data.token }))
        navigate('/account')
      }
    } else if (response.status === 401) {
      dispatch(showErrorMsg('Неверный логин и/или пароль'))
    } else if (response.status === 404) {
      dispatch(showErrorMsg('Компании с данным ИНН не найдено'))
    } else if (response.status === 409) {
      dispatch(showErrorMsg('Пользователь с данной почтой уже существует'))
    } else if (response.status === 422) {
      const errorMessage = await response.json()
      dispatch(showErrorMsg(errorMessagesDict[errorMessage.details[0]]))
    }
  }
}
