import {useEffect, useRef} from "react"

export default function TextareaAutosize({minHeight, defaultHeight, maxHeight = null, ...props}) {
    const inputRef = useRef()

    const updateSize = (el) => {
        if (!el) return
        if (el.value) {
            el.style.height = minHeight
            if (maxHeight) el.style.height = `min(${maxHeight}, ${el.scrollHeight + "px"})`
            else el.style.height = el.scrollHeight + "px"
        } else el.style.height = defaultHeight
    }

    useEffect(() => {
        updateSize((props.reference || {}).current || inputRef.current)
    }, [props.value])

    useEffect(() => {
        window.addEventListener("resize", () => updateSize(inputRef.current))
        return () => window.removeEventListener("resize", () => updateSize(inputRef.current))
    }, [])

    return <textarea {...props} ref={props.reference || inputRef} style={{
        ...props.style,
        minHeight,
        maxHeight,
        height: defaultHeight,
        transition: "none"
    }} onChange={(e) => {
        !props.value && updateSize(e.currentTarget)
        props.onChange && props.onChange(e)
    }}/>
}