// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppCheckbox_switch__pc\\+X\\+ input {\n  width: 20px;\n  height: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/AppCheckbox/AppCheckbox.module.scss"],"names":[],"mappings":"AAIA;EACC,WAAA;EACA,YAAA;AAHD","sourcesContent":[".switch {\n\n}\n\n.switch input {\n\twidth: 20px;\n\theight: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": "AppCheckbox_switch__pc+X+"
};
export default ___CSS_LOADER_EXPORT___;
