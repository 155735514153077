// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainHeader_Logout__ul3Eh {\n  position: relative;\n  white-space: nowrap;\n  background: none;\n  color: #000;\n  line-height: 16.61px;\n}\n.MainHeader_Logout__ul3Eh:after {\n  content: \"\";\n  position: absolute;\n  bottom: 2px;\n  left: 0;\n  width: 100%;\n  height: 1px;\n  background: #000;\n}", "",{"version":3,"sources":["webpack://./src/components/MainHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;AACF;AAEE;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;AAAJ","sourcesContent":[".Logout {\n  position: relative;\n  white-space: nowrap;\n  background: none;\n  color: #000;\n  line-height: 16.61px;\n\n\n  &:after {\n    content: \"\";\n    position: absolute;\n    bottom: 2px;\n    left: 0;\n    width: 100%;\n    height: 1px;\n    background: #000;\n  }\n}\n\n.LogoutContainer {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Logout": "MainHeader_Logout__ul3Eh"
};
export default ___CSS_LOADER_EXPORT___;
