import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as yup from 'yup'
import {
  Currency,
  SOURCE,
  VacancyEmploymentType,
  VacancyExperience,
  VacancyReportingForm,
  VacancyWorkingHours,
  VacancyWorkplaceModel,
  yupOneOfEnum,
  yupUint32,
} from '../../constants'
import { showErrorMsg } from './alertSlice'

export const getMyVacanciesAsync = createAsyncThunk(
  'GET',

  async (queryObj = {}, { rejectWithValue, getState, dispatch }) => {
    const state = getState()
    const TOKEN = state.userSlice.user.access_token
    let query = '?'
    let include = queryObj.include || []
    include.forEach((el) => {
      if (query !== '?') query += '&'
      query += 'include=' + el
    })
    const response = await fetch(
      `${SOURCE.new_url}vacancies/my${query === '?' ? '' : query}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: TOKEN,
        },
      }
    )
    if (!response.ok) {
      return dispatch(showErrorMsg('Произошла ошибка на сервере'))
    }
    return await response.json()
  }
)

export const addVacancyAsync = createAsyncThunk(
  'ADD',

  async ({ vacancy }, { rejectWithValue, getState, dispatch }) => {
    const state = getState()
    const TOKEN = state.userSlice.user.access_token

    const response = await fetch(`${SOURCE.new_url}vacancies`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: TOKEN,
      },
      body: JSON.stringify(vacancy),
    })
    const data = await response.json()
    dispatch(addVacancy(data))
    return data
  }
)

export const deleteVacancyAsync = createAsyncThunk(
  'DELETE',

  async ({ id }, { rejectWithValue, getState, dispatch }) => {
    const state = getState()
    const TOKEN = state.userSlice.user.access_token

    await fetch(`${SOURCE.new_url}vacancies/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: TOKEN,
      },
    })

    dispatch(deleteVacancy({ id }))
  }
)

const BasicVacancySchema = yup.object({
  city: yup.string().trim().max(255, 'Город длиной должен быть менее 255'),
  name: yup.string().trim().max(50, 'Имя вакансии длиной до 50 символов'),
  description: yup.string().trim().max(3000, 'Описание до 3000 символов'),
  teamRole: yupOneOfEnum([
    'COLLABORATIVE_WORK',
    'TEAM_COORDINATION',
    'TEAM_MANAGEMENT',
    'INDEPENDENT_WORK',
  ]),
  shortDescription: yup
    .string()
    .trim()
    .max(3000, 'Краткое описание до 3000 символов')
    .nullable(),
  salary: yupUint32().max(100_000_000, 'нельзя предложить больше 100 000 000 '),
  salaryCurrency: yupOneOfEnum(Currency),
  experience: yupOneOfEnum(VacancyExperience),
  employmentType: yupOneOfEnum(VacancyEmploymentType),
  reportingForm: yupOneOfEnum(VacancyReportingForm),
  workingHours: yupOneOfEnum(VacancyWorkingHours),
  workplaceModel: yupOneOfEnum(VacancyWorkplaceModel),
  isHidden: yup.boolean(),
  keySkills: yup
    .array()
    .of(yup.string().trim().max(500, 'Длина скилла до 500 символов'))
    .max(50, 'Можно требовать максимум 50 скиллов'),
})

export const updateVacancyAsync = createAsyncThunk(
  'UPDATE',

  async ({ id, vacancy }, { rejectWithValue, getState, dispatch }) => {
    try {
      await BasicVacancySchema.validate(vacancy)
    } catch (err) {
      dispatch(showErrorMsg(err.errors))
      return
    }

    const state = getState()
    const TOKEN = state.userSlice.user.access_token

    await fetch(`${SOURCE.new_url}vacancies/${id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: TOKEN,
      },
      body: JSON.stringify(vacancy),
    })

    dispatch(editVacancy({ id, vacancy }))
  }
)

const initialState = {
  vacanciesInfo: {
    vacancies: [],
    currentPage: 0,
    perPage: 0,
    total: 0,
    totalPages: 0,
    loaded: false,
  },
}

export const vacanciesSlice = createSlice({
  name: 'myVacancy',

  initialState,

  reducers: {
    clearVacancy: (state, action) => {
      state.vacanciesInfo = initialState
    },
    setVacancy: (state, action) => {
      state.vacanciesInfo.vacancies = action.payload
      state.loaded = true
    },
    addVacancy: (state, action) => {
      state.vacanciesInfo = {
        ...state.vacanciesInfo,
        vacancies: state.vacanciesInfo.vacancies.concat({
          ...action.payload,
        }),
      }
    },
    editVacancy: (state, action) => {
      const { id, vacancy } = action.payload

      state.vacanciesInfo.vacancies = state.vacanciesInfo.vacancies
        ? state.vacanciesInfo.vacancies.map((current) =>
            current.id === id
              ? {
                  ...current,
                  ...vacancy,
                }
              : current
          )
        : []
    },
    deleteVacancy: (state, action) => {
      const { id } = action.payload
      state.vacanciesInfo.vacancies = state.vacanciesInfo.vacancies
        ? state.vacanciesInfo.vacancies.filter((c) => c.id !== id)
        : []
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMyVacanciesAsync.pending, (state, action) => {
        state.vacanciesInfo.loaded = false
      })
      .addCase(getMyVacanciesAsync.fulfilled, (state, action) => {
        if (action.payload.items)
          state.vacanciesInfo.vacancies = action.payload.items
        state.vacanciesInfo.loaded = true
      })
  },
})

export const {
  setVacancy,
  addVacancy,
  editVacancy,
  deleteVacancy,
  clearVacancy,
} = vacanciesSlice.actions
export default vacanciesSlice.reducer
