import React, { useEffect, useRef, useState } from 'react'
import classes from './CompanyCard.module.scss'
import {
  COLORS,
  SOURCE,
  teamSizeLabels,
  toDate,
} from '../../../../../constants'
import Button from '../../../../../components/ui/Button/Button'
import { useLocation, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { DefaultAvatar } from '../../../../../components/ui/DefaultAvatar'

const CompanyCard = ({ canEdit = false }) => {
  const navigate = useNavigate()
  const loc = useLocation()
  const avatar = useSelector((state) => state.userSlice.avatar)
  const user = useSelector((state) => state.userSlice.user)
  const [showFullText, setShowFullText] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const descriptionRef = useRef(null)

  useEffect(() => {
    if (descriptionRef.current) {
      setIsOverflowing(descriptionRef.current.scrollHeight > 84)
    }
  }, [user.description])

  const toggleShowMore = () => {
    setShowFullText(!showFullText)
  }
  return (
    <div className={classes.Container + ' p-4 p-xl-5 w-sm-max '}>
      <div className={classes.Company}>
        <DefaultAvatar
          style={{
            height: '80px',
            width: '80px',
            objectFit: 'cover',
            flexShrink: '0',
          }}
          className={classes.CompanyAvatar}
          src={SOURCE.new_url + `employers/${user.id}/avatar`}
          alt='user'
          onClick={() => console.log(1)}
        />
        <div
          className={classes.CompanyTitle + ' my-auto'}
          style={{ overflow: 'hidden' }}
        >
          <div className={'' + classes.CompanyTitleUp}>{user.name}</div>
          <div className={classes.CompanyTitleDown}>
            договор №{user.agreementNumber} от {toDate(user.createdAt)}
          </div>
        </div>
      </div>

      <div
        className={classes.CompanyInfo}
        style={{ maxHeight: showFullText ? 'none' : '84px' }}
        ref={descriptionRef}
      >
        {user.description}

        {isOverflowing && (
          <span className={classes.ShowMoreButton} onClick={toggleShowMore}>
            {showFullText ? 'Скрыть' : '... читать дальше'}
          </span>
        )}
      </div>

      <ul className={classes.CompanyStat}>
        <li>
          <b>Объем:</b>
          {` ${teamSizeLabels[user.size] || 'Не указано'}`}
        </li>
        <li>
          <b>Город:</b>
          {` ${user.city || 'Не указано'}`}
        </li>
        <li>
          <b>Стартап:</b>
          {` ${user.isStartup ? 'да' : 'нет'}`}
        </li>
      </ul>

      <a href={user.website} target={'_blank'} className={classes.Link}>
        Наш сайт
      </a>

      {canEdit && (
        <Button
          onClick={() => navigate('/account/edit_user_card')}
          text={'Редактировать страницу'}
        />
      )}
    </div>
  )
}
export default CompanyCard
