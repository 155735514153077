import {SOURCE} from "../constants"

export async function sendResetCode(email) {
    try {
        return await fetch(
            `${SOURCE.new_url}passwordResets?email=${email}&role=EMPLOYER`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )
    } catch (e) {
        console.error(e)
        return null
    }
}

export async function verifyResetCode(code, password) {
    try {
        return await fetch(
            `${SOURCE.new_url}passwordResets/${code}`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({password})
            }
        )
    } catch (e) {
        console.error(e)
        return null
    }
}