import { SOURCE } from '../constants'

export async function getMyVacancyResponses(token, query = '?') {
  try {
    const res = await fetch(
      `${SOURCE.new_url}vacancyResponses/my${
        query === '?' ? '' : '?' + new URLSearchParams(query).toString()
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    )
    if (!res) return null
    return await res.json()
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function getMyGuestVacancyResponses(token, query = '?') {
  const res = await fetch(
    `${SOURCE.new_url}guestVacancyResponses/my${
      query === '?' ? '' : '?' + new URLSearchParams(query).toString()
    }`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  )
  if (!res) return []
  return await res.json()
}

export async function getMyVRCities(token) {
  try {
    const res = await fetch(`${SOURCE.new_url}vacancyResponses/my/cities`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    if (!res) return null
    return await res.json()
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function changeVacancyResponseStatus(token, id, status) {
  try {
    const res = await fetch(SOURCE.new_url + 'vacancyResponses/' + id, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ status }),
    })
    if (!res.ok) return null
    return await res.json()
  } catch (e) {
    console.error(e)
    return null
  }
}

export async function changeGuestResponseStatus(token, id, status) {
  try {
    const res = await fetch(SOURCE.new_url + 'guestVacancyResponses/' + id, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ status }),
    })
    if (!res.ok) return null
    return await res.json()
  } catch (e) {
    console.error(e)
    return null
  }
}
