import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  GuestResponseInfoModal,
  OfferModal,
} from '../../../../components/Modals'
import { DefaultAvatar } from '../../../../components/ui/DefaultAvatar'
import {
  COLORS,
  SOURCE,
  candidateFunnelOptions,
  labelsForVacancyResponseStatus,
} from '../../../../constants'

import {
  BasicOfferSchema,
  createOffer,
  deleteOffer,
  getMyOffers,
} from '../../../../api/offers'
import {
  changeGuestResponseStatus,
  changeVacancyResponseStatus,
} from '../../../../api/vacancyResponses'
import { ReactComponent as ArrowDownSvg } from '../../../../icons/arrow_down.svg'
import { ReactComponent as BookmarkSvg } from '../../../../icons/bookmark.svg'
import { ReactComponent as CloseSvg } from '../../../../icons/close.svg'
import { ReactComponent as CoinsSvg } from '../../../../icons/coins.svg'
import { ReactComponent as FireSvg } from '../../../../icons/fire.svg'
import { ReactComponent as FlagSvg } from '../../../../icons/flag.svg'
import { ReactComponent as MicrophoneSvg } from '../../../../icons/microphone.svg'
import { ReactComponent as OfferSvg } from '../../../../icons/offer.svg'
import { ReactComponent as PlaceSvg } from '../../../../icons/place.svg'
import {
  showErrorMsg,
  showSuccessMsg,
} from '../../../../store/slices/alertSlice'
import classes from './VacancyFunnelBlock.module.scss'
import { getMyGuestVacancyResponses } from '../../../../api/vacancyResponses'
import {
  editGuestVacancyResponseResumeAndContacts,
  getGuestResponsePdf,
} from '../../../../api/guest'
import {
  editGuestVacancyResponseResume,
  getGuestVacancyResponseResumeAndContacts,
} from '../../../../api/guest'

export const VacancyFunnelBlock = ({ vacancy }) => {
  const access_token = useSelector(
    (state) => state.userSlice.user.access_token
  )
  const [responses, setResponses] = useState(vacancy.responses)
  const [guestResponses, setGuestResponses] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('ALL')
  const [offers, setOffers] = useState([])
  const [resumeAndContacts, setResumeAndContacts] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchGuestResponses = async () => {
      const guestResponsesRes = await getMyGuestVacancyResponses(
        access_token,
        'include=resume'
      )

      if (!guestResponsesRes || guestResponsesRes.length === 0) {
        dispatch(showErrorMsg('Ошибка при загрузке гостевых откликов'))
      } else {
        setGuestResponses(guestResponsesRes.items)
      }
    }
    fetchGuestResponses()
  }, [access_token, dispatch])

  useEffect(() => {
    (async () => {
      const offers = await getMyOffers(access_token, ['vacancyResponse'])
      if (!offers) return dispatch(showErrorMsg('Ошибка при загрузке офферов'))
      setOffers((p) => offers.items)
    })()
  }, [])

  // useEffect(() => console.log(guestResponses), [guestResponses]);
  return (
    <div className='w-100 d-flex flex-column gap-3'>
      <span className='fw-semibold fs-5'>Воронка</span>
      <div className='w-100 d-flex flex-row flex-wrap gap-2 justify-content-around justify-content-md-start'>
        {Object.entries(candidateFunnelOptions).map(([key, val], i) => (
          <button
            key={i}
            className='px-3 py-2 fs-6 rounded-2'
            onClick={() => setSelectedCategory(key)}
            style={{
              backgroundColor:
                selectedCategory === key ? '#D0CBFF' : 'transparent',
            }}
          >
            {val.label}
            <span className='ms-2' style={{ fontWeight: '300' }}>
              {(responses || []).filter((el) =>
                candidateFunnelOptions[key].filter(el)
              ).length +
                (guestResponses || [])?.filter((el) =>
                  candidateFunnelOptions[key].filter(el)
                ).length}
            </span>
          </button>
        ))}
      </div>
      <div className='row gx-5'>
        {(responses || [])
          .filter(candidateFunnelOptions[selectedCategory].filter)
          .map((r, i) => (
            <ResponseCard
              response={r}
              key={i}
              vacancy={vacancy}
              setResponses={setResponses}
              offer={offers.find((o) => o.vacancyResponseId === r.id) || {}}
            />
          ))}
        {(guestResponses || [])
          .filter(candidateFunnelOptions[selectedCategory].filter)
          .map((r, i) => (
            <GuestResponseCard
              response={r}
              key={i}
              vacancy={vacancy}
              setGuestResponses={setGuestResponses}
              setResumeAndContacts={setResumeAndContacts}
              resumeAndContacts={resumeAndContacts}
            />
          ))}
        {responses?.length + guestResponses?.length === 0 && (
          <span className='w-100 text-center'>Пока нет откликов</span>
        )}
      </div>
    </div>
  )
}

const GuestResponseCard = ({
  response,
  vacancy,
  setGuestResponses,
  setResumeAndContacts,
  resumeAndContacts,
}) => {
  const access_token = useSelector(
    (state) => state.userSlice.user.access_token
  )
  const dispatch = useDispatch()
  const [isInfo, setIsInfo] = useState({
    infoModalShown: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      const guestResumeAndContacts =
        await getGuestVacancyResponseResumeAndContacts(
          access_token,
          response.resume.id,
          {
            include: ['response', 'contacts'],
          }
        )
      if (!guestResumeAndContacts)
        return dispatch(
          showErrorMsg('Ошибка при получении данных о гостевом отклике')
        )
      setResumeAndContacts((p) => ({
        ...p,
        guestResumeAndContacts,
      }))
    }

    fetchData()
  }, [access_token, dispatch])

  let skills = [...(response.resume?.skills || [])]
  skills = skills
    ?.sort((a, b) => vacancy.keySkills.indexOf(b) >= 0)
    .slice(0, skills?.reduce((s, c) => s + c.length, 0) > 21 ? 2 : 3)

  const changeStatusHandler = async (v) => {
    // const changeStatusHandler = async (v) => {
    //   const status = v || 'NEW_APPLICATION';
    // const res = await changeVacancyResponseStatus(
    //   access_token,
    //   response.id,
    //   status
    // );
    // if (res === null)
    //   return dispatch(showErrorMsg('Ошибка при изменении статуса'));
    // setResponses((p) =>
    //   p.map((r) => (r.id === response.id ? { ...r, status } : r))
    // );
    // };
    const status = v || 'NEW_APPLICATION'
    const editGuest = {
      status: status,
      isViewedByEmployer: true,
      _requester: 'EMPLOYER',
    }
    const res = await editGuestVacancyResponseResume(
      access_token,
      response.id,
      editGuest
    )
    if (!res) return dispatch(showErrorMsg('Ошибка при изменении статуса'))
    setGuestResponses((p) =>
      p.map((r) =>
        r.id === response.id
          ? {
              ...r,
              status,
            }
          : r
      )
    )
  }
  const handlePdfLink = (id) => {
    (async () => {
      const response = await getGuestResponsePdf(access_token, id)
      return window.open(response)
    })()
  }
  return (
    <div className='d-flex flex-column col-12 col-md-6 col-lg-4 pt-5'>
      {isInfo.infoModalShown && (
        <GuestResponseInfoModal
          guestResponse={resumeAndContacts}
          shown={isInfo.infoModalShown}
          set={setIsInfo}
        />
      )}
      <div
        className={`${classes.Card} d-flex flex-column w-sm-max`}
        style={{
          flex: '1',
          border: '1px #12D153 solid',
          position: 'relative',
          borderTopRightRadius: 'var(--bs-border-radius)',
        }}
      >
        <div
          className='rounded-top'
          style={{
            position: 'absolute',
            left: '-1px',
            top: '-28px',
            padding: '3px 5px',
            fontSize: '14px',
            backgroundColor: '#12D153',
            color: '#fff',
          }}
        >
          Гостевой отклик
        </div>
        <div
          className={classes.CardHead + ' mb-4'}
          onClick={() => setIsInfo((p) => ({ ...p, infoModalShown: true }))}
          style={{ cursor: 'pointer' }}
        >
          <DefaultAvatar
            className={classes.Avatar + ' img-fluid rounded'}
            style={{ objectFit: 'cover' }}
            type='b2c'
            src={SOURCE.new_url + `applicants/null/avatar`}
          />
          <div className={classes.WhoBox}>
            <div className={classes.Name + ' fs-5 mb-2'}>
              {response.resume.firstName} {response.resume.lastName}
            </div>
            <div className={classes.Position + ' fs-6'}>
              {(response.resume || {}).title}
            </div>
          </div>
        </div>

        <div className={classes.CardSkills + ' row g-2 g-sm-3 fs-6 mb-4'}>
          {(skills || []).map((el, i) => {
            const active = vacancy.keySkills.indexOf(el) >= 0
            return (
              <div className='col-auto' key={i}>
                <p
                  className='py-sm-2 py-1 px-3 fs-6 rounded text-wrap text-center'
                  style={{
                    backgroundColor: active
                      ? COLORS.warning
                      : COLORS.light_neutral,
                    color: active ? 'white' : COLORS.text,
                  }}
                >
                  {el}
                </p>
              </div>
            )
          })}
        </div>
        <div className={classes.CardInfo + ' flex-wrap fs-6 mb-auto gap-3'}>
          <div className='text-nowrap'>
            <PlaceSvg className='me-2' />
            {response.resume?.city ? response.resume?.city : 'Россия'}
          </div>
          {(response.resume || {}).desiredSalary && (
            <div className='text-nowrap mb-2'>
              <CoinsSvg className='me-2' />
              {(+response.resume.desiredSalary).toLocaleString()}
              &nbsp;руб/мес.
            </div>
          )}
        </div>
        <CheckResume handleClick={handlePdfLink} guestResponse={response} />
        <SelectFunnel
          changeStatusHandler={changeStatusHandler}
          currentValue={response.status}
        />
      </div>
    </div>
  )
}

const ResponseCard = ({ response, vacancy, setResponses, offer }) => {
  const access_token = useSelector(
    (state) => state.userSlice.user.access_token
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    salary: 0,
    message: '',
    show: false,
    showAccept: false,
    acceptDeleteOffer: () => {},
  })
  const { candidate } = response

  const changeStatusHandler = async (v) => {
    const status = v || 'NEW_APPLICATION'
    const res = await changeVacancyResponseStatus(
      access_token,
      response.id,
      status
    )
    if (res === null)
      return dispatch(showErrorMsg('Ошибка при изменении статуса'))
    setResponses((p) =>
      p.map((r) => (r.id === response.id ? { ...r, status } : r))
    )
  }

  let skills = [...(candidate.resume?.skills || [])]
  skills = skills
    ?.sort((a, b) => vacancy.keySkills.indexOf(b) >= 0)
    .slice(0, skills?.reduce((s, c) => s + c.length, 0) > 21 ? 2 : 3)

  const acceptOfferAction = async () => {
    await changeStatusHandler('OFFER_MADE')

    const offerData = {
      salary: state.salary,
      salaryCurrency: 'RUB',
      message: state.message,
      vacancyResponseId: response.id,
    }

    try {
      await BasicOfferSchema.validate(offerData)
    } catch (err) {
      dispatch(showErrorMsg(err.errors))
      return
    }

    const r = await createOffer(access_token, offerData)
    if (!r || !r.ok) return dispatch(showErrorMsg('Что-то пошло не так'))

    dispatch(showSuccessMsg('Оффер отправлен'))
    setState((p) => ({ ...p, show: false }))
  }
  const acceptDeleteOffer = async (response, value) => {
    const deleted = await deleteOffer(access_token, offer.id)
    if (!deleted) return dispatch(showErrorMsg('Ошибка при удалении'))
    changeStatusHandler(value)
    setState((p) => ({ ...p, showAccept: false }))
    console.log(
      `delete offer ${response.id}`,
      `change status ${changeStatusHandler} ${value}`,
      `close modal ${state.showAccept}`
    )
  }

  const redefineChangeStatusHandler = (v) => {
    if (v === 'OFFER_MADE') {
      setState((p) => ({ ...p, show: true }))
    } else {
      if (response.status === 'OFFER_MADE') {
        let a = window.confirm('вы действительно хотите удалить оффер?')
        if (a === true) {
          acceptDeleteOffer(response, v)
        }
      } else {
        changeStatusHandler(v)
      }
    }
  }
  return (
    <div className='d-flex flex-column col-12 col-md-6 col-lg-4  pt-4'>
      <OfferModal
        state={state}
        candidate={candidate}
        resume={candidate.resume || {}}
        acceptOfferAction={acceptOfferAction}
        setState={setState}
      />
      <div
        className={`${classes.Card} d-flex flex-column rounded-top w-sm-max`}
        style={{ flex: '1' }}
      >
        <div
          className={classes.CardHead + ' mb-4'}
          onClick={() => navigate(`/candidates/${candidate.id}`)}
          style={{ cursor: 'pointer' }}
        >
          <DefaultAvatar
            className={classes.Avatar + ' img-fluid rounded'}
            style={{ objectFit: 'cover', flexShrink: '0' }}
            type='b2c'
            src={SOURCE.new_url + `applicants/${candidate.id}/avatar`}
          />
          <div className={classes.WhoBox}>
            <div className={classes.Name + ' fs-5 mb-2'}>
              {candidate.firstName} {candidate.lastName}
            </div>
            <div className={classes.Position + ' fs-6'}>
              {(candidate.resume || {}).title}
            </div>
          </div>
        </div>

        <div className={classes.CardSkills + ' row g-2 g-sm-3 fs-6 mb-4'}>
          {(skills || []).map((el, i) => {
            const active = vacancy.keySkills.indexOf(el) >= 0
            return (
              <div className='col-auto' key={i}>
                <p
                  className='py-sm-2 py-1 px-3 fs-6 rounded text-wrap text-center'
                  style={{
                    backgroundColor: active
                      ? COLORS.warning
                      : COLORS.light_neutral,
                    color: active ? 'white' : COLORS.text,
                  }}
                >
                  {el}
                </p>
              </div>
            )
          })}
        </div>
        <div className={classes.CardInfo + ' flex-wrap fs-6 mb-auto gap-3'}>
          <div className='text-nowrap'>
            <PlaceSvg className='me-2' />
            {candidate.city ? candidate.city : 'Россия'}
          </div>
          {(candidate.resume || {}).desiredSalary && (
            <div className='text-nowrap mb-2'>
              <CoinsSvg className='me-2' />
              {(+candidate.resume.desiredSalary).toLocaleString()}
              &nbsp;руб/мес.
            </div>
          )}
        </div>
        <SelectFunnel
          changeStatusHandler={redefineChangeStatusHandler}
          currentValue={response.status}
        />
      </div>
    </div>
  )
}

export const SelectFunnel = ({ changeStatusHandler, currentValue }) => {
  const [isOpened, setIsOpened] = useState(false)

  const outsideClickHandler = () => setIsOpened(false)

  const wrapperClickHandler = (e) => {
    e.stopPropagation()
    setIsOpened((p) => !p)
  }

  const itemClickHandler = (value) => (e) => {
    e.stopPropagation()
    setIsOpened(false)
    changeStatusHandler(value)
  }

  useEffect(() => {
    window.addEventListener('click', outsideClickHandler)
    return () => window.removeEventListener('click', outsideClickHandler)
  }, [])

  return (
    <ul
      className='list-group position-relative'
      style={{
        zIndex: '100',
        marginTop: '12px',
        maxHeight: '56px',
        listStyleType: 'none',
        minWidth: '120px',
      }}
    >
      <li
        className='list-group-item px-sm-3 p-2 ms-0 d-flex flex-row justify-content-between align-items-center'
        style={{ cursor: 'pointer' }}
        onClick={wrapperClickHandler}
      >
        <div>
          {iconByVacancyResponseStatus(currentValue, '#EA7000')}
          <span className='fw-semibold' style={{ color: '#EA7000' }}>
            {labelsForVacancyResponseStatus[currentValue]}
          </span>
        </div>
        <ArrowDownSvg width='10px' height='6px' />
      </li>
      {isOpened &&
        Object.entries(labelsForVacancyResponseStatus).map(
          ([value, label], i) =>
            currentValue !== value && (
              <li
                className='list-group-item list-group-item-action d-flex px-sm-3 p-2'
                key={i}
                style={{ cursor: 'pointer' }}
                onClick={itemClickHandler(value)}
              >
                {iconByVacancyResponseStatus(value)}
                <p className='me-auto fs-6' style={{ fontWeight: '600' }}>
                  {label}
                </p>
              </li>
            )
        )}
    </ul>
  )
}

const CheckResume = ({ handleClick, guestResponse }) => {
  return (
    <ul
      onClick={() => handleClick(guestResponse.id)}
      className='list-group position-relative'
      style={{
        marginTop: '12px',
        maxHeight: '56px',
        listStyleType: 'none',
        minWidth: '120px',
      }}
    >
      <li
        className='list-group-item px-sm-3 p-2 ms-0 d-flex flex-row justify-content-between align-items-center'
        style={{ cursor: 'pointer' }}
      >
        <span className='fw-semibold pe-3 ' style={{ color: '#EA7000' }}>
          <svg
            width='40'
            height='40'
            viewBox='0 0 23 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              x='0.5'
              y='0.346191'
              width='22'
              height='23'
              rx='4'
              fill='#6757F1'
            />
            <path
              d='M11.5 6V9C11.5 9.71008 12.1716 10.2857 13 10.2857H16.5V16.7143C16.5 17.4244 15.8284 18 15 18H8C7.17157 18 6.5 17.4244 6.5 16.7143V7.28571C6.5 6.57563 7.17157 6 8 6H11.5ZM12.5 6.21429V9C12.5 9.23669 12.7239 9.42857 13 9.42857H16.25L12.5 6.21429ZM10.5 12.4286C10.5 12.6653 10.7239 12.8571 11 12.8571H14C14.2761 12.8571 14.5 12.6653 14.5 12.4286C14.5 12.1919 14.2761 12 14 12H11C10.7239 12 10.5 12.1919 10.5 12.4286ZM11 13.7143C10.7239 13.7143 10.5 13.9062 10.5 14.1429C10.5 14.3796 10.7239 14.5714 11 14.5714H14C14.2761 14.5714 14.5 14.3796 14.5 14.1429C14.5 13.9062 14.2761 13.7143 14 13.7143H11ZM10.5 15.8571C10.5 16.0938 10.7239 16.2857 11 16.2857H14C14.2761 16.2857 14.5 16.0938 14.5 15.8571C14.5 15.6205 14.2761 15.4286 14 15.4286H11C10.7239 15.4286 10.5 15.6205 10.5 15.8571ZM9 12.8571C9.27614 12.8571 9.5 12.6653 9.5 12.4286C9.5 12.1919 9.27614 12 9 12C8.72386 12 8.5 12.1919 8.5 12.4286C8.5 12.6653 8.72386 12.8571 9 12.8571ZM9.5 14.1429C9.5 13.9062 9.27614 13.7143 9 13.7143C8.72386 13.7143 8.5 13.9062 8.5 14.1429C8.5 14.3796 8.72386 14.5714 9 14.5714C9.27614 14.5714 9.5 14.3796 9.5 14.1429ZM9 16.2857C9.27614 16.2857 9.5 16.0938 9.5 15.8571C9.5 15.6205 9.27614 15.4286 9 15.4286C8.72386 15.4286 8.5 15.6205 8.5 15.8571C8.5 16.0938 8.72386 16.2857 9 16.2857Z'
              fill='white'
            />
          </svg>
        </span>
        <div style={{ flex: '1' }}>
          <a href='#a' className='fw-semibold'>
            Посмотреть резюме
          </a>
          <br />
          <span className='fs-6'>PDF</span>
        </div>
      </li>
    </ul>
  )
}

const iconByVacancyResponseStatus = (value, fill = '#000') => {
  const size = '16px'
  switch (value) {
    case 'NEW_APPLICATION':
      return (
        <FireSvg
          className='me-2 my-auto'
          style={{ height: size, width: size }}
          fill={fill}
        />
      )
    case 'SCREENING':
      return (
        <BookmarkSvg
          className='me-2 my-auto'
          style={{ height: size, width: size }}
          fill={fill}
        />
      )
    case 'INTERVIEWING':
      return (
        <MicrophoneSvg
          className='me-2 my-auto'
          style={{ height: size, width: size }}
          fill={fill}
        />
      )
    case 'OFFER_MADE':
      return (
        <OfferSvg
          className='me-2 my-auto'
          style={{ height: size, width: size }}
          fill={fill}
        />
      )
    case 'HIRED':
      return (
        <FlagSvg
          className='me-2 my-auto'
          style={{ height: size, width: size }}
          fill={fill}
        />
      )
    case 'REJECTED':
      return (
        <CloseSvg
          className='me-2 my-auto'
          style={{ height: size, width: size }}
          fill={fill}
        />
      )
  }
}
