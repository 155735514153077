import React from 'react'
import classes from './Button.module.scss'

const Button = ({text, ...props}) => {
    return (
        <button
            {...props}
            className={`${props.className || ""} ${classes.Container}`}
        >
            {text}
        </button>
    )
}
export default Button