import {SOURCE} from "../constants"

export async function employerGetMe(access_token, include = []) {
    try {
        let query = "?"
        query += include.length ? "include=" + include.join("&include=") : ""
        return await fetch(`${SOURCE.new_url}employers/me${query}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
        })
    } catch (e) {
        console.error(e)
        return null
    }
}

export async function employerPatchMe(access_token, patchUser) {
    try {
        return await fetch(`${SOURCE.new_url}employers/me`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
            body: JSON.stringify(patchUser),
        })
    } catch (e) {
        console.error(e)
        return null
    }
}

export async function employerDeleteMe(access_token) {
    try {
        return await fetch(`${SOURCE.new_url}employers/me`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
        })
    } catch (e) {
        console.error(e)
        return null
    }
}