import {SOURCE} from "../constants"

export async function getFullResume(access_token, applicantId) {
    try {
        if (!applicantId) return null
        const response = await fetch(
            `${SOURCE.new_url}resumes/${applicantId}?include=applicant&include=certificates&include=contacts&include=education&include=experience&include=languages`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: access_token,
                },
            }
        )
        if (!response.ok) return null
        return await response.json()
    } catch (e) {
        console.error(e)
        return null
    }
}