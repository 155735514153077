import { Alert } from 'react-bootstrap'
import { ReactComponent as SuccessSvg } from './icons/check_line.svg'
import { ReactComponent as FailSvg } from './icons/fail.svg'
import { useEffect, useState } from 'react'
import { Loader } from './components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedDay, setSelectedSlot } from './store/slices/meetingsSlice'
import { hideErrorMsg, hideSuccessMsg } from './store/slices/alertSlice'
import * as yup from "yup"

const NAME_DAYS = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
export const MONTHS = [
	'января',
	'февраля',
	'марта',
	'апреля',
	'мая',
	'июня',
	'июля',
	'августа',
	'сентября',
	'октября',
	'ноября',
	'декабря',
]
export const FULL_MONTHS = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь',
]
export const SHORT_MONTHS = [
	'янв.',
	'фев.',
	'мар.',
	'апр.',
	'мая',
	'июн.',
	'июл.',
	'авг.',
	'сент.',
	'окт.',
	'ноя.',
	'дек.',
]
export const PASTEL_COLORS = ['#FFFCB0', '#D4FFC0', '#D1F4FF', '#FDD0B7']

export const COLORS = {
	primary: '#8F4EE3', //'#7268CB',
	light_primary: '#F1F0FA',
	red_primary: '#F8485D',
	red_light_primary: '#FFF9FA',
	text_primary: '#5246B8',
	hover_primary: '#4C3EC9',
	danger: '#F8485D',
	danger_light: '#FFEEF0',
	danger_hover: '#FFEEF0',
	footer: '#272727', // '#F8F8FD'
	secondary: '#FAFAFD',
	light_neutral: '#F1F1F1',
	text: '#272727',
	first_text: '#191919',
	second_text: '#6E6D75',
	header_text: '#B3B8C0',
	gray_text: '#849095',
	pastel_secondary: '#FFF9F9',
	pastel: '#FBFBFF',
	warning: '#FF9900',
	success: '#01A707',
	success_light: '#F5FFF0',
	hr: '#C7C8C9',
	main_bg: '#F8F7FB',
	gray: '#E9F0F6',
	orange: '#F96D49',
	yellow: '#FECF77',
	black: '#18191B',
	neuro: 'linear-gradient(91.64deg, #6757F1 0%, #F8485D 100.87%)',
	light_black: '#2C2C2C'
}
export const LANDING_MAX_WIDTH = '1600px'
export const MAX_WIDTH = '1400px'
export const PADDINGS = ' px-3 px-lg-5'

export const AVATARS_BACKGROUNDS = [
	'radial-gradient(50% 50% at 50% 50%, rgba(248, 255, 170, 0.50) 0%, rgba(176, 232, 142, 0.50) 100%)',
	'radial-gradient(50% 50% at 50% 50%, #AAFFC7 0%, #8EE8D2 100%',
	'radial-gradient(50% 50% at 50% 50%, #ACAAFF 0%, #B58EE8 100%',
	'radial-gradient(50% 50% at 50% 50%, #F8FFAA 0%, #B0E88E 100%',
]

export const SOURCE = {
	url: 'https://api.hurtle.ru',
	new_url: process.env.REACT_APP_NODE_ENV === "dev" ? "https://dev.hurtle.ru/api/v1/" : "https://hurtle.ru/api/v1/",
	source_url: 'https://resource.studvoyage.ru/',
}

export const datePlusTime = datetime => {
	let [date, time] = new Date(datetime).toLocaleString().split(', ')
	date = date.split('.')
	date[1] = MONTHS[parseInt(date[1] - 1)]
	return date.join(' ') + ', ' + time.substring(0, 5)
}

export const calendarDateToDateTime = calendarDate => {
	return [
		calendarDate.getMonth() + 1,
		calendarDate.getDate(),
		calendarDate.getFullYear(),
	].join('-')
}
export const toTime = datetime => {
	return new Date(datetime).toLocaleTimeString().slice(0, 5)
}

export const toDate = datetime => {
	return new Date(datetime).toLocaleDateString()
}
export function generateUniqueId() {
	let result = ''
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
	const charactersLength = characters.length
	for (let i = 0; i < 10; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

export const ErrorMsg = () => {
	const dispatch = useDispatch()
	const message = useSelector(state => state.alertSlice.alert.errorMsg)
	const hideHandler = () => dispatch(hideErrorMsg())
	useEffect(() => {
		const timeout = setTimeout(hideHandler, 3000)
		return () => clearTimeout(timeout)
	}, [message])
	return (
		<div
			className='position-fixed'
			style={{ maxWidth: '500px', top: '10px', right: '10px', zIndex: '999999' }}
		>
			<Alert
				show={Boolean(message)}
				variant='danger'
				className='bg-white border-0'
				onClose={() => dispatch(hideErrorMsg())}
				dismissible
			>
				<div className='d-flex me-sm-5'>
					<FailSvg className='me-2' height='24px' width='24px' />
					<p className='fs-5' style={{ color: COLORS.red_primary }}>
						Ошибка
					</p>
				</div>
				<p className='fs-6' style={{ color: COLORS.gray_text, whiteSpace: 'pre-wrap' }}>{message}</p>
			</Alert>
		</div>
	)
}

export const SuccessMsg = () => {
	const dispatch = useDispatch()
	const hideHandler = () => dispatch(hideSuccessMsg())
	const message = useSelector(state => state.alertSlice.alert.successMsg)

	useEffect(() => {
		const timeout = setTimeout(hideHandler, 3000)
		return () => clearTimeout(timeout)
	}, [message])
	return (
		<div
			className='position-fixed'
			style={{ maxWidth: '500px', top: '10px', right: '10px', zIndex: '999999' }}
		>
			<Alert
				show={Boolean(message)}
				variant='success'
				className='bg-white border-0'
				onClose={() => dispatch(hideSuccessMsg())}
				dismissible
			>
				<div className='d-flex me-sm-5'>
					<SuccessSvg className='me-2' style={{ flexShrink: '0' }} fill={COLORS.success} height='24px' width='24px' />
					<p className='fs-5' style={{ color: COLORS.success }}>
						Успешно!
					</p>
				</div>
				<p className='fs-6' style={{ color: COLORS.gray_text }}>{message}</p>
			</Alert>
		</div>
	)
}

const lengthValidate = (s, min, max) => s?.length >= min && s?.length < max
const rangeValidate = (s, min, max) => s >= min && s < max

export const Validation = {
    validName: name => /^[A-zА-яЁё]+$/i.test(name),
    validFIO: (firstName, lastName, middleName) => lengthValidate(firstName, 2, 50) && lengthValidate(lastName, 2, 50) && (!middleName || lengthValidate(middleName, 1, 50)),
    validFio: fio => fio.trim().split(' ').length >= 2 && fio.trim().split(' ').length < 4 && fio.trim().split(' ').filter(el => !Validation.validName(el)).length === 0 && fio.trim().split(' ')[0].length >= 2 && fio.trim().split(' ')[1].length >= 2,
    validMail: mail => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail), // /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
    validPhone: phone => lengthValidate(phone, 1, 36),
    validPassword: pswd => pswd.length >= 8, // /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/.test(pswd)
		validCompanyName: cName => cName.trim().length >= 2,
    validDateTime: date => date.length === 20,
    validDate: date => date.length === 10 && rangeValidate(+date.split('-')[0], 1900, new Date().getFullYear() - 13),
    validTelegram: tg => tg !== '',
    validMask: mask => !mask.includes('_') && mask !== ''
}

const MAX_POSITIVE_POSTGRES_INTEGER = 2 ** 31 - 1
const MIN_NEGATIVE_POSTGRES_INTEGER = - (2 ** 31)


export function yupInt32(schema) {
  schema = schema ?? yup.number()
  return schema.min(MIN_NEGATIVE_POSTGRES_INTEGER).max(MAX_POSITIVE_POSTGRES_INTEGER)
}

export function yupUint32(schema) {
  schema = schema ?? yup.number()
  return schema.min(0).max(MAX_POSITIVE_POSTGRES_INTEGER)
}

export function yupOneOfEnum(enumObject) {
  return yup.mixed().oneOf(Object.values(enumObject))
}

export function yupManyOfEnum(enumObject) {
  return yup.array( yupOneOfEnum( enumObject ).defined() )
}

export function getAuthRoute(state) {
	let result = '/auth'
	// if (state.is_active) {
	// if (state.is_completed){
	result = '/account'
	// } else result += '/complete_account'
	// } else result += '/confirm_mail'
	return result
}

export function daysInMonth(month, year) {
	// Use 1 for January, 2 for February, etc.
	return new Date(year, month, 0).getDate()
}

export function lowerMonth(cMonth, cYear, selMonth, selYear) {
	if (cMonth === selMonth) {
		if (cYear === selYear) {
			return [selMonth, selYear]
		}
		selMonth -= 1
		if (selMonth === 1) {
			selYear -= 1
			selMonth = 12
		}
		return [selMonth, selYear]
	} else if (selMonth === 1) {
		selYear -= 1
		selMonth = 13
	}
	return [selMonth - 1, selYear]
}

export const useDebounceCallback = (func, delay) => {
	const [args, setArgs] = useState(null)
	useEffect(() => {
		if (args === null) return
		const timeout = setTimeout(() => func(...args), delay)
		return () => clearTimeout(timeout)
	}, [args]) // DO NOT add func and delay to deps
	return (...args) => setArgs(args)
}

export const isResumeFilled = (resume) => {
    return (resume.skills?.length > 0)
        || (resume.certificates?.length > 0)
        || (resume.education?.length > 0)
        || (resume.experience?.length > 0)
        || (resume.languages?.length > 0)
}

export const FAQ = [
	{
		q: 'Какой срок размещения вакансии?',
		a: 'Размещение вакансии на нашей платформе пройдет моментально — сразу после заполнения',
		id: generateUniqueId(),
	},
	{
		q: 'Как разместить вакансию?',
		a: 'Для размещения вакансии необходимо зарегистрироваться на сайте и опубликовать вакансию в личном кабинете работодателя',
		id: generateUniqueId(),
	},
	{
		q: 'Сколько стоит размещение вакансии?',
		a: 'Размещение вакансии на платформе Хартл бесплатное',
		id: generateUniqueId(),
	},
	{
		q: 'Кто может размещать вакансии?',
		a: 'Разместить вакансию на платформе Хартл может любая команда или компания, которой нужны IT-специалисты — это может быть как небольшой стартап, так и полноценный завод :)',
		id: generateUniqueId(),
	},
	{
		q: 'Сколько стоит просмотр контактов?',
		a: 'Контакты всех откликнувшихся или рекомендованных кандидатов можно смотреть бесплатно, по желанию можно оплатить просмотр видеорезюме кандидата',
		id: generateUniqueId(),
	},
]

export const MEETING_TYPES = {
	CONSULTATION_B2C_EXPERT: 'Карьерная консультация (1Р)',
	CONSULTATION_B2C_EXPERT_FOR_STUDENTS: 'Карьерная консультация (299Р)',
	CONSULTATION_B2B: 'Консультация бизнес',
	CONSULTATION_B2C: 'Консультация',
	INTERVIEW: 'Собеседование',
}

export const labelsByEmploymentType = {
	FULL_TIME: 'Полная занятость',
	PART_TIME: 'Частичная занятость',
	PROJECT: 'Проектная',
	VOLUNTEER: 'Волонтерство',
	PROBATION: 'Стажировка',
}

export const labelsByWorkplaceModel = {
	OFFICE: 'Офис',
	HYBRID: 'Гибрид',
	REMOTE: 'Удаленно',
}

export const labelsByExperience = {
	NO_EXPERIENCE: 'Без опыта',
	BETWEEN_1_AND_3: '1-3 года',
	BETWEEN_3_AND_6: '3-6 лет',
	MORE_THAN_6: '6 и более лет',
}

export const labelsByTeamRole = {
	COLLABORATIVE_WORK: 'Координирование команды',
	TEAM_COORDINATION: 'Совместная работа',
	TEAM_MANAGEMENT: 'Управление командой',
	INDEPENDENT_WORK: 'Самостоятельная работа',
}

export const labelsByReportingForm = {
	GROUP_WORK: 'Групповая работа',
	PROJECT_MANAGER: 'Project-менеджер',
	DIRECTOR: 'Директор',
	INDEPENDENT_WORK: 'Независимая работа',
}

export const labelsByWorkingHours = {
	GROUP_WORK: 'Групповая работа',
	STRICT_SCHEDULE: 'Строгий график',
	FREELANCE: 'Фриланс',
	FIXED_HOURS: 'Фиксированные часы',
}

export const labelsBySalaryCurrency = {
	RUB: 'руб/мес',
	USD: 'дол/мес',
	EUR: 'евро/мес',
	GBP: 'фунт/мес',
	GEL: 'GEL (₾)',
	AZN: 'AZN (₼)',
	BYR: 'BYR (Br)',
	KGS: 'KGS (с)',
	KZT: 'KZT (₸)',
	UZS: 'UZS (S)',
}

export const Currency = ["RUB", "USD", "EUR", "GBP", "GEL", "AZN", "BYR", "KGS", "KZT", "UZS"]

export const teamSizeLabels = {
	LESS_THAN_15: 'до 15 человек',
	BETWEEN_15_AND_50: 'до 50 человек',
	BETWEEN_50_AND_100: 'до 100 человек',
	BETWEEN_100_AND_500: 'до 500 человек',
	MORE_THAN_500: 'свыше 500 человек',
}

export const CONTACT_LABELS = {
	EMAIL: 'Почта',
	PHONE: 'Телефон',
	TELEGRAM: 'Telegram',
	GITHUB: 'GitHub',
	VK: 'VK',
	FACEBOOK: 'Facebook',
	LINKEDIN: 'LinkedIn',
	OTHER: 'Другое',
}

export const teamSizeOptions = [
	{ label: 'До 15 человек', value: 'LESS_THAN_15' },
	{ label: 'До 50 человек', value: 'BETWEEN_15_AND_50' },
	{ label: 'До 100 человек', value: 'BETWEEN_50_AND_100' },
	{ label: 'До 500 человек', value: 'BETWEEN_100_AND_500' },
	{ label: 'Свыше 500 человек', value: 'MORE_THAN_500' },
]

export const funnelFiltersTypes = [
	'Все',
	'Новый отклик',
	'Подборка',
	'Интервью',
	'Оффер',
	'Нанят',
]

export const formatSalary = salary =>
	salary.replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const formatDateExperience = (
	startMonth,
	startYear,
	endMonth,
	endYear
) => {
	const formatMonth = month => (month < 10 ? `0${month}` : month)

	const startDate = `${formatMonth(startMonth)}.${startYear}`
	const endDate = endYear ? `${formatMonth(endMonth)}.${endYear}` : 'по н.в.'

	return `${startDate} - ${endDate}`
}

export const labelsForVacancyResponseStatus = {
	NEW_APPLICATION: 'Новый отклик',
	SCREENING: 'Выборка',
	INTERVIEWING: 'Интервью',
	OFFER_MADE: 'Оффер',
	HIRED: 'Нанят',
	REJECTED: 'Отказ',
}

export const candidateFunnelOptions = {
	ALL: { label: 'Все', filter: () => true },
	NEW_APPLICATION: {
		label: 'Новый отклик',
		filter: r => r.status === 'NEW_APPLICATION',
	},
	SCREENING: { label: 'Выборка', filter: r => r.status === 'SCREENING' },
	INTERVIEWING: { label: 'Интервью', filter: r => r.status === 'INTERVIEWING' },
	OFFER_MADE: { label: 'Оффер', filter: r => r.status === 'OFFER_MADE' },
	HIRED: { label: 'Нанят', filter: r => r.status === 'HIRED' },
	REJECTED: { label: 'Отказ', filter: r => r.status === 'REJECTED' },
}

const handler = {
	get: function (target, name) {
		return Object.prototype.hasOwnProperty.call(target, name) ? target[name] : name
	},
}

export const errorMessagesDict = new Proxy(
	{
		'inn должен быть как минимум 10 символы':
			'ИНН должен быть как минимум 10 символов',
		'password должен быть как минимум 8 символы':
			'Пароль должен быть как минимум 8 символов',
	},
	handler
)

export const labelsByVacancyStatus = {
	UNDER_REVIEW: 'На рассмотрении',
	FAILED_TO_PASS_REVIEW: 'Не прошла модерацию',
	PUBLISHED: 'Опубликована',
	HIDDEN: 'Скрыта',
}
export const colorsByVacancyStatus = {
	UNDER_REVIEW: '#FF9900',
	FAILED_TO_PASS_REVIEW: '#F8485D',
	PUBLISHED: '#01A707',
	HIDDEN: '#F1F1F1',
}

export const VacancyExperience = ["NO_EXPERIENCE", "BETWEEN_1_AND_3", "BETWEEN_3_AND_6", "MORE_THAN_6"]
export const VacancyEmploymentType = ["FULL_TIME", "PART_TIME", "PROJECT", "VOLUNTEER", "PROBATION"]
export const VacancyReportingForm = ["INDEPENDENT_WORK", "DIRECTOR", "PROJECT_MANAGER", "GROUP_WORK"]
export const VacancyWorkingHours = ["GROUP_WORK", "STRICT_SCHEDULE", "FREELANCE", "FIXED_HOURS"]
export const VacancyWorkplaceModel = ["OFFICE", "HYBRID", "REMOTE"]