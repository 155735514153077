import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {SOURCE} from "../../constants"

function groupByDate(items) {
    const result = {}

    items.forEach(item => {
        const dateObject = new Date(item.dateTime)
        const day = dateObject.getDate().toString()
        const month = (dateObject.getMonth() + 1).toString()
        const year = dateObject.getFullYear()

        const dateKey = `${month}-${day}-${year}`

        if (!result[dateKey]) {
            result[dateKey] = [item]
        } else {
            result[dateKey].push(item)
        }
    })

    return result
}

export const getAvailableMeetingsAsync = createAsyncThunk(
    "getAvailableMeetings",

    async (_, {getState, dispatch}) => {
        const state = getState()
        const TOKEN = state.userSlice.user.access_token

        let currentDate = new Date()
        let formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`
        let encodedDate = encodeURIComponent(formattedDate)

        const response = await fetch(`${SOURCE.new_url}meetingSlots?available=true&types=INTERVIEW&types=CONSULTATION_B2C&types=CONSULTATION_B2B`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': TOKEN,
            }
        })

        const data = await response.json()

        const available = groupByDate(data.items)

        dispatch(setAvailable({available}))

        return data
    }
)


const initialState = {
    all: {
        items: [],
        currentPage: 0,
        perPage: 0,
        total: 0,
        totalPages: 0,
    },
    available: {},
    selectedDay: "",
    selectedSlot: "",
    isLoading: false,
}

export const meetingsSlice = createSlice({
    name: 'meetings',

    initialState,

    reducers: {
        setAvailable: (state, action) => {
            const {available} = action.payload
            const selected = Object.keys(available)[0]

            state.available = available
            state.selectedDay = selected
            state.selectedSlot = available[selected][0].id
        },
        setSelectedDay: (state, action) => {
            state.selectedDay = action.payload
        },
        setSelectedSlot: (state, action) => {
            state.selectedSlot = action.payload
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(getAvailableMeetingsAsync.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getAvailableMeetingsAsync.fulfilled, (state, action) => {
                Object.assign(state.all, action.payload)
                state.isLoading = false
            })
    }
})

export const {setAvailable, setSelectedDay, setSelectedSlot} = meetingsSlice.actions

export default meetingsSlice.reducer