import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from 'react-router'
import { ReactComponent as ArrowBackSvg } from '../../../icons/arrow_back.svg'
import { ReactComponent as MoreInfo } from '../../../icons/moreInfo.svg'
import { ReactComponent as MagicStick } from '../../../icons/magic_stick.svg'

import classes from './CandidateScreen.module.scss'
import { Resume } from './resume/Resume'
import { InterviewResults } from '../InterviewResults'
import {
  getCandidateInfoAsync,
  resetCandidateInfo,
} from '../../../store/slices/candidateSlice'
import { Loader } from '../../../components/Loader'
import { NeuroResume } from './neuro/NeuroResume'
import { showErrorMsg } from '../../../store/slices/alertSlice'
import { createAiChat } from '../../../api/applicantAiChats'
import { NotFoundPage } from '../../system/NotFoundPage'

export const CandidateScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loc = useLocation()
  const { id } = useParams()
  const candidateInfo = useSelector(
    (state) => state.candidateSlice.candidateInfo
  )
  const user = useSelector((state) => state.userSlice.user)
  const vacanciesLoaded = useSelector(
    (state) => state.vacanciesSlice.vacanciesInfo.loaded
  )
  const onBack = () => {
    navigate(-1)
  }

  const chatId =
    candidateInfo.candidate?.aiChats?.find((c) => c.employerId === user.id)
      ?.id || null

  const updateCandidateInfo = () => {
    dispatch(resetCandidateInfo())
    dispatch(
      getCandidateInfoAsync({
        id: id,
        queryObj: {
          include: ['resume', 'meetings', 'vacancyResponses', 'aiChats'],
        },
      })
    )
  }

  useEffect(() => {
    updateCandidateInfo()
  }, [])

  useEffect(() => {
    if (!(candidateInfo.loaded && candidateInfo.candidate.id === id)) return
    const createChat = async () => {
      const result = await createAiChat(
        user.access_token,
        candidateInfo.candidate.id
      )
      if (result.id) updateCandidateInfo()
    }

    if (!chatId) createChat()
  }, [candidateInfo.loaded])

  if (candidateInfo.candidate.error === 'Applicant not found')
    return <Navigate to='/not-found' replace />

  return !(
    candidateInfo.loaded &&
    vacanciesLoaded &&
    candidateInfo.candidate.id === id
  ) ? (
    <Loader />
  ) : (
    <div className='mb-5'>
      {/* {candidate.responseModal.shown && (
			<ResponseModal
				responseModal={candidate.responseModal}
				setState={setCandidate}
			/>
		)} */}
      <div className={classes.Container}>
        <div className={classes.Navbar}>
          <button
            onClick={onBack}
            className={classes.ButtonBack + ' pe-md-4 pe-3'}
          >
            <ArrowBackSvg />
          </button>
          <button
            onClick={() => navigate('/candidates/' + id)}
            className={
              !loc.pathname.includes('neuro')
                ? classes.ButtonActive
                : classes.Button
            }
          >
            <MoreInfo />
            <span className='d-md-block d-none'>Резюме</span>
          </button>
          {chatId && (
            <button
              onClick={() => navigate(`/candidates/${id}/neuro`)}
              className={
                loc.pathname.includes('neuro')
                  ? classes.ButtonActive
                  : classes.Button
              }
            >
              <MagicStick />
              <span className='d-md-block d-none'>Нейрорезюме</span>
            </button>
          )}
        </div>
      </div>

      <Routes>
        <Route
          path='/'
          element={
            <Resume
              user={user}
              candidateInfo={candidateInfo}
              chatId={chatId}
              updateCandidateInfo={updateCandidateInfo}
            />
          }
        />
        <Route
          path='neuro'
          element={
            <NeuroResume
              user={user}
              candidateInfo={candidateInfo}
              chatId={chatId}
            />
          }
        />
        {/*<Route path='results' element={<InterviewResults/>}/>*/}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </div>
  )
}
