import {
	BlockTypeSelect,
	BoldItalicUnderlineToggles,
	CodeToggle,
	ListsToggle,
	MDXEditor,
	UndoRedo,
	headingsPlugin,
	listsPlugin,
	markdownShortcutPlugin,
	quotePlugin,
	toolbarPlugin,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Select from 'react-select'
import { InputAutosize } from '../../../../components/ui/InputAutosize'
import TextareaAutosize from '../../../../components/ui/TextareaAutosize'
import {
	COLORS,
	labelsByEmploymentType,
	labelsByExperience,
	labelsBySalaryCurrency,
	labelsByWorkplaceModel,
	useDebounceCallback,
} from '../../../../constants'
import { ReactComponent as DeleteOutline } from '../../../../icons/delete-outline.svg'
import { ReactComponent as PlusSvg } from '../../../../icons/plus_circle.svg'
import {
	deleteVacancyAsync,
	updateVacancyAsync,
} from '../../../../store/slices/vacanciesSlice'
import CompanyCard from '../../layouts/main/myCompanyCard/CompanyCard'
import classes from './EditVacancy.module.scss'

export const EditVacancy = () => {
	const { id } = useParams()
	const nameRef = createRef()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const vacancies = useSelector(
		state => state.vacanciesSlice.vacanciesInfo.vacancies
	)
	let vacancy = vacancies.find(e => e.id === id)
	const [patchVacancy, setPatchVacancy] = useState({
		city: vacancy?.city,
		experience: vacancy?.experience,
		name: vacancy?.name,
		shortDescription: vacancy?.shortDescription,
		salary: vacancy?.salary,
		salaryCurrency: vacancy?.salaryCurrency,
		employmentType: vacancy?.employmentType,
		workplaceModel: vacancy?.workplaceModel,
		keySkills: vacancy?.keySkills || [''],
		teamRole: vacancy?.teamRole,
		reportingForm: vacancy?.reportingForm,
		workingHours: vacancy?.workingHours,
		_requester: 'EMPLOYER',
	})
	const [citiesAvailable, setCitiesAvailable] = useState([])
	const mdEditorRef = useRef()

	const deleteBtnHandler = () => {
		if (
			window.confirm(
				`Вы действительно хотите удалить вакансию ${patchVacancy.name}?`
			)
		) {
			dispatch(deleteVacancyAsync({ id }))
		}
	}

	const updateVacancy = useDebounceCallback(() => {
		dispatch(
			updateVacancyAsync({
				id,
				vacancy: {
					...patchVacancy,
					description: mdEditorRef.current.getMarkdown(),
				},
			})
		)
	}, 500)

	const firstUpdate = useRef(true)
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false
			return
		}
		updateVacancy()
	}, [patchVacancy])

	useEffect(() => {
		nameRef?.current?.focus()
		fetch(
			'https://raw.githubusercontent.com/pensnarik/russian-cities/master/russian-cities.json'
		).then(r =>
			r
				.json()
				.then(data =>
					setCitiesAvailable(data.map(e => ({ label: e.name, value: e.name })))
				)
		)
	}, [])
	if (!vacancy) return navigate('/account/vacancies')

	return (
		<div className='row'>
			<div className='col-lg-4 d-flex flex-column gap-3 pb-3'>
				<CompanyCard canEdit={false} />
				<ul className={classes.VacancyParameters + ' p-4 p-xl-5 w-sm-max'}>
					<li className='d-flex w-100 flex-row'>
						<p style={{ fontWeight: '600' }}>Локация:&nbsp;</p>
						<ParamAdvSelect
							isSearchable={true}
							options={citiesAvailable}
							placeholder='указать'
							value={{ label: patchVacancy.city, value: patchVacancy.city }}
							onChange={v => setPatchVacancy(p => ({ ...p, city: v.value }))}
							needBorder
						/>
						{/*<InputAutosize type="text" className={classes.ParameterInput} placeholder="указать" value={patchVacancy.city}*/}
						{/*       onChange={e => setPatchVacancy(p => ({...p, city: e.target.value}))} minWidth="1ch" defaultWidth="7ch"/>*/}
					</li>
					<li className='d-flex w-100 flex-row'>
						<p style={{ fontWeight: '600' }}>Формат:&nbsp;</p>
						<select
							className={`${classes.ParameterSelect} ${
								!patchVacancy.workplaceModel ? classes.Underline : ''
							}`}
							value={patchVacancy.workplaceModel}
							onChange={e =>
								setPatchVacancy(p => ({ ...p, workplaceModel: e.target.value }))
							}
						>
							<option disabled={true} selected={!patchVacancy.workplaceModel}>
								выбрать
							</option>
							{Object.keys(labelsByWorkplaceModel).map(opt => (
								<option key={opt} value={opt}>
									{labelsByWorkplaceModel[opt]}
								</option>
							))}
						</select>
					</li>
					<li className='d-flex w-100 flex-row'>
						<p style={{ fontWeight: '600' }}>Занятость:&nbsp;</p>
						<select
							className={`${classes.ParameterSelect} ${
								!patchVacancy.employmentType ? classes.Underline : ''
							}`}
							value={patchVacancy.employmentType}
							onChange={e =>
								setPatchVacancy(p => ({ ...p, employmentType: e.target.value }))
							}
						>
							<option disabled={true} selected={!patchVacancy.employmentType}>
								выбрать
							</option>
							{Object.keys(labelsByEmploymentType).map(opt => (
								<option key={opt} value={opt}>
									{labelsByEmploymentType[opt]}
								</option>
							))}
						</select>
					</li>
					<li className='d-flex w-100 flex-row'>
						<p style={{ fontWeight: '600' }}>Опыт:&nbsp;</p>
						<select
							className={`${classes.ParameterSelect} ${
								!patchVacancy.experience ? classes.Underline : ''
							}`}
							value={patchVacancy.experience}
							onChange={e =>
								setPatchVacancy(p => ({ ...p, experience: e.target.value }))
							}
						>
							<option disabled={true} selected={!patchVacancy.experience}>
								выбрать
							</option>
							{Object.keys(labelsByExperience).map(opt => (
								<option key={opt} value={opt}>
									{labelsByExperience[opt]}
								</option>
							))}
						</select>
					</li>
					<li className='d-flex w-100 flex-row'>
						<p style={{ fontWeight: '600' }}>Зарплата:&nbsp;</p>
						<InputAutosize
							type='text'
							className={classes.ParameterInput}
							placeholder='указать'
							value={
								patchVacancy.salary
									? patchVacancy.salary.toLocaleString()
									: null
							}
							minWidth='1ch'
							defaultWidth='7ch'
							onChange={e => {
								const val = e.target.value
									? +e.target.value.replace(/\D/g, '')
									: 0
								setPatchVacancy(p => ({ ...p, salary: val }))
							}}
						/>
						{patchVacancy.salary ? (
							<>
								&nbsp;
								<select
									className={`${classes.ParameterSelect} ${
										!patchVacancy.salaryCurrency ? classes.Underline : ''
									}`}
									style={{ width: '8ch' }}
									value={patchVacancy.salaryCurrency}
									onChange={e =>
										setPatchVacancy(p => ({
											...p,
											salaryCurrency: e.target.value,
										}))
									}
								>
									<option
										disabled={true}
										selected={!patchVacancy.salaryCurrency}
									>
										валюта
									</option>
									{Object.keys(labelsBySalaryCurrency).map(opt => (
										<option key={opt} value={opt}>
											{labelsBySalaryCurrency[opt]}
										</option>
									))}
								</select>
							</>
						) : (
							''
						)}
					</li>
				</ul>
				<button className='btn danger' onClick={deleteBtnHandler}>
					Удалить вакансию
				</button>
			</div>
			<div className='col-lg-8'>
				<div className={classes.VacancyDescBlock + ' p-4 p-xl-5 w-sm-max '}>
					<input
						type='text'
						className='w-100 fs-3 no_outline fw-semibold'
						placeholder='Наименование'
						ref={nameRef}
						value={patchVacancy.name}
						onChange={e =>
							setPatchVacancy(p => ({ ...p, name: e.target.value }))
						}
					/>
					<div className='d-flex flex-row flex-wrap gap-3 align-items-center'>
						{(patchVacancy.keySkills || []).map((s, i) => (
							<KeySkill key={i} i={i} s={s} setPatchVacancy={setPatchVacancy} />
						))}
						<PlusSvg
							style={{ cursor: 'pointer' }}
							className='ms-2'
							onClick={() =>
								setPatchVacancy(p => ({
									...p,
									keySkills: [...p.keySkills, ''],
								}))
							}
						/>
					</div>
					<TextareaAutosize
						minHeight='1.5rem'
						defaultHeight='1.5em'
						className='no_outline fs-6 fw-medium'
						value={patchVacancy.shortDescription}
						placeholder='Введите сюда краткое описание вакансии'
						onChange={e =>
							setPatchVacancy(p => ({ ...p, shortDescription: e.target.value }))
						}
					/>
					<MDXEditor
						markdown={vacancy.description || ''}
						style={{}}
						placeholder='Описание вакансии'
						plugins={[
							headingsPlugin(),
							quotePlugin(),
							listsPlugin(),
							markdownShortcutPlugin(),
							toolbarPlugin({
								toolbarContents: () => (
									<>
										<UndoRedo />
										<BoldItalicUnderlineToggles />
										<CodeToggle />
										<ListsToggle />
										<BlockTypeSelect />
									</>
								),
							}),
						]}
						contentEditableClassName={classes.DescriptionField + ' p-0 '}
						ref={mdEditorRef}
						onChange={updateVacancy}
					/>
				</div>
			</div>
		</div>
	)
}

const KeySkill = ({ s, i, setPatchVacancy }) => {
	return (
		<span className='delete-parent delete-not-mobile'>
			<button
				className='rounded-start p-2'
				style={{ top: '5px', backgroundColor: COLORS.light_neutral }}
				onClick={() =>
					setPatchVacancy(p => ({
						...p,
						keySkills: p.keySkills.filter((s, idx) => idx !== i),
					}))
				}
			>
				<DeleteOutline fill='black' />
			</button>
			<InputAutosize
				type='text'
				className='fw-semibold px-3 py-2 rounded-end no_outline'
				style={{ backgroundColor: COLORS.light_neutral, marginLeft: '1.5px' }}
				placeholder='Навык'
				minWidth='4ch'
				defaultWidth='5.25rem'
				value={s}
				onChange={e => {
					setPatchVacancy(p => ({
						...p,
						keySkills: p.keySkills.map((s, idx) =>
							idx === i ? e.target.value : s
						),
					}))
				}}
			/>
		</span>
	)
}

const ParamAdvSelect = ({ needBorder = false, ...props }) => {
	return (
		<Select
			theme={t => ({ ...t, colors: { ...t.colors, primary: 'transparent' } })}
			styles={{
				control: styles => ({
					...styles,
					outline: 'none',
					border: needBorder ? `0.8px solid ${COLORS.gray}` : 'none',
					fontSize: '1rem',
					flexGrow: 1,
					minHeight: 'fit-content',
					cursor: 'pointer',
				}),
				input: styles => ({ ...styles, padding: 0, margin: 0 }),
				valueContainer: styles => ({ ...styles, padding: 0 }),
				placeholder: styles => ({
					...styles,
					textDecoration: 'underline',
					color: 'black',
				}),
				indicatorsContainer: styles => ({ ...styles, display: 'none' }),
				container: styles => ({ ...styles, width: '100%' }),
			}}
			{...props}
		/>
	)
}
