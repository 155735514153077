import {SOURCE} from "../constants"

export async function createAiChat(token, applicantId) {
    try {
        const response = await fetch(
            `${SOURCE.new_url}applicantAiChats`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    applicantId: applicantId
                })
            }
        )
        if (!response.ok) return false
        return await response.json()
    } catch (error) {
        console.error(error)
        return false
    }
}

export async function createAiMessage(token, chatId, question) {
    try {
        const response = await fetch(
            `${SOURCE.new_url}applicantAiChatMessages`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    chatId: chatId,
                    question: question
                })
            }
        )
        if (!response.ok) return false
        return await response.json()
    } catch (error) {
        console.error(error)
        return false
    }
}

export const getAiChat = async (token, chatId, queryObj = {}) => {
    try {
        let query = '?'

        let include = queryObj.include || []
        include.forEach(el => {
            if (query !== '?') query += '&'
            query += 'include=' + el
        })
        const response = await fetch(
            `${SOURCE.new_url}applicantAiChats/` + chatId +  query,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
            }
        )

        if (!response.ok) return null
        return await response.json()
    } catch (e) {
        console.error(e)
        return null
    }
}