import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'
import { FilterCandidatesModal } from '../../components/Modals'
import { DefaultAvatar } from '../../components/ui/DefaultAvatar'
import {
  COLORS,
  SOURCE,
  labelsBySalaryCurrency,
  useDebounceCallback,
} from '../../constants'
import { ReactComponent as PlaceSvg } from '../../icons/place.svg'
import { ReactComponent as SettingsSvg } from '../../icons/settings.svg'
import { ReactComponent as CoinsSvg } from '../../icons/coins.svg'
import {
  getAllVacancyResponsesAsync,
  setCities,
} from '../../store/slices/candidateSlice'
import classes from './Candidates.module.scss'
import { Loader } from '../../components/Loader'
import { getMyVRCities } from '../../api/vacancyResponses'
import { NavbarToBackSample } from '../../components/NavbarToBackSample'
import DocsIcon from '../../icons/document_icon.svg'
import { getGuestResponsePdf } from '../../api/guest'
import { GuestResponseInfoModal } from '../../components/Modals'
import { getGuestVacancyResponseResumeAndContacts } from '../../api/guest'
import { showErrorMsg } from '../../store/slices/alertSlice'

export const Candidates = () => {
  const candidatesData = useSelector(
    (state) => state.candidateSlice.candidatesData
  )
  const filters = candidatesData.filters
  const user = useSelector((state) => state.userSlice.user)
  const dispatch = useDispatch()
  const [state, setState] = useState({
    filterModalShown: false,
  })

  const applyFilters = useDebounceCallback(
    (filters) =>
      dispatch(
        getAllVacancyResponsesAsync({
          include: ['vacancy', 'candidate.resume'],
          filters,
        })
      ),
    500
  )

  const handlePdfLink = (id) => {
    (async () => {
      const response = await getGuestResponsePdf(user.access_token, id)
      return window.open(response)
    })()
  }

  useEffect(() => {
    applyFilters(filters)
  }, [filters, candidatesData.currentPage])

  useEffect(() => {
    (async () => {
      const cities = (await getMyVRCities(user.access_token)) || []
      dispatch(setCities(cities))
    })()
  }, [])

  const openFilterModal = () => {
    setState((p) => ({ ...p, filterModalShown: true }))
  }

  if (user.access_token === undefined) return <Navigate to='/logout' />
  if (!candidatesData || !candidatesData.loaded) return <Loader />
  if (candidatesData.candidates.length === 0)
    return <Navigate to='/not-found' />

  return (
    <div className='w-100 mx-auto mb-5 mt-4'>
      <div className={`${classes.Container}`}>
        <NavbarToBackSample
          className={`${classes.CandidatesHeader} mb-3`}
          last_children={
            <div
              className={classes.Filter + ' ms-auto'}
              onClick={openFilterModal}
            >
              <div className='me-2'>Фильтр</div> <SettingsSvg />{' '}
            </div>
          }
        >
          <h1 className='fs-5 fw-bold d-none d-md-flex align-items-center mb-0'>
            Все кандидаты
          </h1>
        </NavbarToBackSample>

        {!candidatesData.loaded ? (
          <Loader />
        ) : (
          <div className={`${classes.CardGrid} row  gy-2`}>
            {candidatesData.candidates.map((candidateResponse, i) => (
              <div className='col-xl-4 col-md-6 col-12' key={i}>
                <CandidateCard candidateResponse={candidateResponse} />
              </div>
            ))}
            {candidatesData.guestItems.map((candidateResponse, i) => (
              <div className='col-xl-4 col-md-6 col-12' key={i}>
                <GuestCandidateCard
                  candidateResponse={candidateResponse}
                  handlePdfLink={handlePdfLink}
                  user={user}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <FilterCandidatesModal state={state} setState={setState} />
    </div>
  )
}

const GuestCandidateCard = ({ candidateResponse, handlePdfLink, user }) => {
  const navigate = useNavigate()
  const resume = candidateResponse.resume
  const dispatch = useDispatch()
  const [isInfo, setIsInfo] = useState({
    infoModalShown: false,
  })
  const [resumeAndContacts, setResumeAndContacts] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const guestResumeAndContacts =
        await getGuestVacancyResponseResumeAndContacts(
          user.access_token,
          resume.id,
          {
            include: ['response', 'contacts'],
          }
        )
      if (!guestResumeAndContacts)
        return dispatch(
          showErrorMsg('Ошибка при получении данных о гостевом отклике')
        )
      setResumeAndContacts((p) => ({
        ...p,
        guestResumeAndContacts,
      }))
    }

    fetchData()
  }, [user.access_token, dispatch])

  return (
    <>
      <div
        className='d-flex position-absolute px-2 rounded-top'
        style={{
          translate: '0 -100%',
          background: `${COLORS.success}`,
          color: 'white',
          maxWidth: '151px',
        }}
      >
        Гостевой отклик
      </div>
      {isInfo.infoModalShown && (
        <GuestResponseInfoModal
          guestResponse={resumeAndContacts}
          shown={isInfo.infoModalShown}
          set={setIsInfo}
        />
      )}
      <div
        onClick={() => setIsInfo((p) => ({ ...p, infoModalShown: true }))}
        className='d-flex flex-column h-100 '
        style={{
          border: `1px solid ${COLORS.success}`,
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          borderTopRightRadius: '5px',
        }}
      >
        <div
          className='text-center rounded py-1'
          style={{ background: 'white' }}
        >
          <span style={{ color: '#849095' }}>
            Вакансия{' '}
            <span
              className='text-decoration-underline'
              onClick={() =>
                navigate('/account/vacancies/' + candidateResponse.vacancy?.id)
              }
              style={{ color: '#6757F1', cursor: 'pointer' }}
            >
              «{candidateResponse.vacancy?.name}»
            </span>
          </span>
        </div>
        <div
          className={`${classes.Card} rounded d-flex flex-column`}
          style={{ flex: '1' }}
        >
          <div
            className={classes.CardHead + ' mb-2'}
            style={{ cursor: 'pointer' }}
          >
            <div className='ms-3'>
              <DefaultAvatar
                className={classes.Avatar + ' img-fluid rounded'}
                type='b2c'
                style={{ objectFit: 'cover' }}
                src={SOURCE.new_url + `guestApplicants/${resume.id}/avatar`}
              />
            </div>
            <div className={classes.WhoBox}>
              <p className={classes.Position + ' fs-6 mb-1'}>{resume.title}</p>
              <p className={classes.Name + ' fs-5 mb-1'}>
                {resume.firstName} {resume.lastName}
              </p>
            </div>
            {resume.desiredSalary && (
              <div className='text-nowrap'>
                <CoinsSvg className='me-2' />
                {(+resume.desiredSalary).toLocaleString()}{' '}
                {labelsBySalaryCurrency[resume.desiredSalaryCurrency]}
              </div>
            )}
          </div>
          <div
            className={'rounded border p-2 d-flex mx-5 mb-2'}
            onClick={() => handlePdfLink(candidateResponse.id)}
          >
            <img src={DocsIcon} alt='DocsLogo' width={45} height={45} />
            <div>
              <p className='column ms-2 text-decoration-underline'>
                Посмотреть резюме
              </p>
              <p
                className='column ms-2'
                style={{
                  color: COLORS.gray_text,
                  fontWeight: 'regular',
                  fontSize: '14px',
                }}
              >
                PDF
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CandidateCard = ({ candidateResponse }) => {
  const candidate = candidateResponse.candidate
  const navigate = useNavigate()

  const clickButtonHandler = () => {
    if (candidate.id) navigate(`/candidates/${candidate.id}`)
  }
  if (!candidate) return <Navigate to='/not-found' replace />

  let skills = [...candidate.resume.skills]
  skills = skills
    .sort((a, b) => candidateResponse.vacancy.keySkills.indexOf(b) >= 0)
    .slice(0, skills.reduce((s, c) => s + c.length, 0) > 21 ? 2 : 3)
  return (
    <div className='d-flex flex-column h-100'>
      <div
        className={`${classes.Card} rounded-top d-flex flex-column`}
        style={{ flex: '1' }}
      >
        <div
          className={classes.CardHead + ' mb-3'}
          onClick={clickButtonHandler}
          style={{ cursor: 'pointer' }}
        >
          <div className='ms-3'>
            <DefaultAvatar
              className={classes.Avatar + ' img-fluid rounded'}
              type='b2c'
              style={{ objectFit: 'cover' }}
              src={SOURCE.new_url + `applicants/${candidate.id}/avatar`}
            />
          </div>
          <div className={classes.WhoBox}>
            <div className={classes.Name + ' fs-5 mb-2'}>
              {candidate.firstName} {candidate.lastName}
            </div>
            <div className={classes.Position + ' fs-6'}>
              {candidate.resume?.title}
            </div>
          </div>
        </div>

        <div className={classes.CardSkills + ' row gy-3 fs-6 mb-4'}>
          {skills.map((el, i) => {
            const active = candidateResponse.vacancy.keySkills.indexOf(el) >= 0
            return (
              <div className='col-auto ' key={i}>
                <p
                  className='py-2 px-3 rounded text-nowrap text-center'
                  style={{
                    backgroundColor: active
                      ? COLORS.warning
                      : COLORS.light_neutral,
                    color: active ? 'white' : COLORS.text,
                  }}
                >
                  {el}
                </p>
              </div>
            )
          })}
        </div>
        <div className={classes.CardInfo + ' flex-wrap gy-2 fs-6'}>
          <div className='text-nowrap'>
            <PlaceSvg className='me-2' />
            {candidate.resume?.city ? candidate.resume?.city : 'Россия'}
          </div>
          {candidate.resume?.desiredSalary && (
            <div className='text-nowrap'>
              <CoinsSvg className='me-2' />
              {(+candidate.resume?.desiredSalary).toLocaleString()}{' '}
              {labelsBySalaryCurrency[candidate.resume?.desiredSalaryCurrency]}
            </div>
          )}
        </div>
      </div>
      <div
        className='text-center rounded-bottom py-1'
        style={{ backgroundColor: '#F3F3F3' }}
      >
        <span
          className='text-decoration-underline'
          onClick={() =>
            navigate('/account/vacancies/' + candidateResponse.vacancy?.id)
          }
          style={{ color: '#6757F1', cursor: 'pointer' }}
        >
          «{candidateResponse.vacancy?.name}»
        </span>
      </div>
    </div>
  )
}
