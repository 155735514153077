import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Loader } from '../../../../components/Loader'
import { OfferModal, VideoModal } from '../../../../components/Modals'
import { DefaultAvatar } from '../../../../components/ui/DefaultAvatar'
import {
  COLORS,
  SOURCE,
  formatDateExperience,
  isResumeFilled,
  labelsBySalaryCurrency,
  toDate,
} from '../../../../constants'
import { ReactComponent as CoinsSvg } from '../../../../icons/coins.svg'
import { ReactComponent as HurtleLogo } from '../../../../icons/hurtle_logo.svg'
import { ReactComponent as LocationSvg } from '../../../../icons/location.svg'
import { showErrorMsg } from '../../../../store/slices/alertSlice'
import { SelectFunnel } from '../../../account/vacancy/VacancyFunnelBlock/VacancyFunnelBlock'
import { ContactMenuSelect } from './ContactMenuSelect'
import classes from './Resume.module.scss'
import { changeVacancyResponseStatus } from '../../../../api/vacancyResponses'
import {
  BasicOfferSchema,
  createOffer,
  deleteOffer,
  getMyOffers,
} from '../../../../api/offers'

export const Resume = ({ candidateInfo, updateCandidateInfo, chatId }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const [offers, setOffers] = useState([])
  const myVacancies = useSelector(
    (state) => state.vacanciesSlice.vacanciesInfo
  )
  const token = useSelector((state) => state.userSlice.user.access_token)
  const candidate = candidateInfo.candidate
  const resume = candidateInfo.resume
  const helloMeeting =
    candidate.meetings.find((m) => m.type === 'INTERVIEW') || null
  const [state, setState] = useState({
    videoModalShown: false,
    videoUrl: SOURCE.new_url + `meetings/${helloMeeting?.id}/video`,
  })
  let responses = candidate.vacancyResponses
    .filter(
      (r) =>
        myVacancies.vacancies.filter((v) => v.id === r.vacancyId).length === 1
    )
    .map((r) => ({
      ...r,
      vacancy: myVacancies.vacancies.filter((v) => v.id === r.vacancyId)[0],
    }))

  const changeStatusHandler = async (v, id) => {
    const status = v || 'NEW_APPLICATION'
    const res = await changeVacancyResponseStatus(token, id, status)
    if (res !== null) updateCandidateInfo()
  }
  useEffect(() => {
    getMyOffers(token, ['vacancyResponse']).then((r) => {
      (r.items || []).forEach((el) => {
        setOffers((p) => [
          ...new Set([...p, { response: el.vacancyResponseId, offer: el.id }]),
        ])
      })
    })
  }, [])

  // useEffect(() => {
  // 	( async () => {
  // 		const video = await getVideo(token, SOURCE.new_url + `meetings/${helloMeeting.id}/video`)
  // 		if (!video) return dispatch(showErrorMsg('Ошибка при загрузке видео'))
  // 		setState({ ...state, videoU: video })
  // 	})()
  // }, [helloMeeting])

  return !myVacancies.loaded ? (
    <Loader />
  ) : (
    <div className='row gy-3'>
      {helloMeeting?.id && state.videoModalShown && (
        <VideoModal state={state} setState={setState} />
      )}
      <div className='col-xxl-9 col-xl-8 col-12'>
        <div className='rounded bg-white p-md-5 p-4 w-sm-max h-100'>
          <div className='row gy-3 mb-md-5 mb-4'>
            <div className='col-auto'>
              <DefaultAvatar
                className={classes.ResumeMainAvatar + ' img-fluid rounded'}
                style={{ objectFit: 'cover' }}
                src={SOURCE.new_url + `applicants/${candidate.id}/avatar`}
                alt='user'
              />
            </div>
            <div className='col d-flex'>
              <div className='d-flex flex-column my-auto'>
                <div className={'fw-bolded fs-3 mb-1'}>
                  {`${candidate?.firstName} ${candidate?.lastName}`}
                </div>
                <div
                  className='fs-5 fw-bolded mb-1 mb-sm-2'
                  style={{ color: COLORS.gray_text }}
                >
                  {resume?.title}
                </div>
                <div
                  className='row gy-1 gy-sm-2 '
                  style={{ color: COLORS.gray_text }}
                >
                  <div className='col-sm text-nowrap'>
                    <LocationSvg height='25px' width='25px' className='me-2' />
                    {resume?.city || 'Город не указан'}
                  </div>
                  <div className='col text-nowrap'>
                    <CoinsSvg height='25px' width='25px' className='me-2' />
                    {!resume?.desiredSalary
                      ? 'з/п не указана'
                      : `${resume?.desiredSalary.toLocaleString()} ${
                          labelsBySalaryCurrency[resume?.desiredSalaryCurrency]
                        }`}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-auto'>
              <ContactMenuSelect contacts={resume.contacts || []} />
            </div>
          </div>
          {isResumeFilled(resume) ? (
            <>
              {resume?.summary?.length > 0 && (
                <>
                  <h2 className='fs-3 mb-3 mt-sm-5 mt-4'>О себe</h2>
                  <p>{resume.summary}</p>
                </>
              )}
              {resume?.skills.length > 0 && (
                <>
                  <h2 className='fs-3 mb-3 mt-sm-5 mt-4'>Навыки</h2>
                  <div className='row g-2 g-sm-3 '>
                    {resume?.skills?.length ? (
                      resume?.skills.map((el) => (
                        <div className='col-auto' key={el}>
                          <p
                            className='px-3 py-sm-2 py-1 fs-6 rounded'
                            style={{ backgroundColor: COLORS.light_neutral }}
                          >
                            {el}
                          </p>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                </>
              )}
              {resume?.education.length > 0 && (
                <>
                  <h2 className='fs-3 mb-3 mt-sm-4 mt-3'>Образование</h2>
                  <div className=' d-flex flex-column'>
                    {resume?.education.map((el) => (
                      <div key={el.name} className={'row gy-2'}>
                        <div
                          className={
                            classes.UniversitySectionOne + ' col-sm-4 col-md-3'
                          }
                        >
                          <p className='fs-6' style={{ color: COLORS.primary }}>
                            {el.name}
                          </p>
                          <p>{`${el.degree}, ${el.endYear}`}</p>
                        </div>

                        <div className='col'>
                          <p
                            className='fs-6'
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {el.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {resume?.experience.length > 0 && (
                <>
                  <h2 className='fs-3 mb-3 mt-sm-4 mt-3'>Опыт работы</h2>
                  <div className=' d-flex flex-column'>
                    {resume?.experience.map((el) => (
                      <div key={el.company} className=' row gy-3 mb-3'>
                        <div
                          className={
                            classes.ExperienceColumn + ' col-sm-4 col-md-3'
                          }
                        >
                          <div
                            className={
                              classes.ExperienceColumnName + ' fs-6 mb-2'
                            }
                          >
                            {el.company}
                          </div>
                          <div className='text-wrap'>{el.position}</div>
                          <div className={classes.ExperienceColumnDate}>
                            {formatDateExperience(
                              el.startMonth,
                              el.startYear,
                              el.endMonth,
                              el.endYear
                            )}
                          </div>
                        </div>

                        <div className='col'>
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            {el.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          ) : (
            <p className='fs-5 mt-4'>У кандидата отсутствует резюме</p>
          )}
        </div>
      </div>

      <div className='col d-flex flex-column gap-sm-4 gap-3'>
        {chatId && (
          <div className='d-flex flex-column bg-white w-sm-max rounded px-md-5 p-4'>
            <p
              className='fs-5 mb-3 text-center'
              style={{ color: COLORS.primary }}
            >
              Нейрорезюме
            </p>
            <p className='fs-6'>
              Поговорите с нейрорезюме — мы проанализировали резюме и
              собеседование кандидата, и теперь вы можете задать все
              интересующие вопросы прямо сейчас, в чате
            </p>
            <button
              onClick={() => navigate(`/candidates/${candidate.id}/neuro`)}
              className='btn neuro rounded py-2 mt-sm-5 mt-3 fs-6'
            >
              Перейти
            </button>
          </div>
        )}

        <div className='bg-white rounded w-sm-max px-md-5 p-4'>
          <div className='mb-2'>
            <HurtleLogo className='me-3' />
            Собеседование
          </div>
          <div className='fs-bold'>
            {!helloMeeting
              ? 'Не пройдено'
              : ' Пройдено ' + toDate(helloMeeting.updatedAt)}
          </div>
          {helloMeeting?.id && (
            <button
              onClick={() => setState({ ...state, videoModalShown: true })}
              className={classes.WatchResults + ' mt-2'}
            >
              {/* window.open(state.videoUrl) */}
              {state.video === 0 ? (
                <Loader size='small' color='white' />
              ) : (
                'Посмотреть запись'
              )}
            </button>
          )}
          {/* <button className={classes.OpenPortfolio + '  mt-3'}>
                    Открыть портфолио
                </button> */}
        </div>
        {responses.slice(0, expanded ? undefined : 2).map((r, i) => (
          <Response
            key={i}
            changeStatusHandler={(v) => changeStatusHandler(v, r.id)}
            response={r}
            candidate={candidate}
            resume={resume}
            offers={offers}
          />
        ))}
        {responses.length > 2 && (
          <p
            onClick={() => setExpanded(!expanded)}
            className='text-decoration-underline mx-auto mt-2'
            style={{ color: COLORS.gray_text, cursor: 'pointer' }}
          >
            {expanded ? 'Скрыть' : 'Показать ещё'}
          </p>
        )}
      </div>
    </div>
  )
}

const Response = ({
  response,
  changeStatusHandler,
  candidate,
  resume,
  offers,
}) => {
  const token = useSelector((state) => state.userSlice.user.access_token)
  const dispatch = useDispatch()

  const [state, setState] = useState({
    salary: 0,
    message: '',
    show: false,
    showAccept: false,
    acceptDeleteOffer: () => {},
  })

  const acceptOfferAction = async () => {
    changeStatusHandler('OFFER_MADE')

    const offerData = {
      salary: state.salary,
      salaryCurrency:
        resume.desiredSalaryCurrency || labelsBySalaryCurrency.RUB,
      message: state.message,
      vacancyResponseId: response.id,
    }

    try {
      await BasicOfferSchema.validate(offerData)
    } catch (err) {
      dispatch(showErrorMsg(err.errors))
      return
    }

    const r = await createOffer(token, offerData)
    if (!r || !r.ok) return dispatch(showErrorMsg('Что-то пошло не так'))
    setState((p) => ({ ...p, show: false }))
  }
  const acceptDeleteOffer = async (response, value) => {
    const offer_id = offers.find((x) => x.response === response.id).offer
    const deleteRes = await deleteOffer(token, offer_id)
    if (!deleteRes) return dispatch(showErrorMsg('Ошибка при удалении'))
    const changeRes = await changeStatusHandler(value)
    setState((p) => ({ ...p, showAccept: false }))

    console.log(
      `delete offer ${response.id}`,
      `change status ${changeStatusHandler} ${value}`,
      `close modal ${state.showAccept}`
    )
  }

  const redefineChangeStatusHandler = (v) => {
    if (v === 'OFFER_MADE') {
      setState((p) => ({ ...p, show: true }))
    } else {
      if (response.status === 'OFFER_MADE') {
        if (window.confirm('вы действительно хотите удалить оффер?')) {
          acceptDeleteOffer(response, v)
        }
      } else {
        changeStatusHandler(v)
      }
    }
  }
  return (
    <div className='bg-white w-sm-max rounded px-md-5 p-4'>
      <p className='fs-6 fw-bold mb-2'>{response.vacancy.name}</p>
      <div className='mb-3 fs-6'>
        Дата отклика:&nbsp;
        <span className='fw-bold'>
          {new Date(response.createdAt).toLocaleDateString()}
        </span>
      </div>
      <SelectFunnel
        currentValue={response.status}
        changeStatusHandler={redefineChangeStatusHandler}
      />

      <OfferModal
        state={state}
        candidate={candidate}
        resume={resume}
        acceptOfferAction={acceptOfferAction}
        setState={setState}
      />
    </div>
  )
}
