import {Stack} from "react-bootstrap"
import {Route, Routes, useLocation, useNavigate} from "react-router"
import {MainHeader} from "./components/MainHeader"
import {Footer} from "./components/Footer"
import {Loader} from "./components/Loader"
import {AuthScreen} from "./screens/auth/AuthScreen"
import {Contacts} from "./screens/main/Contacts"
import Cookies from "js-cookie"
import {useDispatch} from "react-redux"
import {setUser} from "./store/slices/userSlice"
import {useEffect, useState} from "react"
import {AccountScreen} from "./screens/account/AccountScreen"
import {NotFoundPage} from "./screens/system/NotFoundPage"
import {AcceptCookie} from "./components/AcceptCookie"
import {Candidate, CandidateScreen} from "./screens/candidates/candidate/CandidateScreen"
import {InterviewResults} from "./screens/candidates/InterviewResults"
import {PrivacyPolicy} from "./screens/system/PrivacyPolicy"
import {MainLanding} from "./screens/main/MainLanding"
import {COLORS, ErrorMsg, MAX_WIDTH, PADDINGS, SuccessMsg} from "./constants"
import './buttons.scss'
import { Candidates } from "./screens/candidates/Candidates"
import ErrorBoundary from "./components/ErrorBoundary"
import { SpamPage } from './screens/system/SpamPage'
import { clearVacancy } from './store/slices/vacanciesSlice'

function App() {
    const [state, setState] = useState({
        headerScrollClasses: ''
    })
    const loc = useLocation()
    const bgColor = loc.pathname === '/' ? '#FAF5ED' : (loc.pathname.includes('account') || loc.pathname.includes('candidate') || loc.pathname.includes('vacancy'))
        ? COLORS.main_bg : "#fff"

    useEffect(() => {
        let lastScroll = 0
        let headerScrollUp = true
        let headerScrollDown = false
        const listener = () => {
            if (window.scrollY <= 0) headerScrollUp = false
            else {
                if (window.scrollY > lastScroll && !headerScrollDown) {
                    headerScrollUp = false
                    headerScrollDown = true
                }
                if (window.scrollY < lastScroll && headerScrollDown) {
                    headerScrollUp = true
                    headerScrollDown = false
                }
            }
            const headerScrollClasses = `${headerScrollUp ? "header-scroll-up" : ""} ${headerScrollDown ? "header-scroll-down" : ""}`
            lastScroll = window.scrollY
            setState(p => ({...p, headerScrollClasses}))
        }
        window.addEventListener("scroll", listener)
        return () => window.removeEventListener("scroll", listener)
    }, [loc])

    return (
    <Stack style={{ minHeight: '100vh', backgroundColor: bgColor, }}>
        <ErrorBoundary>
            <div className={'d-flex flex-column' + PADDINGS}>
                <div className="w-100 mx-auto" style={{ maxWidth: MAX_WIDTH }}>
                    <ErrorMsg />
                    <SuccessMsg />
                    <div className={'w-100 position-fixed top-0 start-0 ' + state.headerScrollClasses} style={{
                        transition: 'all 0.3s ease-in-out',
                        backgroundColor: bgColor,
                        flexShrink: '1',
                        zIndex: 1000
                    }}>
                        <div className={PADDINGS + ' mx-auto'}>
                            <div className='w-100 mx-auto' style={{ maxWidth: MAX_WIDTH, flex: '1',  }}>
                                <MainHeader/>
                            </div>
                        </div>
                    </div>
                    <div className="w-100" style={{marginTop: "5.25rem"}}>
                        <Routes>
                            <Route path='/' element={<MainLanding/>}/>
                            <Route path='account/*' element={<AccountScreen/>}/>
                            <Route path='auth/*' element={<AuthScreen/>}/>
                            <Route path='contacts' element={<Contacts/>}/>
                            <Route path='logout' element={<Logout/>}/>
                            <Route path='spam' element={<SpamPage/>}/>
                            <Route path='/candidates' element={<Candidates/>}/>
                            <Route path='candidates/:id/*' element={<CandidateScreen/>}/>
                            <Route path='policy' element={<PrivacyPolicy/>}/>
                            <Route path='*' element={<NotFoundPage/>}/>
                            {/*?*/}
                        </Routes>
                    </div>
                </div>
            </div>

        </ErrorBoundary>
        <Footer/>
        <AcceptCookie/>
    </Stack>
    )
}

const Logout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        Cookies.remove('access_token')

        setTimeout(() => {
            dispatch(setUser({
                loaded: false,
                access_token: undefined,
                id: "",
                createdAt: "",
                updatedAt: "",
                email: "",
                login: "",
                contact: "",
                firstName: "",
                middleName: "",
                lastName: "",
                phone: "",
                isConfirmedByManager: false,
                name: "",
                inn: "",
                ogrn: "",
                legalForm: true,
                agreementNumber: "",
                agreementDate: "",
                vacancies: [],
                meetings: [],
            }))
            dispatch(clearVacancy())
            navigate('/auth')

        }, 1000)
    })

    return <Loader/>
}

export default App
