import {SOURCE, yupOneOfEnum, yupUint32} from "../constants"
import * as yup from "yup"

export const BasicOfferSchema = yup.object({
    salary: yupUint32().defined().max(100_000_000, 'Можно предложить максимум 100 000 000'),
    salaryCurrency: yupOneOfEnum(["RUB", "USD", "EUR", "GBP", "GEL", "AZN", "BYR", "KGS", "KZT", "UZS"]).defined(),
    message: yup.string().defined().trim().min(10, 'минимум 10 символов в сообщении').max(500, 'максимум 500 символов в сообщении'),
    vacancyResponseId: yup.string().defined().length(36),
})

export async function createOffer(access_token, data) {
    try {
        return await fetch(`${SOURCE.new_url}offers`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
            body: JSON.stringify(data),
        })
    } catch (e) {
        console.error(e)
        return null
    }
}

export async function getMyOffers(access_token, include = []) {
    try {
        let query = "?"
        if (include.length > 0) query += `include=${include.join('&include=')}`
        const res = await fetch(`${SOURCE.new_url}offers/my${query}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
        })
        if (!res.ok) return null
        return await res.json()
    } catch (e) {
        console.error(e)
        return null
    }
}

export async function deleteOffer(access_token, id) {
    try {
        return await fetch(`${SOURCE.new_url}offers/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: access_token,
            },
        })
    } catch (e) {
        console.error(e)
        return null
    }
}