import { useState } from 'react'
import { useNavigate } from 'react-router'
import { COLORS } from '../../constants'
import { ReactComponent as ArrowRightSvg } from '../../icons/main_landing/arrow_right.svg'
import { ReactComponent as CarouselPrevSvg } from '../../icons/main_landing/carousel_prev.svg'
import { ReactComponent as CarouselNextSvg } from '../../icons/main_landing/carousel_next.svg'
import './styles.css'
import { Row } from 'react-bootstrap'

export const MainLanding = () => {
	const [state, setState] = useState({
		email: '',
	})
	const navigate = useNavigate()
	return (
		<div className='w-100 pt-sm-4 pb-5'>
			<div className='row gy-3 mb-5'>
				<div className='col-lg-8 d-flex flex-column'>
					<div className='d-flex flex-column mb-4'>
						<p className='fs-1 fw-bold mb-5'>
							Здесь находят{' '}
							<span style={{ color: COLORS.primary }}>уникальных</span>{' '}
							кандидатов и узнают про них всё
						</p>

						<p className='fs-3 fw-bolded mb-4' style={{ maxWidth: '530px' }}>
							Повышайте{' '}
							<span style={{ color: COLORS.primary }}>эффективность</span>{' '}
							вашего найма вместе с Хартл:
						</p>
						{[
							'Бесплатно создавайте и размещайте вакансии',
							'Анализируйте кандидатов вместе с нейрорезюме',
							'Смотрите собеседования, которые прошли кандидаты у нас',
						].map((t, i) => (
							<div className='d-flex align-items-center mb-4'>
								<ArrowRightSvg
									style={{ cursor: 'pointer' }}
									height='20px'
									width='25px'
								/>
								<p className='fs-5 fw-bolded ms-3'>{t}</p>
							</div>
						))}
					</div>
					<button
						onClick={() => navigate('/account')}
						className='btn primary rounded-pill px-5 py-sm-3 py-2 fs-5 fs-sm-4 mt-auto me-sm-auto'
					>
						Опубликовать вакансию
					</button>
				</div>
				<div className='col d-none d-md-flex'>
					<img
						src={require('../../images/main/head.png')}
						className='mb-3'
						style={{ height: 'auto', width: '100%', overflow: 'hidden' }}
					/>
				</div>
			</div>

			<div className='d-flex mt-sm-5'>
				<ArrowRightSvg
					height='26px'
					width='30px'
					style={{ flexShrink: '0' }}
					className='mt-sm-3 mt-2 me-3'
				/>
				<div className='d-flex flex-column'>
					<p
						className='fs-1 fw-bold mb-4'
						style={{ color: COLORS.light_black }}
					>
						Почему вы должны разместить вакансию здесь
					</p>
				</div>
			</div>

			<div className='row gy-3 gy-sm-4 justify-content-center'>
				{[
					{
						img: 'why1.png',
						title: 'Бесплатно',
						text: 'Мы не берём никаких комиссий за размещение вакансии, а также за просмотр контактов',
					},
					{
						img: 'why2.png',
						title: 'Круглосуточная поддержка',
						text: 'Наши специалисты помогут решить любые трудности, а также расскажут про платформу',
					},
					{
						img: 'why3.png',
						title: 'Проверенные кандидаты',
						text: 'С каждым кандидатом мы самостоятельно провели собеседование по авторской методологии',
					},
					{
						img: 'why4.png',
						title: 'Умная аналитика',
						text: 'Нейросеть поможет выбрать подходящего специалиста и выделит его среди других',
					},
					{
						img: 'why5.png',
						title: 'Очень быстро',
						text: 'За счёт применения инновационных технологий на просмотр одного кандидата уходит не более 30 секунд',
					},
				].map((el, i) => (
					<WhiteBlock key={i} data={el} />
				))}
			</div>

			<div className='row mt-5 mb-sm-5'>
				<div className='col-lg-7 d-flex'>
					<ArrowRightSvg
						height='26px'
						width='30px'
						style={{ flexShrink: '0' }}
						className='mt-sm-3 mt-2 me-3'
					/>
					<div className='d-flex flex-column'>
						<p
							className='fs-1 fw-bold mb-4'
							style={{ color: COLORS.light_black }}
						>
							Мы сами проводим собеседование
						</p>
						<p className='fs-5' style={{ maxWidth: '650px' }}>
							Кандидаты, зарегистрированные на нашей платформе проходят
							собеседование с профессиональными IT-рекрутерами. На нём мы сами
							задаем все необходимые вопросы кандидату, а также проверяем его
							знания в профессиональной области. Обо всём, что мы узнали на
							собеседовании – вы можете спросить нейрорезюме
						</p>
					</div>
				</div>
				<div className='col d-none d-lg-flex'>
					<img
						src={require('../../images/main/iphone.png')}
						className='h-100'
						style={{ height: 'auto', width: '100%', overflow: 'hidden' }}
					/>
				</div>
			</div>

			<div className='d-flex mt-5'>
				<ArrowRightSvg
					height='26px'
					width='30px'
					style={{ flexShrink: '0' }}
					className='mt-sm-3 mt-2 me-3'
				/>
				<div className='d-flex flex-column'>
					<p
						className='fs-1 fw-bold mb-4'
						style={{ color: COLORS.light_black }}
					>
						Как это работает
					</p>
				</div>
			</div>

			<div className='row gy-3 justify-content-center'>
				{[
					{
						img: 'step1.png',
						title: '1. Создайте и опубликуйте вакансию',
						text: 'Расскажите об открытой позиции с помощью нашего удобного конструктора вакансий. Сразу после публикации вы начнете получать первых кандидатов, которых порекомендует наша нейросеть.',
					},
					{
						img: 'step2.png',
						title: '2. Получайте уникальные отклики',
						text: 'Мы продвигаем нашу платформу среди профессионалов самыми разнообразными методами – пишем им в социальных сетях, встречаемся на мероприятиях и рассказываем о себе в профильных каналах. Поэтому каждый кандидат, которого вы тут встретите – действительно уникальный.',
					},
					{
						img: 'step3.png',
						title: '3. Смотрите нейрорезюме',
						text: 'Нейрорезюме – это инструмент анализа кандидата с помощью общения с генеративной моделью искусственного интеллекта, обученной на данных из резюме и собеседования, которое проводит Хартл. С ним вы сможете гораздо эффективнее анализировать приходящих специалистов и сразу узнать необходимую информацию – почему кандидат ушел с предыдущего места работы или работал ли он с K8s.',
					},
					{
						img: 'step4.png',
						title: '4. Сделайте правильный выбор',
						text: 'Наконец, найдите того самого кандидата и закройте вакансию. Начальство будет гордиться вами.',
					},
				].map((el, i) => (
					<BlackCard key={i} obj={el} />
				))}
			</div>
			<div className='d-flex mt-5'>
				<ArrowRightSvg
					height='26px'
					width='30px'
					style={{ flexShrink: '0' }}
					className='mt-sm-3 mt-2 me-3'
				/>
				<div className='d-flex flex-column'>
					<p
						className='fs-1 fw-bold mb-4'
						style={{ color: COLORS.light_black }}
					>
						Принимайте правильное решение
					</p>
				</div>
			</div>

			<ControlledCarousel />

			<div
				id='background_image'
				className='py-sm-5 p-4 d-flex flex-column mt-5'
				style={{
					width: '100%',
					height: 'auto',
					borderRadius: '40px',
				}}
			>
				<p
					className='fw-bold fs-1 mx-auto mb-sm-5 mb-4'
					style={{ color: 'white' }}
				>
					Разместим вашу первую вакансию?
				</p>
				<button
					className='btn light_black py-sm-3 py-2 px-5 mx-sm-auto rounded-pill'
					onClick={() => navigate('/auth')}
				>
					Разместить вакансию
				</button>
			</div>
		</div>
	)
}

function ControlledCarousel() {
	return (
		<div
			id='carouselExampleInterval'
			className='carousel carousel-dark slide mb-5'
			data-bs-ride='carousel'
		>
			<div className='container my-3' style={{ width: '85%' }}>
				<div
					className='carousel-inner p-3'
					style={{
						backgroundColor: '#BF81E2',
						borderRadius: '50px',
					}}
				>
					{[
						{
							title: 'Конструктор вакансий',
							text: (
								<>
									Удобный конструктор, с помощью которого вы можете рассказать
									<br /> о своей позиции самое главное. Ничего лишнего, только
									текст.
								</>
							),
							img: 'carousel1.png',
							active: 'active',
						},
						{
							title: 'Воронка кандидатов',
							text: (
								<>
									Сортируйте всех кандидатов с нашей воронкой
									<br /> – от отклика до найма в команду.
								</>
							),
							img: 'carousel2.png',
						},
						{
							title: 'Умные рекомендации',
							text: (
								<>
									Нейросеть, а также наши рекрутеры порекомендуют подходящих
									<br /> к вашей вакансии кандидатов – это тоже бесплатно.
								</>
							),
							img: 'carousel3.png',
						},
						{
							title: 'Саммари',
							text: (
								<>
									Искусственный интеллект суммирует резюме и итоги собеседования
									<br />
									за вас и покажет самое главное из них.
								</>
							),
							img: 'carousel4.png',
						},
						{
							title: 'Круглосуточная поддержка',
							text: (
								<>
									Специалисты технической поддержки готовы помочь вам
									<br /> в разрешении любой проблемы 24 часа в сутки, 7 дней в
									неделю.
								</>
							),
							img: 'carousel5.png',
						},
					].map((el, i) => (
						<Slide data={el} key={i} />
					))}
				</div>
			</div>

			<button
				className='carousel-control-prev justify-content-start opacity-100'
				type='button'
				data-bs-target='#carouselExampleInterval'
				data-bs-slide='prev'
			>
				<CarouselPrevSvg width='45%' className='' />

				<span className='visually-hidden'>Previous</span>
			</button>
			<button
				className='carousel-control-next justify-content-end opacity-100'
				type='button'
				data-bs-target='#carouselExampleInterval'
				data-bs-slide='next'
			>
				<CarouselNextSvg width='45%' />

				<span className='visually-hidden'>Next</span>
			</button>
		</div>
	)
}

const Slide = ({ data }) => {
	return (
		<div className={`carousel-item ${data.active}`}>
			<div
				className='d-flex align-items-center gap-2'
				style={{ flexDirection: 'column' }}
			>
				<h2 className='h2 py-2' style={{ color: '#FAF5ED', fontWeight: 700 }}>
					{data.title}
				</h2>
				<h6
					className='h6 text-center'
					style={{ color: '#F6E7FF', lineHeight: '1.6rem' }}
				>
					{data.text}
				</h6>
				<div
					className=''
					style={{
						backgroundColor: '#2C2C2C',
						borderRadius: '40px',
						width: '95%',
						marginBottom: '2%',
					}}
				>
					<img
						src={require('../../images/main/' + data.img)}
						className='d-block w-100'
						alt='...'
					/>
				</div>
			</div>
		</div>
	)
}

const WhiteBlock = ({ data }) => {
	return (
		<div className='col-xl-4 col-sm-6'>
			<div
				className='bg-white d-flex flex-column justify-content-center p-4 p-md-5 h-100'
				style={{ borderRadius: '40px' }}
			>
				<img
					src={require('../../images/main/' + data.img)}
					className='mx-auto'
					style={{ maxWidth: '100%', maxHeight: '250px' }}
				/>
				<p className='fs-5 fw-bolded mt-3 mb-2'>{data.title}</p>
				<p className='fs-6'>{data.text}</p>
			</div>
		</div>
	)
}

const BlackCard = ({ obj }) => {
	return (
		<div className='col-md-6'>
			<div
				className='rounded-5 p-sm-5 p-3 pb-4 h-100'
				style={{ backgroundColor: COLORS.light_black }}
			>
				<div className='d-flex flex-column gy-2 flex-nowrap'>
					<div className='mb-3 pe-sm-auto'>
						<div className='bg-white rounded-5 d-flex p-sm-0 h-100'>
							<img
								src={require('../../images/main/' + obj.img)}
								className='mx-auto h-100'
								style={{ maxWidth: '100%', maxHeight: '250px' }}
							/>
						</div>
					</div>
					<div className='d-flex flex-column'>
						<div className='d-flex mb-md-3 my-1 mt-sm-3'>
							<p
								className='me-auto my-auto fs-4 fw-bolded'
								style={{ color: 'white' }}
							>
								{obj.title}
							</p>
						</div>
						<p
							className='fs-6'
							style={{ color: obj.img === 'step4.png' ? 'white' : '#A4A3A7' }}
						>
							{obj.text}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
