import {useState} from "react"
import {useLocation, useNavigate} from "react-router"
import {ErrorMsg, SOURCE, Validation} from "../../../constants"
import {AppInput} from "../../../components/ui/AppInput"
import { useDispatch, useSelector } from "react-redux"
import { showErrorMsg } from "../../../store/slices/alertSlice"
import {verifyResetCode} from "../../../api/passwordResets"

export const ResetPassword = () => {
    const loc = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const searchParams = new URLSearchParams(loc.search)
    const email = searchParams.get('email')
    const code = searchParams.get('code')
    const [state, setState] = useState({
        pswd1: '',
        pswd2: '',
        passwordVisible: false,
        showAlerts: false,
        sent: false,
        errorMsg: '',
    })

    return (
        <div className="container-fluid d-flex flex-column mx-auto my-5" style={{maxWidth: '500px'}}>

            {state.errorMsg && (
                <ErrorMsg state={state} setState={setState}/>
            )}

            <p className="text-center fs-2 mb-4" style={{fontWeight: '600'}}>Восстановление доступа</p>

            {
                state.sent ? (
                    <>
                        <p className="fs-3 text-center">Пароль успешно изменён</p>

                        <button
                            className="btn light_primary px-4 py-2 mt-2"
                            onClick={() => navigate('/auth')}
                        >
                            Авторизация
                        </button>
                    </>
                ) : (
                    <>
                        <AppInput
                            switchEye={() => setState(p => ({...p, passwordVisible: !p.passwordVisible}))}
                            onChange={e => setState(prev => ({...prev, pswd1: e.target.value.trim()}))}
                            showAlert={!Validation.validPassword(state.pswd1)}
                            type={state.passwordVisible ? 'text' : 'password'}
                            label='Новый пароль'
                            value={state.pswd1}
                            showEye={true}
                        />
                        <AppInput
                            switchEye={() => setState(p => ({...p, passwordVisible: !p.passwordVisible}))}
                            onChange={e => setState(prev => ({...prev, pswd2: e.target.value.trim()}))}
                            showAlert={!Validation.validPassword(state.pswd2)}
                            type={state.passwordVisible ? 'text' : 'password'}
                            label='Подтвердите пароль'
                            value={state.pswd2}
                            showEye={true}
                        />

                        <button
                            className='btn primary py-2 px-4 mt-4'
                            onClick={() => save(state, setState, email, code, dispatch)}
                        >
                            Сохранить
                        </button>
                    </>
                )
            }
        </div>
    )
}

async function save(state, setState, email, code, dispatch) {
    if (!Validation.validPassword(state.pswd1)) {
        return dispatch(showErrorMsg('Пароль должен содержать латинские заглавные и прописные буквы и цифры и быть не короче 6 символов'))
    }

    if (state.pswd1 !== state.pswd2) {
        return dispatch(showErrorMsg('Пароли не совпадают'))
    }

    try {
        const response = await verifyResetCode(code, state.pswd1)

        if (response === null || !response.ok) {
            console.error(`Ошибка запроса: ${response.status} ${response.statusText}`)
            dispatch(showErrorMsg('Произошла ошибка на сервере.'))
        } else {
            const data = await response.json()

            if (data.message === 'Password reset successfully') {
                setState(p => ({ ...p, sent: true }))
            } else if (data.error === 'Invalid code or email') {
                dispatch(showErrorMsg('Код не найден или уже был использован'))
            } else {
                dispatch(showErrorMsg('Произошла ошибка на сервере.'))
            }
        }
    } catch (error) {
        console.error('Произошла ошибка при выполнении запроса:', error)
        dispatch(showErrorMsg('Произошла ошибка при выполнении запроса.'))
    }

}