// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/main/footer.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gray {\n    background-color: var(--gray);\n}\n.orange {\n    background-color: var(--orange);\n}\n.yellow {\n    background-color: var(--yellow);\n}\n.black {\n    background-color: var(--black);\n}\n.purple {\n    background-color: var(--purple);\n}\n.skyblue {\n    background-color: var(--skyblue);\n}\n\n\n/* @media (min-width: 1280px) {\n    .fs-1 {\n        font-size: 3rem !important;\n    }\n} */\n\n.rounded-5 {\n    border-radius: 40px !important;\n}\n\n#background_image {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    background-position: center;\n  \n}", "",{"version":3,"sources":["webpack://./src/screens/main/styles.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA;IACI,+BAA+B;AACnC;AACA;IACI,+BAA+B;AACnC;AACA;IACI,8BAA8B;AAClC;AACA;IACI,+BAA+B;AACnC;AACA;IACI,gCAAgC;AACpC;;;AAGA;;;;GAIG;;AAEH;IACI,8BAA8B;AAClC;;AAEA;IACI,yDAAqD;IACrD,sBAAsB;IACtB,2BAA2B;;AAE/B","sourcesContent":[".gray {\n    background-color: var(--gray);\n}\n.orange {\n    background-color: var(--orange);\n}\n.yellow {\n    background-color: var(--yellow);\n}\n.black {\n    background-color: var(--black);\n}\n.purple {\n    background-color: var(--purple);\n}\n.skyblue {\n    background-color: var(--skyblue);\n}\n\n\n/* @media (min-width: 1280px) {\n    .fs-1 {\n        font-size: 3rem !important;\n    }\n} */\n\n.rounded-5 {\n    border-radius: 40px !important;\n}\n\n#background_image {\n    background-image: url('../../images/main/footer.png');\n    background-size: cover;\n    background-position: center;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
