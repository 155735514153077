import { SOURCE } from '../constants'
export async function getGuestResponsePdf(token, id) {
  const response = await fetch(
    `${SOURCE.new_url}guestVacancyResponses/${id}/resumeFile`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  )
  console.log(response)
  if (!response.ok) {
    return false
  }
  const link = URL.createObjectURL(await response.blob())
  return link
}

export async function getGuestVacancyResponseResumeAndContacts(
  token,
  id,
  queryObj = {}
) {
  let query = '?'
  let include = queryObj.include || []
  include.forEach((el) => {
    if (query !== '?') query += '&'
    query += 'include=' + el
  })
  const response = await fetch(
    `${SOURCE.new_url}guestVacancyResponseResumes/${id}` +
      (query === '?' ? '' : query),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  )

  if (response.status === 404) return 404

  if (!response.ok) {
    return false
  }
  const json = await response.json()
  return json
}

export async function editGuestVacancyResponseResumeAndContacts(
  token,
  id,
  guestResponseResume
) {
  const response = await fetch(
    `${SOURCE.new_url}guestVacancyResponseResumes/${id}`,
    {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(guestResponseResume),
    }
  )

  if (!response.ok) {
    return false
  }
  return true
}

export async function editGuestVacancyResponseResume(
  token,
  id,
  guestResponseResume
) {
  const response = await fetch(`${SOURCE.new_url}guestVacancyResponses/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(guestResponseResume),
  })

  if (!response.ok) {
    return false
  }
  return true
}
