import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { showErrorMsg } from './alertSlice'
import { getMyVacanciesAsync } from './vacanciesSlice'
import { getApplicantById } from '../../api/applicants'
import { getFullResume } from '../../api/resumes'
import {
  getMyGuestVacancyResponses,
  getMyVacancyResponses,
} from '../../api/vacancyResponses'

export const getCandidateInfoAsync = createAsyncThunk(
  'getCandidate',

  async ({ id, queryObj = {} }, { getState, dispatch }) => {
    const state = getState()
    const TOKEN = state.userSlice.user.access_token
    let include = queryObj.include || []
    const candidate = await getApplicantById(TOKEN, id, include)
    const result = {}
    if (candidate) {
      result.candidate = candidate
      const resume = await getFullResume(TOKEN, candidate.resume?.id)
      if (resume) result.resume = resume

      dispatch(
        getMyVacanciesAsync({ include: ['responses.candidate.resume'] })
      )
    } else dispatch(showErrorMsg('Произошла ошибка при загрузке кандидата'))
    return result
  }
)

// export const getMyCandidatesAsync = createAsyncThunk(
// 	'getMyCandidates',

// 	async (queryObj = {}, { getState, dispatch }) => {
// 		const state = getState()
// 		const TOKEN = state.userSlice.user.access_token
// 		let include = queryObj.include || []
// 		let size = queryObj.size || 20
// 		let page = queryObj.page || 0
// 		let nickname = queryObj.nickname || ''
// 		let query = '?'
// 		include.forEach(el => {
// 			if (query !== '?') query += '&'
// 			query += 'include=' + el
// 		});
// 		if (query !== '?') query += '&'
// 		query += 'size=' + size

// 		if (page !== 0) {
// 			query += '&page=' + page
// 		}

// 		if (nickname !== '') {
// 			query += '&nickame=' + nickname
// 		}
// 		const response = await fetch(
// 			`${SOURCE.new_url}vacancyResponses/my${query === '?' ? '' : query}`,
// 			{
// 				method: 'GET',
// 				headers: {
// 					Accept: 'application/json',
// 					'Content-Type': 'application/json',
// 					Authorization: TOKEN,
// 				},
// 			}
// 		)
// 		if (!response.ok) return dispatch(showErrorMsg('Server error'))

// 		const data = await response.json()

// 		return data
// 	}
// )

export const getAllVacancyResponsesAsync = createAsyncThunk(
  'getAll',

  async (queryObj = {}, { getState, dispatch }) => {
    const state = getState()
    const TOKEN = state.userSlice.user.access_token
    let include = queryObj.include || []
    const filters = queryObj.filters || {}
    const activeVacancies = filters.activeVacancies || []
    let size = state.candidateSlice.candidatesData.perPage || 20
    let page = state.candidateSlice.candidatesData.currentPage || 1
    let query = '?'
    query += size ? 'size=' + size : ''
    query += page ? '&page=' + page : ''
    query += filters.city ? '&vacancy_city=' + filters.city : ''
    include.forEach((el) => {
      if (query !== '?') query += '&'
      query += 'include=' + el
    })

    activeVacancies.forEach((v) => {
      if (query !== '?') query += '&'
      query += 'vacancyId=' + v.id
    })
    query += filters.vacancy_maxSalary
      ? '&vacancy_maxSalary=' + filters.vacancy_maxSalary * 1000
      : ''
    query += filters.vacancy_minSalary
      ? '&vacancy_minSalary=' + filters.vacancy_minSalary * 1000
      : ''

    let queryGuest = 'include=vacancy.employer&include=resume'

    const response = await getMyVacancyResponses(TOKEN, query)
    const responseGuests = await getMyGuestVacancyResponses(TOKEN, queryGuest)

    if (!response) return dispatch(showErrorMsg('Server error'))
    if (!responseGuests) return dispatch(showErrorMsg('Server error'))

    const modifiedResponse = {
      ...response,
      items: response.items,
      guestItems: responseGuests.items,
    }

    return {
      candidate: modifiedResponse,
    }
  }
)

const defaultCandidatesFilters = {
  status: [],
  vacancy_city: '',
  sortBy: [],
  activeVacancies: [],
  candidate_minSalary: '',
  candidate_maxSalary: '',
}

const initialState = {
  candidateInfo: {
    candidate: {},
    resume: {},
    loaded: false,
    loading: false,
    offers: [],
  },
  candidatesData: {
    candidates: [],
    cities: [],
    vacancies: [],
    filters: defaultCandidatesFilters,
    currentPage: 1,
    perPage: 0,
    total: 0,
    totalPages: 0,
    loaded: false,
  },
}

export const candidateSlice = createSlice({
  name: 'candidates',
  initialState,

  reducers: {
    resetCandidateInfo: (state, action) => {
      state.candidateInfo = initialState.candidateInfo
    },
    setCities: (state, action) => {
      state.candidatesData.cities = [
        { label: 'Не важно', value: '' },
        ...action.payload.cities.map((c) => ({ label: c, value: c })),
      ]
    },
    setFilters: (state, action) => {
      state.candidatesData = {
        ...state.candidatesData,
        currentPage: 1,
        perPage: 0,
        totalPages: 0,
        total: 0,
        filters: { ...state.candidatesData.filters, ...action.payload },
      }
    },
    resetFilters: (state, action) => {
      state.candidatesData.filters = defaultCandidatesFilters
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCandidateInfoAsync.pending, (state) => {
        state.candidateInfo.loading = true
      })
      .addCase(getCandidateInfoAsync.fulfilled, (state, action) => {
        if (action.payload.candidate)
          state.candidateInfo = {
            ...state.candidateInfo,
            ...action.payload,
            loaded: true,
            loading: false,
          }
        state.candidateInfo.loading = false
        state.candidateInfo.loaded = true
      })
      .addCase(getAllVacancyResponsesAsync.pending, (state) => {
        state.candidatesData.loading = true
      })
      .addCase(getAllVacancyResponsesAsync.fulfilled, (state, action) => {
        const { candidate = {}, guest = {} } = action.payload
        const candidateResponses = candidate.items || []
        const guestResponses = guest.items || []

        const candidates =
          candidate.currentPage === 1
            ? candidateResponses
            : [...state.candidatesData.candidates, ...candidateResponses]

        let allVacancies = candidates.map((c) => c.vacancy)
        let vacancies = []
        allVacancies.forEach((v) => {
          if (vacancies.map((el) => el.name).indexOf(v.name) < 0)
            vacancies.push(v)
        })

        vacancies.forEach((el) => {
          el.guestResponses =
            guestResponses.filter((x) => x.vacancyId === el.id) || []
        })

        state.candidatesData = {
          ...state.candidatesData,
          ...action.payload.candidate,
          vacancies: vacancies,
          candidates: candidates,
          loaded: true,
          loading: false,
        }

        // Object.assign(state.candidatesData, action.payload); TODO pagination
      })
  },
})

export const { setCities, setFilters, resetFilters, resetCandidateInfo } =
  candidateSlice.actions
export default candidateSlice.reducer
