import {useSelector} from "react-redux"
import {Navigate, Route, Routes} from "react-router"
import {AuthLayout} from "./layouts/AuthLayout"
import {useState} from "react"
import {AppInput} from "../../components/ui/AppInput"
import {COLORS, SOURCE} from "../../constants"
import {ForgotPassword} from "./layouts/ForgotPassword"
import {ResetPassword} from "./layouts/ResetPassword"
import {NotFoundPage} from "../system/NotFoundPage"
import { EnterWithMail, ApplyEnterMail } from './layouts/EnterWithMail'

export const AuthScreen = () => {
    const access_token = useSelector(state => state.userSlice.user.access_token)
    const [isAdmin, setIsAdmin] = useState(false)

    return (
        access_token !== undefined ? (
            <Navigate to='/'/>
        ) : (
            <div className="pt-md-4 pb-5">

                {
                    !isAdmin ? (
                        <Routes>
                            <Route path='/' element={<AuthLayout/>}/>
                            <Route path='enter_mail' element={<EnterWithMail/>}/>
                            <Route path='byEmail' element={<ApplyEnterMail/>}/>

                            <Route path='forgot_password' element={<ForgotPassword/>}/>
                            <Route path='reset_password/*' element={<ResetPassword/>}/>
                            <Route path='*' element={<NotFoundPage/>}/>
                        </Routes>
                    ) : (
                        <div
                            className='row gap-5 justify-content-between w-100 my-5 mx-auto'
                            style={{maxWidth: '1400px'}}
                        >
                            <div className='col-md-6 col-lg-4 col-12 d-flex align-items-center'>

                                <img
                                    className='m-auto'
                                    style={{height: 'auto', width: '100%', maxWidth: '450px'}}
                                    src={SOURCE.source_url + 'errorcat.png'}
                                    alt=''
                                />

                            </div>

                            <div className='col d-flex flex-column justify-content-center'>

                                <p
                                    className='fs-1 mb-4'
                                    style={{color: COLORS.primary, fontWeight: '600'}}
                                >

                                <span style={{color: COLORS.text_primary}}>
                                    Доступ
                                </span>

                                    ограничен!

                                </p>

                                <p
                                    className='fs-4 mb-4'
                                    style={{color: COLORS.text_primary}}
                                >
                                    Доступ к данной странице
                                    временно ограничен администрацией. Для продолжения работы потребуется ввести
                                    пароль.
                                </p>

                                <AppInput
                                    label='Пароль'
                                    type='password'
                                    onChange={(e) => setIsAdmin(e.target.value === 'qwerty')}
                                />

                            </div>
                        </div>
                    )
                }
            </div>
        )
    )
}