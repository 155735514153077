import { COLORS } from "../../constants"

export const PrivacyPolicy = () => {
    document.title = 'Хартл | Документы'
    return <div className="container mt-3 mb-5" style={{ color: COLORS.text_primary }}>
        <p className="fs-2 mb-3" style={{ fontWeight: '600' }}>Политика обработки персональных данных</p>
        <p className='fs-5' style={{ fontWeight: '600' }}>1. Общие положения</p>
        <div className="mb-3">
            <p>1.1. Настоящим положением устанавливается порядок обработки персональных данных пользователей сайта <a target="_blank" rel="noopener noreferrer" href='https://hurtle.ru/' className="text-decoration-underline" style={{ color: COLORS.primary, cursor: 'pointer' }}>https://hurtle.ru/</a>, 
                принадлежащего ООО «Хартл» (ИНН 5044138439, ОГРН 1235000023191) (далее – Оператор), и обеспечивается соблюдение требований защиты прав граждан при обработке персональных данных.</p>
            <p>1.2. Политика разработана в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 (далее — ФЗ «О персональных данных»).</p>
        </div>
        <p className='fs-5' style={{ fontWeight: '600' }}>2. Основные понятия, используемые в Политике</p>
        <div className="mb-3">
            <p>2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.</p>
            <p>2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
            <p>2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a target="_blank" rel="noopener noreferrer" href='https://hurtle.ru/' className="text-decoration-underline" style={{ color: COLORS.primary, cursor: 'pointer' }}>https://hurtle.ru/</a>.</p>
            <p>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.</p>
            <p>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.</p>
            <p>2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
            <p>2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
            <p>2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <a target="_blank" rel="noopener noreferrer" href='https://hurtle.ru/'  className="text-decoration-underline" style={{ color: COLORS.primary, cursor: 'pointer' }}>https://hurtle.ru/</a>.</p>
            <p>2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, – персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Федеральным законом от 27.07.2006 №152-ФЗ «О персональных данных» (далее соответственно – персональные данные, разрешенные для распространения, №152-ФЗ).</p>
            <p>2.10. Пользователь – любой посетитель веб-сайта <a target="_blank" rel="noopener noreferrer" href='https://hurtle.ru/'  className="text-decoration-underline" style={{ color: COLORS.primary, cursor: 'pointer' }}>https://hurtle.ru/</a>, субъект обработки персональных данных.</p>
            <p>2.11. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
            <p>2.12. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.</p>
            <p>2.13. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.</p>
            <p>2.14. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.</p>
        </div>
        <p className='fs-5' style={{ fontWeight: '600' }}>3. Цели обработки персональных данныхе</p>
        <div className="mb-3">
            <p>3.1. Цель обработки персональных данных Пользователя – предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте <a target="_blank" rel="noopener noreferrer" href='https://hurtle.ru/'  className="text-decoration-underline" style={{ color: COLORS.primary, cursor: 'pointer' }}>https://hurtle.ru/</a>.</p>
            <p>3.2. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>
        </div>
        <p className='fs-5' style={{ fontWeight: '600' }}>4. Порядок предоставления Пользователем персональных данных</p>
        <div className="mb-3">
                <p>4.1. Заполняя соответствующие формы на сайте <a target="_blank" rel="noopener noreferrer" href='https://hurtle.ru/'  className="text-decoration-underline" style={{ color: COLORS.primary, cursor: 'pointer' }}>https://hurtle.ru/</a>, а также предоставляя данные во время прохождения видео-собеседований на сайте, Пользователь выражает свое согласие с Политикой.</p>
                <p>4.2. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения через формы на сайте <a target="_blank" rel="noopener noreferrer" href='https://hurtle.ru/'  className="text-decoration-underline" style={{ color: COLORS.primary, cursor: 'pointer' }}>https://hurtle.ru/</a> и предоставления во время прохождения видео-собеседований на сайте.</p>
                <p>4.3. Пользователь самостоятельно принимает решение о предоставлении его персональных данных и дает согласие свободно, своей волей и в своем интересе.</p>
        </div>
        <p className='fs-5' style={{ fontWeight: '600' }}>5. Порядок обработки Оператором персональных данных</p>
        <div className="mb-3">
            <p>5.1. Компания осуществляет сбор информации через интернет-сайты и мобильные приложения следующими способами:</p>
            <div className="ps-1">
                <ul>
                    <li>
                        Персональные данные, предоставляемые пользователями: Компания осуществляет сбор персональных данных, которые вводят в поля данных на интернет-сайтах Компании сами пользователи или иные лица по их поручению.
                    </li>
                    <li>
                        Сбор данных о местонахождении устройств пользователей мобильного приложения Компании при использовании пользователями мобильного приложения.
                    </li>
                    <li>Сбор IP адресов пользователей и файлов cookies.</li>
                    <li>
                        Пассивный сбор персональных данных о текущем подключении в части статистических сведений:
                    </li>
                    <div>
                        <p>— идентификатор пользователя, присваиваемый Сайтом;</p>
                        <p>— посещенные страницы;</p>
                        <p>— количество посещений страниц;</p>
                        <p>— информация о перемещении по страницам сайта;</p>
                        <p>— длительность пользовательской сессии;</p>
                        <p>— точки входа (сторонние сайты, с которых пользователь по ссылке переходит на Сайт);</p>
                        <p>— точки выхода (ссылки на Сайте, по которым пользователь переходит на сторонние сайты);</p>
                        <p>— страна пользователя;</p>
                        <p>— регион пользователя;</p>
                        <p>— часовой пояс, установленный на устройстве пользователя;</p>
                        <p>— провайдер пользователя;</p>
                        <p>— браузер пользователя;</p>
                        <p>— цифровой отпечаток браузера (canvas fingerprint);</p>
                        <p>— доступные шрифты браузера;</p>
                        <p>— установленные плагины браузера;</p>
                        <p>— параметры WebGL браузера;</p>
                        <p>— тип доступных медиа-устройств в браузере;</p>
                        <p>— наличие ActiveX;</p>
                        <p>— перечень поддерживаемых языков на устройстве пользователя;</p>
                        <p>— архитектура процессора устройства пользователя;</p>
                        <p>— ОС пользователя;</p>
                        <p>— параметры экрана (разрешение, глубина цветности, параметры размещения страницы на экране);</p>
                        <p>— информация об использовании средств автоматизации при доступе на Сайт;</p>
                        <p>— дата и время посещения сайта;</p>
                        <p>— источник перехода (UTM метка);</p>
                        <p>— значение UTM меток от source до content;</p>
                        <p>— уникальный идентификатор, присваиваемый интернет-сторонним сервисом, обеспечивающим обработку статистических данных.</p>
                    </div>
                </ul>
            </div>
            <p>5.2. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
            <p>5.3. Обработке подлежат только персональные данные, которые отвечают целям их обработки. Содержание и объем обрабатываемых Оператором персональных данных соответствуют заявленным целям обработки, избыточность обрабатываемых персональных данных не допускается.</p>
            <p>5.4. При обработке персональных данных в Компании обеспечивается точность персональных данных, их достаточность и, в необходимых случаях, актуальность по отношению к целям обработки персональных данных. Компания принимает необходимые меры (обеспечивает их принятие) по удалению или уточнению неполных, или неточных персональных данных.</p>
            <p>5.5. Компания в ходе своей деятельности может предоставлять и (или) поручать обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено законодательством РФ о персональных данных. При этом обязательным условием предоставления и (или) поручения обработки персональных данных другому лицу является обязанность сторон по соблюдению конфиденциальности и обеспечению безопасности персональных данных при их обработке.</p>
            <p>5.6. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни, Оператором не осуществляется.</p>
            <p>5.7. Обработка персональных данных, разрешенных для распространения, из числа специальных категорий персональных данных, указанных в ч. 1 ст. 10 №152-ФЗ, допускается, если соблюдаются запреты и условия, предусмотренные ст. 10.1 №152-ФЗ.</p>
            <p>5.8. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством.</p>
            <p>5.9. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <span style={{ color: COLORS.primary, cursor: 'pointer' }}>hello@hurtle.ru</span> с пометкой «Отзыв согласия на обработку персональных данных».</p>
            <p>5.10. Согласие на обработку персональных данных, разрешенных для распространения, прекращает свое действие с момента поступления Оператору требования, указанного в п. 5.10 Политики.</p>
            <p>5.11. Оператор осуществляет хранение персональных данных в форме, позволяющей определить Пользователя, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем, по которому является Пользователь.</p>
            <p>5.12. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия Пользователя или отзыв согласия Пользователем, а также выявление неправомерной обработки персональных данных.</p>
        </div>
        <p className='fs-5' style={{ fontWeight: '600' }}>6. Действия, осуществляемые Оператором в отношении персональных данных</p>
        <div className="mb-3">
            <p>6.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.</p>
            <p>6.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.</p>
        </div>
        <p className='fs-5' style={{ fontWeight: '600' }}>7. Конфиденциальность персональных данных</p>
        <div className="mb-3">
            <p>7.1. Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено федеральным законом.</p>
        </div>
        <p className='fs-5' style={{ fontWeight: '600' }}>8. Иные положения</p>
        <div className="mb-3">
            <p>8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <span style={{ color: COLORS.primary, cursor: 'pointer' }}>hello@hurtle.ru</span></p>
            <p>8.2. В данном документе будут отражены любые изменения Политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
            <p>8.3. Действующая редакция Политики расположена в сети «Интернет» в свободном доступе по адресу <a target="_blank" rel="noopener noreferrer" href='https://hurtle.ru/policy'  className="text-decoration-underline" style={{ color: COLORS.primary, cursor: 'pointer' }}>https://hurtle.ru/policy</a>.</p>
        </div>     
    </div>
}