import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ReactInputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  COLORS,
  SOURCE,
  formatSalary,
  labelsBySalaryCurrency,
  formatDateExperience,
} from '../constants'
import { setFilters } from '../store/slices/candidateSlice'
import { getAvailableMeetingsAsync } from '../store/slices/meetingsSlice'
import { Loader } from './Loader'
import { MonthCalendar, SlotTime } from './MonthCalendar'
import { AppCheckbox } from './ui/AppCheckbox/AppCheckbox'
import { AppInput } from './ui/AppInput'
import { AppSelect } from './ui/AppSelect'
import { AppTextarea } from './ui/AppTextarea'
import { InputAutosize } from './ui/InputAutosize'
import { SelectVacancies } from './ui/SelectVacancies'

import { ReactComponent as CoinsSvg } from '../icons/coins.svg'
import { ReactComponent as LocationSvg } from '../icons/location.svg'
import classes from './Offer.module.scss'
import { DefaultAvatar } from './ui/DefaultAvatar'
import VideoPlayer from './ui/VideoPlayer'
import { ContactMenuSelect } from '../screens/candidates/candidate/resume/ContactMenuSelect'
import { sendVerificationCode, verifyEmail } from '../api/emailVerifications'
import { getApplicantById } from '../api/applicants'
import { createOffer } from '../api/offers'
import { createMeeting } from '../api/meetings'
import { updateUser } from '../store/slices/userSlice'

const url = SOURCE.url

export const LogoutModal = ({ shown, setState }) => {
  const navigate = useNavigate()

  return (
    <Modal
      onHide={() => setState((p) => ({ ...p, logoutModalShown: false }))}
      dialogClassName='rounded-4'
      show={shown}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: '600' }}>Подтверждение</Modal.Title>
      </Modal.Header>

      <Modal.Body>Вы точно хотите выйти?</Modal.Body>

      <Modal.Footer>
        <button
          onClick={() => setState((p) => ({ ...p, logoutModalShown: false }))}
          className='btn primary'
        >
          Нет, остаться!
        </button>

        <button
          className='btn btn-md light_primary text-nowrap w-100'
          style={{ maxWidth: '100px' }}
          onClick={() => {
            setState((p) => ({ ...p, logoutModalShown: false }))
            navigate('/logout')
          }}
        >
          Выйти
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const VideoModal = ({ state, setState }) => {
  return (
    <Modal
      onHide={() => setState((p) => ({ ...p, videoModalShown: false }))}
      dialogClassName='rounded-4'
      size='lg'
      show={state.videoModalShown}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: '600' }}>
          Запись собеседования
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <VideoPlayer url={state.videoUrl || ''} />
      </Modal.Body>

      <Modal.Footer>
        <button
          onClick={() => setState((p) => ({ ...p, videoModalShown: false }))}
          className='btn primary'
        >
          Закрыть
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const ConfirmMailModal = ({ state, setState, access_token }) => {
  const [localState, setLocalState] = useState({
    code: '',
    confirmation_id: null,
    disabled: false,
    successMsg: '',
    errorMsg: '',
    sent: false,
  })
  const user = useSelector((state) => state.userSlice.user)

  useEffect(() => {
    console.log('user', user)
  }, [user])

  const dispatch = useDispatch()

  // useEffect(() => {
  // 	(async () => {
  // 		const res = await sendVerificationCode(access_token);
  // 		if (res === null || !res.ok) setState(prev => ({ ...prev, errorMsg: 'Ошибка!' }))
  // 		else setState(prev => ({ ...prev, disabled: true, successMsg: 'Код отправлен!' }))
  // 	})();
  // }, [])

  const verifyEmailHandler = async () => {
    if (localState.code.indexOf('x') < 0) {
      const response = await verifyEmail(
        access_token,
        localState.code.replace('-', '')
      )

      if (response === null || !response.ok) {
        setState((prev) => ({ ...prev, errorMsg: 'Неверный код' }))
      } else {
        setState((prev) => ({ ...prev, confirmMailModalShown: false }))
        dispatch(updateUser({ isEmailConfirmed: true }))
      }
    }
  }

  return (
    <Modal
      dialogClassName='rounded-4'
      size
      centered
      show={state.confirmMailModalShown}
      onHide={() => setState((p) => ({ ...p, confirmMailModalShown: false }))}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: '600' }}>
          Подтверждение почты
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className='text-center mb-5 fs-5' style={{ fontWeight: '300' }}>
          Мы отправили на вашу почту код подтверждения, введите его ниже
        </p>

        <ReactInputMask
          maskChar='x'
          mask='***-***'
          pattern='[A-Za-z0-9]{3}-[A-Za-z0-9]{3}'
          style={{ maxWidth: '300px', fontSize: '22px' }}
          className='form-control form-control-md p-3 text-center mb-5 mx-auto'
          placeholder='xxx-xxx'
          inputmode='numeric'
          value={localState.code}
          onChange={(e) =>
            setLocalState((p) => ({ ...p, code: e.target.value.trim() }))
          }
        />
      </Modal.Body>

      <Modal.Footer>
        <button
          onClick={async () => {
            const res = await sendVerificationCode(access_token)
            if (res === null || !res.ok) {
              setState((prev) => ({ ...prev, errorMsg: 'Ошибка!' }))
            } else {
              setLocalState((prev) => ({
                ...prev,
                disabled: true,
                successMsg: 'Код отправлен!',
              }))
            }
          }}
          style={{
            backgroundColor: COLORS.secondary,
            color: COLORS.primary,
            borderWidth: 0,
            maxWidth: '250px',
          }}
          className='btn btn-md py-2 mb-3 w-100 text-nowrap mx-auto'
          disabled={localState.disabled}
          title={
            localState.disabled
              ? 'Следующий код можно запросить через 30 секунд'
              : ''
          }
        >
          Отправить код
        </button>

        <button
          style={{
            maxWidth: '250px',
            backgroundColor: COLORS.primary,
            color: '#fff',
          }}
          className='btn btn-md py-2 mb-3 w-100 primary text-nowrap mx-auto'
          onClick={verifyEmailHandler}
          disabled={localState.code.length < 7}
        >
          Подтвердить
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const ResponseModal = ({ responseModal, setState }) => {
  const access_token = useSelector(
    (state) => state.userSlice.user.access_token
  )
  const myId = useSelector((state) => state.userSlice.user.id)
  const { id } = useParams()

  const [assignedVacancies, setAssignedVacancies] = useState([])
  const vacanciesToOffer = assignedVacancies.filter(
    (vacancy) => vacancy.employerId === myId
  )

  const [chosenVacancy, setChosenVacancy] = useState(null)

  useEffect(() => {
    if (assignedVacancies.length > 0) {
      setChosenVacancy(assignedVacancies[0].id)
    }
  }, [assignedVacancies])

  useEffect(() => {
    const fetchData = async () => {
      const data = await getApplicantById(access_token, id, [
        'assignedVacancies',
      ])
      if (!data) return

      setAssignedVacancies(data.assignedVacancies)

      setState((prevState) => ({
        ...prevState,
        responseModal: {
          ...prevState.responseModal,
          loaded: true,
          vacancies: data.assignedVacancies,
        },
      }))
    }

    fetchData()
  }, [])

  const selectVacancyToOffer = (e) => {
    setChosenVacancy(e.target.value)
  }

  const sendOfferHandler = async () => {
    const response = await createOffer(access_token, {
      salary: responseModal.salary,
      salaryCurrency: responseModal.currency,
      message: responseModal.feedback,
      vacancyId: chosenVacancy,
      candidateId: id,
    })
  }

  return (
    <Modal
      dialogClassName='rounded-4'
      centered
      size='lg'
      show={responseModal.shown}
      onHide={() =>
        setState((p) => ({
          ...p,
          responseModal: { ...p.responseModal, shown: false },
        }))
      }
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: '600' }}>Подтверждение</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='d-flex flex-column '>
          {responseModal.loaded && responseModal.is_sent && (
            <p className='fs-5' style={{ color: COLORS.primary }}>
              Вы уже отпраили отклик данному кандидату
            </p>
          )}

          {!responseModal.loaded ? (
            <Loader size='small' />
          ) : (
            <SelectVacancies
              vacancies={vacanciesToOffer}
              label='Доступные вакансии'
              value={chosenVacancy}
              onChange={selectVacancyToOffer}
            />
          )}

          <div className='mb-3'>
            <div className='input-group flex-nowrap'>
              <div className='w-100'>
                <label className='ms-3 position-absolute'>
                  <span className='h6 small bg-white text-muted px-1'>
                    Зарплата
                  </span>
                </label>

                <input
                  className='form-control p-3 rounded-0 rounded-start'
                  style={{ marginTop: '12px' }}
                  value={formatSalary(responseModal.salary)}
                  onChange={(e) =>
                    setState((p) => ({
                      ...p,
                      responseModal: {
                        ...p.responseModal,
                        salary: e.target.value.replace(/\s/g, ''),
                      },
                    }))
                  }
                />
              </div>

              <select
                className='form-select'
                style={{ marginTop: '12px', minWidth: '120px' }}
                value={responseModal.currency}
                onChange={(e) =>
                  setState((p) => ({
                    ...p,
                    responseModal: {
                      ...p.responseModal,
                      currency: e.target.value,
                    },
                  }))
                }
              >
                {Object.keys(labelsBySalaryCurrency).map((option) => (
                  <option key={option} value={option}>
                    {labelsBySalaryCurrency[option]}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label className='ms-3 position-absolute'>
              <span className='h6 small bg-white text-muted px-1'>Отзыв</span>
            </label>

            <textarea
              style={{
                marginTop: '12px',
                height: '250px',
                minHeight: '400px !important',
              }}
              value={responseModal.feedback}
              className='form-control d-flex px-3 py-2 text-wrap'
              onChange={(e) =>
                setState((p) => ({
                  ...p,
                  responseModal: {
                    ...p.responseModal,
                    feedback: e.target.value,
                  },
                }))
              }
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          className='btn primary rounded-3 px-3 py-2'
          disabled={
            responseModal.feedback === '' ||
            chosenVacancy === '' ||
            (responseModal.loaded && responseModal.is_sent)
          }
          onClick={sendOfferHandler}
        >
          Отправить
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const BookMeetingModal = ({ shown, set, type }) => {
  const dispatch = useDispatch()
  const { available, isLoading, selectedDay, selectedSlot } = useSelector(
    (state) => state.meetings
  )
  const access_token = useSelector(
    (state) => state.userSlice.user.access_token
  )
  const user = useSelector((state) => state.userSlice.user)

  const getId = available[selectedDay]?.find(
    (slot) => slot.id === selectedSlot
  )

  const setInterviewTime = async () => {
    try {
      const response = await createMeeting(access_token, {
        type: 'INTERVIEW',
        name: user.name ? user.name : 'ИП',
        description: 'some text here',
        slotId: getId.id,
      })

      if (response === null || !response.ok) {
        throw new Error(`HTTP error! Status: ${(response || {}).status}`)
      }

      const data = await response.json()

      set((p) => ({
        ...p,
        bookMeetingModalShown: false,
      }))

      console.log(data)
    } catch (error) {
      set((p) => ({
        ...p,
        errorMsg: 'Произошла ошибка',
      }))

      console.error('An error:', error.message)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAvailableMeetingsAsync())
    }
    fetchData()
  }, [])

  return (
    <Modal
      onHide={() => set((p) => ({ ...p, bookMeetingModalShown: false }))}
      dialogClassName='rounded-4'
      show={shown}
      size='xl'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: '600' }}>Выбор времени</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            className='row justify-content-center p-2'
            style={{ minHeight: '450px' }}
          >
            <div className='col-md-6 d-flex flex-column mb-4'>
              <MonthCalendar />
            </div>

            <div className='col d-flex flex-column justify-content-between mb-4 px-lg-5 px-2'>
              <div className='d-flex flex-column'>
                <p className='p-0 mb-3' style={{ fontSize: '22px' }}>
                  Доступное время
                </p>

                <div
                  style={{ overflowY: 'scroll', scrollbarWidth: 'none' }}
                  className='row gap-3 mx-1'
                >
                  {available[selectedDay]?.map((slot) => (
                    <SlotTime key={slot.id} slot={slot} />
                  )) || (
                    <div
                      className='col rounded-3 p-4'
                      style={{ border: '1px solid ' + COLORS.primary }}
                    >
                      <p
                        className='fs-5 text-center'
                        style={{ color: COLORS.primary }}
                      >
                        Пока нет свободных слотов ;(
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <button
          onClick={setInterviewTime}
          disabled={!selectedSlot}
          style={{ backgroundColor: COLORS.light_primary }}
          className='btn btn-md primary py-2 mb-2 px-5'
        >
          Записаться
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const MeetingInfoModal = ({ state, setState }) => {
  return (
    <Modal
      centered
      contentClassName='border-0 rounded-4 px-2 m-3'
      size='lg'
      show={state.infoModalShown}
      onHide={() => setState((p) => ({ ...p, infoModalShown: false }))}
    >
      <Modal.Header closeButton style={{ borderBottom: '0 none' }}>
        <Modal.Title className='fs-4' style={{ fontWeight: '600' }}>
          {state.meeting.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-row justify-content-between mb-2'>
          <p className='fs-5'>Дата и время</p>
          <div
            className='rounded-3 py-1 px-3'
            style={{
              color: COLORS.primary,
              border: '1px solid ' + COLORS.primary,
            }}
          >
            {state.meeting.time}
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between'>
          <p className='fs-5'>Менеджер</p>
          <div
            className='rounded-3 py-1 px-3 fs-6'
            style={{
              color: COLORS.primary,
              border: '1px solid ' + COLORS.primary,
            }}
          >
            {state.meeting.manager_id}
          </div>
        </div>
        <hr className='my-4' />
        <p className='fs-4 mb-3' style={{ fontWeight: '600' }}>
          Что будет на встрече
        </p>
        <p
          className='fs-6 text-wrap text-break'
          style={{ whiteSpace: 'pre-line' }}
        >
          {state.meeting.description}
        </p>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: '0 none' }}>
        <div
          onClick={() => window.open(state.meeting.link)}
          className='py-2 px-5 rounded-3 justify-content-center success'
          style={{
            backgroundColor: COLORS.success,
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          <img
            alt=''
            className='me-3'
            src={require('../icons/sberjazz.svg').default}
          />
          Подключиться
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export const FilterCandidatesModal = ({ state, setState }) => {
  const dispatch = useDispatch()
  const onHide = () => {
    setState((p) => ({ ...p, filterModalShown: false }))
  }
  const customAcceptAction = () => {
    // acceptAction()
    onHide()
  }
  const changeFilter = (payload) => {
    dispatch(setFilters(payload))
  }
  const candidatesData = useSelector(
    (state) => state.candidateSlice.candidatesData
  )
  const filters = candidatesData.filters
  return (
    <Modal
      centered
      contentClassName='border-0 rounded-4 px-2 m-3 w-700'
      size='lg'
      show={state.filterModalShown}
      onHide={onHide}
    >
      <Modal.Header closeButton style={{ borderBottom: '0 none' }}>
        <Modal.Title className='fs-4' style={{ fontWeight: '600' }}>
          Фильтр
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column justify-content-between mb-5'>
          <p className='fs-4 mb-3' style={{ fontWeight: '600' }}>
            Вакансия
          </p>
          <div className='row row-cols-md-2 gy-3'>
            {candidatesData.vacancies.map((el, i) => {
              const active =
                filters.activeVacancies.filter((x) => x.name === el.name)
                  .length === 1
              return (
                <div className='col-md d-flex align-items-center fs-6' key={i}>
                  <AppCheckbox
                    className='me-3'
                    value={active}
                    onChange={(change) =>
                      changeFilter({
                        activeVacancies: active
                          ? filters.activeVacancies.filter(
                              (v) => v.name !== el.name
                            )
                          : [...filters.activeVacancies, el],
                      })
                    }
                  />
                  <p>{el.name} </p>
                  <small className='text-muted ms-1 fs-6 my-auto '>
                    (
                    {
                      candidatesData.candidates.filter(
                        (c) => c.vacancy.name === el.name
                      ).length
                    }
                    )
                  </small>
                </div>
              )
            })}
          </div>
        </div>
        <p className='mb-3 h6' style={{ fontWeight: '600' }}>
          Сортировка
        </p>
        <div className='row gy-3'>
          <div className='col-md'>
            <div className='d-flex align-items-center mb-2'>
              <AppCheckbox
                value={filters.sortBy.indexOf('createdAt_asc') >= 0}
                className='me-2'
                onChange={(change) => {
                  const active = filters.sortBy.indexOf('createdAt_asc') >= 0
                  changeFilter({
                    sortBy: active
                      ? filters.sortBy.filter((el) => el !== 'createdAt_asc')
                      : [...filters.sortBy, 'createdAt_asc'],
                  })
                }}
              />{' '}
              По дате отклика
            </div>
            <div className='d-flex align-items-center'>
              <AppCheckbox
                value={filters.sortBy.indexOf('isViewedByEmployer_asc') >= 0}
                className='me-2'
                onChange={(change) => {
                  const active =
                    filters.sortBy.indexOf('isViewedByEmployer_asc') >= 0
                  changeFilter({
                    sortBy: active
                      ? filters.sortBy.filter(
                          (el) => el !== 'isViewedByEmployer_asc'
                        )
                      : [...filters.sortBy, 'isViewedByEmployer_asc'],
                  })
                }}
              />{' '}
              Не просмотренные
            </div>
          </div>
          <div className='col'>
            По зарплате
            <div className='row mt-2 '>
              <div className='col d-flex'>
                от
                <InputAutosize
                  style={{
                    height: '24px',
                    padding: '.375rem .375rem',
                  }}
                  defaultWidth='4.5ch'
                  minWidth='4.5ch'
                  className='form-control mx-2 text-center'
                  value={filters.candidate}
                  onChange={(change) =>
                    changeFilter({
                      candidate: change.target.value || '',
                    })
                  }
                />
                тыс.
              </div>
              <div className='col d-flex'>
                до
                <InputAutosize
                  style={{
                    height: '24px',
                    padding: '.375rem .375rem',
                  }}
                  defaultWidth='4.5ch'
                  minWidth='4.5ch'
                  className='form-control mx-2 text-center'
                  value={filters.candidate}
                  onChange={(change) =>
                    changeFilter({
                      candidate: change.target.value || '',
                    })
                  }
                />
                тыс.
              </div>
            </div>
          </div>
        </div>
        <div className='mt-3 mb-2 fs-6'>По городу</div>
        <AppSelect
          padding='p-sm-2 py-1'
          options={candidatesData.cities}
          value={filters.city}
          onChange={(change) => changeFilter({ city: change.target.value })}
        />
      </Modal.Body>
      <Modal.Footer style={{ borderTop: 'None' }}>
        <button
          className='btn primary py-2 px-5 rounded-3 justify-content-center me-2 text-nowrap'
          onClick={customAcceptAction}
        >
          Применить фильтр
        </button>
        <button
          className='btn py-2 px-5 rounded-3 justify-content-center text-nowrap'
          style={{
            border: '1px solid #849095',
            color: '#849095',
          }}
          onClick={onHide}
        >
          Отменить
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const OfferModal = ({
  candidate,
  resume,
  state,
  setState,
  acceptOfferAction,
}) => {
  return (
    <Modal
      centered
      contentClassName='border-0 rounded-4 px-2 m-3 w-700'
      size='lg'
      show={state.show}
      onHide={() => setState((p) => ({ ...p, show: false }))}
    >
      <Modal.Header closeButton style={{ borderBottom: '0 none' }}>
        <Modal.Title
          className='fs-4'
          style={{ fontWeight: '600' }}
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0, marginTop: '-1rem' }}>
        <div className='container'>
          <div className='fs-3'>Оффер</div>
          <div className='text-muted fs-6 mb-4'>Кандидат</div>
          <div className='d-flex mb-5 gap-5'>
            <div className='col-auto'>
              <DefaultAvatar
                className={classes.ResumeMainAvatar + ' img-fluid rounded'}
                style={{ objectFit: 'cover' }}
                src={SOURCE.new_url + `applicants/${candidate.id}/avatar`}
                alt='user'
              />
            </div>
            <div className='col d-flex'>
              <div className={classes.User + ' my-auto'}>
                <div className={classes.UserName} style={{ marginBottom: 0 }}>
                  {`${candidate?.firstName} ${candidate?.lastName}`}
                </div>
                <div className='mb-2'>{resume?.title}</div>
                <div className='row gy-2 ' style={{ color: COLORS.gray_text }}>
                  <div className='col-sm'>
                    <LocationSvg height='25px' width='25px' className='me-2' />
                    {resume?.city || 'Город не указан'}
                  </div>
                  <div className='col text-nowrap'>
                    <CoinsSvg height='25px' width='25px' className='me-2' />
                    {!resume?.desiredSalary
                      ? 'з/п не указана'
                      : `${resume?.desiredSalary.toLocaleString()} ${
                          labelsBySalaryCurrency[resume?.desiredSalaryCurrency]
                        }`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='my-4'>
            <div>Зарплата</div>
            <div className=''>
              <AppInput
                onInput={(e) => {
                  let currentValue = e.target.value
                    ? e.target.value.replace(/\D/g, '')
                    : '0'
                  if (e.nativeEvent.inputType === 'deleteContentBackward') {
                    currentValue = currentValue.slice(0, -1)
                  }
                  setState((p) => ({ ...p, salary: +currentValue }))
                }}
                onChange={() => {}}
                value={
                  (state.salary ? state.salary.toLocaleString() : `__`) +
                  ' руб. / мес.'
                }
                inputClassName='mt-1'
              />
            </div>
          </div>
          <div>
            <p className='mb-1'>Сопроводительное письмо</p>
            <div>
              <AppTextarea
                value={state.message}
                onChange={(e) => {
                  setState((p) => ({ ...p, message: e.target.value }))
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='mt-3' style={{ borderTop: 'None' }}>
        <button
          className='btn primary py-2 px-5 rounded-3 justify-content-center me-2 text-nowrap'
          onClick={acceptOfferAction}
        >
          Отправить
        </button>
        <button
          className='btn py-2 px-5 rounded-3 justify-content-center text-nowrap'
          style={{
            border: '1px solid #849095',
            color: '#849095',
          }}
          onClick={() => setState((p) => ({ ...p, show: false }))}
        >
          Отменить
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const GuestResponseInfoModal = ({ guestResponse, shown, set }) => {
  guestResponse = guestResponse.guestResumeAndContacts
  return (
    <Modal
      centered
      contentClassName='border-0 rounded-4 px-2 m-3 w-700'
      size='xl'
      show={shown}
      onHide={() => set((p) => ({ ...p, infoModalShown: false }))}
    >
      <Modal.Header closeButton className='border-0'>
        <Modal.Title className='fs-4 bottom-0' style={{ fontWeight: '600' }}>
          Кандидат
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row gy-3 mb-md-5 mb-4'>
          <div className='col d-flex'>
            <div className='d-flex flex-column my-auto'>
              <div className={'fw-bolded fs-3 mb-1'}>
                {`${guestResponse.firstName} ${guestResponse.lastName}`}
              </div>
              <div
                className='fs-5 fw-bolded mb-1 mb-sm-2'
                style={{ color: COLORS.gray_text }}
              >
                {guestResponse.title}
              </div>
              <div
                className='row gy-1 gy-sm-2 '
                style={{ color: COLORS.gray_text }}
              >
                <div className='col text-nowrap'>
                  <CoinsSvg height='25px' width='25px' className='me-2' />
                  {!guestResponse.desiredSalary
                    ? 'з/п не указана'
                    : `${guestResponse.desiredSalary.toLocaleString()} ${
                        labelsBySalaryCurrency[
                          guestResponse.desiredSalaryCurrency
                        ]
                      }`}
                </div>
              </div>
            </div>
          </div>
          <div className='col-auto'>
            <ContactMenuSelect contacts={guestResponse.contacts || []} />
          </div>
        </div>
        {guestResponse.skills?.length > 0 && (
          <>
            <h2 className='fs-4 mb-3 mt-sm-5 mt-4'>Навыки</h2>
            <div className='row g-2 g-sm-3 '>
              {guestResponse.skills?.length ? (
                guestResponse.skills.map((el) => (
                  <div className='col-auto' key={el}>
                    <p
                      className='px-3 py-sm-2 py-1 fs-6 rounded'
                      style={{ backgroundColor: COLORS.light_neutral }}
                    >
                      {el}
                    </p>
                  </div>
                ))
              ) : (
                <div></div>
              )}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
