import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { COLORS, ErrorMsg, SOURCE } from "../../constants"
import { Loader } from "../../components/Loader"

// Deprecated, I think

export const InterviewResults = () => {
    const user = useSelector(state => state.userSlice.user)
    const [state, setState] = useState({
        current_script_index: 0,
        scripts: [],
        candidate: {},
        loaded: false,
    })
    const { id } = useParams()
    useEffect(() => {
        async function loadInfo() {
            try {
                const res = await Promise.all([
                    fetch(SOURCE.url + '/api/v1/accounts/by_id/' + id,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': user.access_token
                        }
                    }),
                    fetch(SOURCE.url+'/api/v1/scripts/by_account_id/' + id,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': user.access_token
                        }
                    })
                ])
                const [{ account, detail }, scripts] = await Promise.all(res.map(item => item.json()))
                if (!detail && !scripts.detail)
                    setState(p => ({ ...p, loaded: true, candidate: account, scripts: scripts }))
                else setState(p => ({ ...p, errorMsg: 'Произошла ошибка на сервере' }))
            }
            catch{
                setState(p => ({ ...p, errorMsg: 'Произошла ошибка при загрузке данных' }))
            }
        }
        loadInfo()
        
    }, [])
    return <div className="container-fluid mx-auto px-3 px-md-5 py-5" style={{ maxWidth: '1500px' }}>
        { state.errorMsg && <ErrorMsg state={state} setState={setState} />}
        {!state.loaded ? <Loader/> : <>
        <p className="fs-4">Хартл Собеседование | {state.candidate.last_name + ' ' + state.candidate.first_name[0] + '.' + 
            (state.candidate.middle_name ? state.candidate.middle_name[0] + '.' : '')} | Результаты</p>
        <hr className="my-3"/>
            {state.scripts.length > 0 ? <>
                <select className="form-select mb-3" style={{ cursor: 'pointer' }}
                    onChange={e => setState(p => ({ ...p, current_script_index: p.scripts.indexOf(p.scripts.filter(el => el[0] === e.target.value)[0]) }))}>
                    {state.scripts.map((s,i) => <option style={{ cursor: 'pointer' }} key={i} value={s[0]}>{s[0]}</option>)}
                </select>
                {state.scripts[state.current_script_index][1].questions.map((q, i) => <Question key={i} data={q} />)}
                
            </> : <p className="text-center fs-3 m-5">Человечек не прошел собеседования!</p>}
        </>
        }
    </div>
}

const Question = ({ data }) => {
    return <div className="mt-4 ps-3" style={{ borderLeft: '2px solid ' + COLORS.primary }}>
        <p className="fs-5 mb-2">{data.question}</p>
        {data.answers.filter(a => typeof a !== 'string').map((a,i) => <div key={i} className="d-flex flex-row mb-3">
            <p className="my-auto fs-5 me-4">{a[0]}</p>
            <button className="btn primary text-center" style={{ width: '40px', height: '40px' }}>{a[1]}</button>
        </div>)}
        <div className="d-flex flex-row flex-wrap">
            {data.answers.filter(a => typeof a === 'string').map((a,i) => <button key={i} className="btn primary py-2 px-3 rounded-3 me-3 mb-3">{a}</button>)}
        </div>
    </div>
}