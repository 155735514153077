import styles from './AppCheckbox.module.scss'

export const AppCheckbox = ({ className, onChange, value, ...props }) => {
	return (
		<label
			className={styles.switch + ' d-flex align-items-center ' + className}
			{...props}
		>
			<input
				class='form-check-input my-auto'
				type='checkbox'
				checked={value}
				id='flexCheckDefault'
				onChange={onChange}
			/>
		</label>
	)
}
