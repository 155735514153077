import { useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { LogoutModal } from '../components/Modals'
import { COLORS, SOURCE } from '../constants'
import { showErrorMsg } from '../store/slices/alertSlice'
import { setUser } from '../store/slices/userSlice'
import { getMyVacanciesAsync } from '../store/slices/vacanciesSlice'
import classes from './MainHeader.module.scss'
import { DefaultAvatar } from './ui/DefaultAvatar'
import {employerGetMe} from "../api/employers"

const URL = SOURCE.new_url
export const MainHeader = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const loc = useLocation()
	const user = useSelector(state => state.userSlice.user)
	const vacanciesSlice = useSelector(state => state.vacanciesSlice)

	const [state, setState] = useState({
		logoutModalShown: false,
	})

	useEffect(() => {
		const navbarCollapse = document.getElementById('responsive-navbar-nav')
		if (navbarCollapse && navbarCollapse.classList.contains('show')) {
			navbarCollapse.classList.remove('show')
		}
	}, [loc.pathname])

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await employerGetMe(user.access_token, ["meetings"])

				if (response === null) throw new Error()

				if (response.status === 429) {
					dispatch(showErrorMsg('Слишком частые запросы'))
					navigate('/spam')
					return
				}

				if (!response.ok) throw new Error()

				const res = await response.json()
				if (res.error === 'Token is invalid') {
					navigate('/logout')
				}

				dispatch(
					setUser({
						...res,
						access_token: user.access_token,
						loaded: true,
					})
				)
				if (!vacanciesSlice.vacanciesInfo.loaded)
					dispatch(
						getMyVacanciesAsync({
							include: ['responses.candidate.resume', 'guestResponses'],
						})
					)
			} catch (error) {
				dispatch(
					showErrorMsg('Такого аккаунта не существует. Попробуйте позже.')
				)
				navigate('/logout')
				console.log(error)
			}
		}

		if (user.access_token !== undefined) {
			fetchData()
		}
	}, [user.access_token])

	return (
		<div className='py-2' style={{ transition: 'none', outline: 'none' }}>
			<LogoutModal shown={state.logoutModalShown} setState={setState} />

			<Navbar collapseOnSelect expand='md'>
				<Navbar.Brand className='me-5'>
					<Nav.Link eventKey='1'>
						<img
							src={SOURCE.source_url + 'hurtle-logo.svg'}
							style={{ cursor: 'pointer', height: '35px' }}
							onClick={() => navigate('/')}
							alt=''
						/>
					</Nav.Link>
				</Navbar.Brand>

				<Navbar.Toggle
					aria-controls='responsive-navbar-nav'
					bsPrefix='no_outline'
				>
					<img
						className='d-block d-md-none'
						src={SOURCE.source_url + 'menu.svg'}
						style={{ height: '30px' }}
						alt=''
					/>
				</Navbar.Toggle>

				<Navbar.Collapse id='responsive-navbar-nav'>
					<div className='d-flex flex-wrap-reverse flex-md-row flex-column w-100'>
						<Nav className='me-auto my-auto order-md-1 order-2'>
							{loc.pathname.includes('/account') &&
							user.access_token !== undefined ? (
								<AccountNav pathname={loc.pathname} />
							) : (
								<DefaultNav pathname={loc.pathname} />
							)}
						</Nav>

						<Nav className='mt-md-0 mt-3 order-md-2 order-1'>
							{user.access_token === undefined ? (
								<DefaultRightBar />
							) : !user.loaded ? (
								<AccountRightBarPlaceholder />
							) : (
								<AccountRightBar user={user} setState={setState} />
							)}
						</Nav>
					</div>
				</Navbar.Collapse>
			</Navbar>
		</div>
	)
}

const DefaultNav = ({ pathname }) => {
	const navigate = useNavigate()
	return (
		<>
			<Nav.Link
				className='text-nowrap pe-4'
				onClick={() => navigate('/')}
				eventKey='1'
				style={{
					color: pathname === '/' ? COLORS.black : COLORS.header_text,
					fontWeight: '600',
					fontSize: '17px',
					outline: 'none',
				}}
			>
				Для работодателей
			</Nav.Link>
			<Nav.Link
				className='text-nowrap pe-4'
				onClick={() => window.open('https://hurtle.ru/')}
				eventKey='2'
				style={{
					color: COLORS.header_text,
					fontWeight: '600',
					fontSize: '17px',
					outline: 'none',
				}}
			>
				Для соискателей
			</Nav.Link>
		</>
	)
}

const AccountNav = ({ pathname }) => {
	const navigate = useNavigate()

	return (
		<>
			<Nav.Link
				className='pe-4'
				onClick={() => navigate('/account')}
				eventKey='1'
				style={{
					color:
						pathname.includes('account') && !pathname.includes('settings')
							? COLORS.first_text
							: COLORS.header_text,
					fontWeight: '600',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Главное
			</Nav.Link>

			<Nav.Link
				onClick={() => navigate('/account/settings')}
				eventKey='2'
				style={{
					color: pathname.includes('/settings')
						? COLORS.first_text
						: COLORS.header_text,
					fontWeight: '600',
					fontSize: '18px',
					outline: 'none',
				}}
			>
				Настройки
			</Nav.Link>
		</>
	)
}

const DefaultRightBar = () => {
	const navigate = useNavigate()

	return (
		<div className='d-inline-flex flex-row flex-wrap-reverse align-items-center justify-content-start justify-content-md-end text-nowrap no_outline'>
			<Nav.Link
				onClick={() => navigate('/auth', { state: { isAuth: false } })}
				eventKey='3'
				className='btn me-4 mb-2 px-4 py-2 rounded-pill black'
			>
				Регистрация
			</Nav.Link>

			<Nav.Link
				onClick={() => navigate('/auth', { state: { isAuth: true } })}
				className='btn rounded-pill py-2 px-4 mb-2 black_outline'
				eventKey='2'
			>
				Войти
			</Nav.Link>
		</div>
	)
}

const AccountRightBar = ({ setState, user }) => {
	const navigate = useNavigate()

	return (
		<div className='d-flex flex-row-reverse flex-md-row no_outline justify-content-end justify-content-md-start'>
			<div className='d-flex flex-column align-items-end me-3 mt-1'>
				<p className='text-wrap text-end align-items-end d-none d-lg-block'>
					{user.name}
				</p>

				<div className={classes.LogoutContainer}>
					<Nav.Link
						eventKey='1'
						className={classes.Logout + ' fs-6 p-0'}
						onClick={() => setState(p => ({ ...p, logoutModalShown: true }))}
					>
						Выйти
					</Nav.Link>
				</div>
			</div>

			<div className='my-auto me-md-0 me-3'>
				<Nav.Link
					eventKey='2'
					className='p-0'
					onClick={() => navigate('/account')}
				>
					<DefaultAvatar
						src={SOURCE.new_url + `employers/${user.id}/avatar`}
						className=' img-fluid'
						alt='user'
						style={{
							objectFit: 'cover',
							width: '50px',
							height: '50px',
							borderRadius: '15px',
							cursor: 'pointer',
							flexShrink: 0,
						}}
					/>
				</Nav.Link>
			</div>
		</div>
	)
}

const AccountRightBarPlaceholder = () => {
	const navigate = useNavigate()
	return (
		<div className='d-flex flex-row-reverse flex-md-row no_outline justify-content-end justify-content-md-start placeholder-wave'>
			<div className='d-flex flex-column me-3 mt-1' style={{ width: '5rem' }}>
				<p className='text-end align-items-end d-none d-md-block w-100'>
					<span className='placeholder col-12'></span>
				</p>
				<div
					className='p-0 text-decoration-none align-self-end text-nowrap my-auto w-75'
				>
					<span className='placeholder col-12'></span>
				</div>
			</div>
			<div className='my-auto me-md-0 me-3'>
				<div
					onClick={() => navigate('/account')}
					className='d-flex lh-lg align-items-center justify-content-center placeholder'
					style={{
						fontSize: '18px',
						width: '50px',
						height: '50px',
						borderRadius: '15px',
						cursor: 'pointer',
						color: '#000',
					}}
				></div>
			</div>
		</div>
	)
}
