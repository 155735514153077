import { useState } from "react"
import { COLORS, SOURCE } from "../../constants"
import { ReactComponent as FileSvg } from "../../icons/file.svg"

export const Contacts = () => {
    const [state, setState] = useState({
        contact: ''
    })
    return <div>
        <div className="row gap-3">
            <div className="col flex-column">
                <p className="fs-2 mb-4" style={{ color: COLORS.primary, fontWeight: '600' }}>Как с нами связаться</p>
                <div className="d-flex flex-column mb-4">
                    <p className="fs-4 mb-3" style={{ color: COLORS.text_primary, fontWeight: '600' }}>Контактный центр</p>
                    <div className="row gap-3 ms-1 fs-6">
                        <div className="col-auto rounded-3 px-3 py-2" onClick={() => window.open('https://t.me/hurtle_support_bot')} style={{ border: '1px solid ' + COLORS.primary, cursor: 'pointer' }}>
                            <p className="underline" style={{ color: COLORS.primary }}>@hurtle_support_bot</p>
                        </div>
                        <div className="col-auto rounded-3 px-3 py-2" onClick={() => window.open('mailto:hello@hurtle.ru')} style={{ border: '1px solid ' + COLORS.primary, cursor: 'pointer'  }}>
                            <p className="underline" style={{ color: COLORS.primary }}>hello@hurtle.ru</p>
                        </div>
                        <div className="col-auto rounded-3 px-3 py-2" onClick={() => window.open('tel:+74991131433')} style={{ border: '1px solid ' + COLORS.primary, cursor: 'pointer'  }}>
                            <p className="underline" style={{ color: COLORS.primary }}>+7 (499) 113-14-33</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column mb-4">
                    <p className="fs-4 mb-3" style={{ color: COLORS.text_primary, fontWeight: '600' }}>Аккаунты в социальных сетях</p>
                    <div className="row gap-3 ms-1 fs-6">
                        <div className="col-auto rounded-3 px-3 py-2" onClick={() => window.open('https://vk.com/hurtleru')} style={{ border: '1px solid ' + COLORS.primary, cursor: 'pointer' }}>
                            <p className="underline" style={{ color: COLORS.primary }}>vk.com/hurtleru</p>
                        </div>
                        <div className="col-auto rounded-3 px-3 py-2" onClick={() => window.open('https://t.me/hurtleru')} style={{ border: '1px solid ' + COLORS.primary, cursor: 'pointer'  }}>
                            <p className="underline" style={{ color: COLORS.primary }}>t.me/hurtleru</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column mb-4">
                    <p className="fs-4 mb-3" style={{ color: COLORS.text_primary, fontWeight: '600' }}>Для средств массовой информации</p>
                    <div className="row gap-3 ms-1 fs-6">
                        <div className="col-auto rounded-3 px-3 py-2" onClick={() => window.open('mailto:pr@hurtle.ru')} style={{ border: '1px solid ' + COLORS.primary, cursor: 'pointer' }}>
                            <p className="underline" style={{ color: COLORS.primary }}>pr@hurtle.ru</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <p className="fs-4 mb-3" style={{ color: COLORS.text_primary, fontWeight: '600' }}>Прием претензий</p>
                    <div className="row gap-3 ms-1 fs-6">
                        <div className="col-auto rounded-3 px-3 py-2" onClick={() => window.open('mailto:trouble@hurtle.ru')} style={{ border: '1px solid ' + COLORS.primary, cursor: 'pointer' }}>
                            <p className="underline" style={{ color: COLORS.primary }}>trouble@hurtle.ru</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto d-lg-block d-none">
                <div style={{ height: '100%', width: '1px', backgroundColor: '#C7C8C9' }} />
            </div>
            <div className="col-lg" style={{ color: COLORS.text_primary, fontWeight: '600' }}>
                <p className="fs-2 mb-4" style={{ color: COLORS.primary }}>Данные об организации</p>
                <p className="fs-5 mb-3">Полное наименование: <span style={{ fontWeight: '500' }}>Общество с ограниченной ответственностью “Хартл”.</span></p>
                <p className="fs-5 mb-3">Сокращенное наименование: <span style={{ fontWeight: '500' }}>ООО “Хартл”.</span></p>
                <p className="fs-5 mb-3">Фирменное наименование: <span style={{ fontWeight: '500' }}>Хартл.</span></p>
                <p className="fs-5 mb-3">Зарегистрировано: <span style={{ fontWeight: '500' }}>за основным государственным регистрационным номером (ОГРН) 1235000023191 (дата внесения записи 01.03.2023 г.), ИНН 5044138439, КПП 504401001.</span></p>
                <p className="fs-5 mb-3">Место нахождения: <span style={{ fontWeight: '500' }}>141531, Московская область, г.о. Солнечногорск, д. Хметьево, д. 6А.</span></p>
                <hr className="my-4" />
                <div className="row gap-3">
                    <div className="col-auto" style={{ cursor: 'pointer' }} onClick={() => window.open('https://inc.hse.ru/')}><img style={{ height: 'auto', width: '100%', maxWidth: '300px' }} alt='' src={SOURCE.source_url + 'bi.svg'} /></div>
                    <div className="col-auto" style={{ cursor: 'pointer' }} onClick={() => window.open('https://i.moscow/')}><img style={{ height: 'auto', width: '100%', maxWidth: '300px' }} alt="" src={SOURCE.source_url + 'mic.svg'} /></div>
                </div>
            </div>
        </div>
        <hr className="my-5" />
        <div className="row gap-5 flex-wrap-reverse justify-content-between">
            <div className="col d-flex flex-column justify-content-center">
                <p className="fs-1 mb-3" style={{ color: COLORS.primary, fontWeight: '600' }}>Партнерство на раннем этапе</p>
                <p className="fs-5 mb-4" style={{ color: COLORS.text_primary }}>Вы заинтересованы в нашем проекте? Вероятно, и мы заинтересованы в вашем.
                    Оставьте свой контакт в этой простой форме и мы свяжемся с вами как можно скорее.
                    Ну а если вы хотите подробнее познакомиться с нашим проектом — вы можете скачать презентацию по ссылке ниже</p>
                <div className="row gap-4 mb-4">
                    <div className="col" style={{ minWidth: '200px' }}>
                        <label  className="ms-3 position-absolute">
                            <span className="h6 small bg-white px-1" style={{ color: COLORS.text_primary }}>Ваш контакт</span>
                        </label>
                        <input className="form-control p-3" style={{ marginTop: '12px' }} value={state.contact} onChange={e => setState(p => ({ ...p, contact: e.target.value.trim()}))} />
                    </div>
                    
                    <div className="col-auto d-flex align-items-end">
                        <div onClick={() => sendContact(state.contact, setState)} className="col-auto btn px-3 py-3 rounded-3 primary" style={{ color: '#fff', backgroundColor: COLORS.primary, minWidth: '200px' }}>Отправить</div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between p-3 rounded-3" onClick={() => window.open(SOURCE.source_url + 'presentation.pdf')} style={{ backgroundColor: COLORS.light_primary, cursor: 'pointer' }}>
                    <div className="d-flex flex-row">
                        <FileSvg className="me-3" alt='' />
                        <p className="my-auto primary" style={{ color: COLORS.primary, fontWeight: '600' }}>Скачать презентацию</p>
                    </div>
                    <p style={{ color: COLORS.header_text }}>9.2 mb</p>
                </div>
            </div>
            <div className='col-md-6 col-lg-4 col-12 d-flex align-items-center'>
                <img style={{ height: 'auto', width: '100%', maxWidth: '400px' }} className='m-auto' src={SOURCE.source_url + 'cat6.png'} alt='' />
            </div>
        </div>
    </div>
}

export function sendContact(contact, setState) {
    if (contact !== '')
        // поменять здесь api "/api/v1/utils/contact"
        fetch(SOURCE.url + '/api/v1/utils/contact',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    contact: contact
                })
            }).then(response => response.json()).then(r => {
                if (r === 'Готово') {
                    setState({ contact: '' })
                }
            }).catch((error) => {
                console.log(error)
            }) 
}