// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CandidateCard_TitleSection__Y1v7z {\n  display: flex;\n  gap: 23px;\n  height: 55px;\n}\n\n.CandidateCard_Avatar__Gf2oJ {\n  width: 55px;\n  height: 100%;\n  border-radius: 4px;\n  box-shadow: 0px 0px 12px 0px rgba(103, 87, 241, 0.3019607843);\n}\n\n.CandidateCard_Skills__PYXw2 {\n  display: flex;\n  gap: 16px;\n}\n.CandidateCard_Skills__PYXw2 .CandidateCard_Skill__6MKqs {\n  color: #fff;\n  padding: 7px 14px;\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 15.34px;\n  border-radius: 4px;\n  background: #FF9900;\n}\n\n.CandidateCard_NameContainer__0VA2\\+ {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-evenly;\n}", "",{"version":3,"sources":["webpack://./src/screens/account/layouts/main/myCandidates/CandidateCard.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,YAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,6DAAA;AADF;;AAIA;EACE,aAAA;EACA,SAAA;AADF;AAGE;EACE,WAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;AADJ;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,6BAAA;AAFF","sourcesContent":["\n\n.TitleSection {\n  display: flex;\n  gap: 23px;\n  height: 55px;\n}\n\n.Avatar {\n  width: 55px;\n  height: 100%;\n  border-radius: 4px;\n  box-shadow: 0px 0px 12px 0px #6757F14D;\n}\n\n.Skills {\n  display: flex;\n  gap: 16px;\n\n  .Skill {\n    color: #fff;\n    padding: 7px 14px;\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 15.34px;\n    border-radius: 4px;\n    background: #FF9900;\n  }\n}\n\n.NameContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-evenly;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleSection": "CandidateCard_TitleSection__Y1v7z",
	"Avatar": "CandidateCard_Avatar__Gf2oJ",
	"Skills": "CandidateCard_Skills__PYXw2",
	"Skill": "CandidateCard_Skill__6MKqs",
	"NameContainer": "CandidateCard_NameContainer__0VA2+"
};
export default ___CSS_LOADER_EXPORT___;
