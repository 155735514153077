import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import {
	COLORS,
	colorsByVacancyStatus,
	labelsByVacancyStatus,
} from '../../../../../constants'
import { ReactComponent as CrossedEyeSvg } from '../../../../../icons/crossed-eye-black.svg'
import { ReactComponent as EditPenSvg } from '../../../../../icons/edit-pen.svg'
import { ReactComponent as EyeSvg } from '../../../../../icons/eye-black.svg'
import { ReactComponent as EyeStatsSvg } from '../../../../../icons/eye_stats.svg'
import { ReactComponent as MoreActionsSvg } from '../../../../../icons/more_actions.svg'
import { ReactComponent as PeopleStatsSvg } from '../../../../../icons/people_stats.svg'
import { ReactComponent as TrashBinSvg } from '../../../../../icons/trash-bin.svg'
import {
	addVacancyAsync,
	deleteVacancyAsync,
	updateVacancyAsync,
} from '../../../../../store/slices/vacanciesSlice'
import { NavbarToBackSample } from './../../../../../components/NavbarToBackSample'
import classes from './MyVacancies.module.scss'

const MyVacancies = ({ short = false }) => {
	const vacancies = useSelector(
		state => state.vacanciesSlice.vacanciesInfo.vacancies
	)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const addVacancyHandler = async () => {
		const r = dispatch(
			addVacancyAsync({
				vacancy: {
					city: '',
					experience: 'NO_EXPERIENCE',
					name: 'Новая вакансия',
					description: '',
					teamRole: 'COLLABORATIVE_WORK',
					shortDescription: null,
					salary: 0,
					salaryCurrency: 'RUB',
					employmentType: 'FULL_TIME',
					reportingForm: 'INDEPENDENT_WORK',
					workingHours: 'GROUP_WORK',
					workplaceModel: 'OFFICE',
					isHidden: false,
					keySkills: [''],
				},
			})
		)
		const newVacancy = r.payload
		if (newVacancy?.id) navigate(`/account/vacancies/${newVacancy?.id}/edit`)
	}
	return (
		<div className={classes.Container}>
			{short ? <div
					className={`d-flex ${
						short ? 'flex-column' : 'flex-row justify-content-between'
					}`}
				>
					<div className='fs-3' style={{ fontWeight: '600' }}>
						Мои вакансии{' '}
						{!short ? (
							<span style={{ color: COLORS.gray_text }}>
								({vacancies.length})
							</span>
						) : (
							''
						)}
					</div>

					<button className={classes.LinkText} onClick={addVacancyHandler}>
						Создать вакансию
					</button>
				</div> : 
			<NavbarToBackSample
				last_children={
					<button
						className={
							classes.LinkText +
							' ms-auto d-flex mb-0 h-100 align-items-center'
						}
						onClick={addVacancyHandler}
					>
						Создать вакансию
					</button>
				}
			>
				<p className='fs-5 fw-bold d-none d-md-flex align-items-center'>
					Мои вакансии
				</p>
			</NavbarToBackSample>
				
			}

			{(vacancies || []).length === 0 && (
				<span className='fs-6 w-100 text-center' color={COLORS.gray_text}>
					У вас пока нет вакансий
				</span>
			)}
			{!short && (
				<div className='d-none d-md-block row'>
					<div
						className='col-7 d-flex justify-content-end text-muted'
						style={{ fontWeight: 600 }}
					>
						<div className='pe-2 me-3'>Просмотры</div>
						<div className='ps-4 pe-3 me-4'>Отклики</div>
					</div>
				</div>
			)}
			<div className='d-flex flex-column' >
				{(vacancies || [])
					.slice(0, short ? 3 : vacancies.length)
					.map((el, i) =>
						short ? (
							<Vacancy vacancy={el} key={i} />
						) : (
							<ApartVacancy vacancy={el} key={i} />
						)
					)}
			</div>

			{short && (vacancies || []).length !== 0 && (
				<Link className={classes.LinkTextBottom} to='vacancies'>
					Посмотреть все вакансии
				</Link>
			)}
		</div>
	)
}

const Vacancy = ({ vacancy }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [dropdownOpened, setDropdown] = useState(false)

	const toggleVacancyHidden = () => {
		dispatch(
			updateVacancyAsync({
				id: vacancy.id,
				vacancy: {
					isHidden: !vacancy.isHidden,
					_requester: 'EMPLOYER',
				},
			})
		)
	}

	const openDropdown = () => setDropdown(true)
	const closeDropdown = () => setDropdown(false)
	return (
	<div className={classes.Item + ' bg-white rounded w-100'} onClick={() => navigate(`/account/vacancies/${vacancy.id}`)}
	key={vacancy.id} style={{ padding: '19px 22px' }}>
		<div className='row'>
			<div className={classes.ItemText + ' col'}>
				{vacancy.isHidden && <CrossedEyeSvg className='me-2 mb-1' />}
				{vacancy.name}
			</div>

			<div className={classes.IconsContainer + ' col-sm-4 col-auto'}>
				<div className={classes.NumberOfView + ' pe-sm-5 pe-3'}>
					<EyeStatsSvg className={classes.Icon} />
					{vacancy.viewersCount || 0}
				</div>

				<div className={classes.NumberOfView}>
					<PeopleStatsSvg className={classes.Icon} />
					{(vacancy.responses?.length || 0) +
						(vacancy.guestResponses?.length || 0)}
				</div>
			</div>

			<div
				className='position-relative col-auto'
				onClick={e => e.stopPropagation()}
			>
				<button
					className='no_outline'
					onMouseEnter={openDropdown}
					onClick={() => openDropdown()}
				>
					<MoreActionsSvg className={classes.Icon} />
				</button>
				<div
					className={`${classes.Dropdown} ${
						dropdownOpened ? classes.DropdownOpened : ''
					}`}
					onMouseLeave={closeDropdown}
				>
					<div
						className={classes.DropdownItem}
						onClick={() => navigate(`/account/vacancies/${vacancy.id}/edit`)}
					>
						<EditPenSvg fill='#000' />
						<span className='fs-6 fw-semibold'>Редактировать</span>
					</div>
					<div
						className={classes.DropdownItem}
						onClick={async () =>
							dispatch(deleteVacancyAsync({ id: vacancy.id }))
						}
					>
						<TrashBinSvg />
						<span className='fs-6 fw-semibold'>Удалить</span>
					</div>

					<div className={classes.DropdownItem} onClick={toggleVacancyHidden}>
						{vacancy.isHidden ? <EyeSvg /> : <CrossedEyeSvg />}
						<span className='ms-1 fs-6 fw-semibold'>
							{vacancy.isHidden ? 'Опубликовать' : 'Скрыть'}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	)
}

const ApartVacancy = ({ vacancy }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [dropdownOpened, setDropdown] = useState(false)

	const toggleVacancyHidden = async () => {
		dispatch(
			updateVacancyAsync({
				id: vacancy.id,
				vacancy: {
					isHidden: !vacancy.isHidden,
					_requester: 'EMPLOYER',
				},
			})
		)
	}

	const openDropdown = () => setDropdown(true)
	const closeDropdown = () => setDropdown(false)
	return <div className={classes.Item + ' bg-white rounded'} key={vacancy.id} onClick={() => navigate(`/account/vacancies/${vacancy.id}`)} style={{ padding: '19px 22px' }}>
			<div
				className={ ' row text-nowrap'}
				
			>
				<div
					className={
						classes.ReverseRow1 +
						' col-md-7 col-12 d-flex justify-content-between'
					}
				>
					<div
						className={classes.ItemText + ' d-flex flex-row align-items-center'}
					>
						{vacancy.isHidden && <CrossedEyeSvg className='me-2 mb-1' />}
						{vacancy.name}
					</div>
					<div
						className={
							classes.IconsContainer + ' d-flex justify-content-between me-md-4'
						}
					>
						<div
							className={
								classes.NumberOfView +
								' d-flex pe-sm-5 me-sm-4 pe-3 align-items-center'
							}
						>
							<EyeStatsSvg className={classes.Icon} />
							{vacancy.viewersCount || 0}
						</div>

						<div
							className={
								classes.NumberOfView + ' d-flex px-md-4 align-items-center'
							}
						>
							<PeopleStatsSvg className={classes.Icon} />
							{(vacancy.responses?.length || 0) +
								(vacancy.guestResponses?.length || 0)}
						</div>
					</div>
				</div>
				<div
					className={
						classes.ReverseRow2 +
						' col-md-5 col-12 d-flex justify-content-between'
					}
				>
					<div className='d-flex w-100'>
						<div
							className='rounded-3 py-1 py-xxl-2 px-4 me-auto'
							style={{
								background: colorsByVacancyStatus[vacancy.status],
								color: vacancy.status === 'HIDDEN' ? COLORS.black : 'white',
							}}
						>
							{labelsByVacancyStatus[vacancy.status]}
						</div>
						<div
							className={'position-relative d-flex align-items-center'}
							onClick={e => e.stopPropagation()}
						>
							<button
								className='no_outline '
								onMouseEnter={openDropdown}
								onClick={() => openDropdown()}
							>
								<MoreActionsSvg className={classes.Icon} />
							</button>
							<div
								className={`${classes.Dropdown} ${
									dropdownOpened ? classes.DropdownOpened : ''
								}`}
								onMouseLeave={closeDropdown}
							>
								<div
									className={classes.DropdownItem}
									onClick={() =>
										navigate(`/account/vacancies/${vacancy.id}/edit`)
									}
								>
									<EditPenSvg fill='#000' />
									<span className='fs-6 fw-semibold'>Редактировать</span>
								</div>
								<div
									className={classes.DropdownItem}
									onClick={async () =>
										await dispatch(deleteVacancyAsync({ id: vacancy.id }))
									}
								>
									<TrashBinSvg />
									<span className='ms-1 fs-6 fw-semibold'>Удалить</span>
								</div>
								<div
									className={classes.DropdownItem}
									onClick={toggleVacancyHidden}
								>
									{vacancy.isHidden ? <EyeSvg /> : <CrossedEyeSvg />}
									<span className='ms-1 fs-6 fw-semibold'>
										{vacancy.isHidden ? 'Опубликовать' : 'Скрыть'}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	</div>
}

export default MyVacancies
