import * as yup from 'yup'
import {SOURCE, yupOneOfEnum} from "../../../constants"
import {showErrorMsg, showSuccessMsg} from "../../../store/slices/alertSlice"
import {setUser} from "../../../store/slices/userSlice"
import {employerDeleteMe, employerPatchMe} from "../../../api/employers"

const BasicEmployerSchema = yup.object({
    contact: yup.string().trim().min(1, 'Контакт обязателен').max(36, 'Контакт до 36 символов'),
    firstName: yup.string().trim().min(2, 'Неверная запись имени').max(50, 'Имя до 50 символов'),
    middleName: yup.string().trim().min(1).max(50, 'Отчество до 50 символов').nullable(),
    lastName: yup.string().trim().min(2).max(50, 'Фамилия до 50 символов'),
    phone: yup.string().trim().min(2, 'неверная запись телефона').max(15, 'телефон до 15символов').nullable(),
    city: yup.string().trim().min(2, 'Город должен быть от 2 символов').max(255, 'Город должен быть до 255 символов').nullable(),
    name: yup.string().trim().min(2, 'Имя от 2 символов'),
    inn: yup.string().trim().min(10, 'Неверная запись ИНН').max(12, 'Неверная запись ИНН').nullable(),
    ogrn: yup.string().trim().length(13, 'Неверная запись ОГРН').nullable(),
    legalForm: yupOneOfEnum(["INDIVIDUAL_ENTREPRENEUR", "OOO", "ZAO", "PAO", "NKO"]),
    isStartup: yup.boolean(),
    size: yupOneOfEnum(["LESS_THAN_15", "BETWEEN_15_AND_50", "BETWEEN_50_AND_100", "BETWEEN_100_AND_500", "MORE_THAN_500"]),
    website: yup.string().min(4, 'Неверная ссылка').max(100, 'Ссылка длинной до 100 символов').nullable(),
    description: yup.string().trim().max(3000, 'Описание до 3000 символов').nullable(),
})

export async function patchMyAccount(access_token, user, patchUser, dispatch) {

    try {
		await BasicEmployerSchema.validate(patchUser)
	} catch (err) {
		dispatch(showErrorMsg(err.errors))
		return
	}

    const res = await employerPatchMe(access_token, patchUser)

    if (res !== null && res.ok) {
        await dispatch(showSuccessMsg("Данные сохранены"))
        await dispatch(setUser({...user, ...patchUser}))
    } else {
        const data = await res.json()
        if (data.error) await dispatch(showErrorMsg("Произошла ошибка"))
    }
}

export const deleteMyAccount = async (access_token, navigate) => {
    const res = await employerDeleteMe(access_token)
    if (res !== null && res.ok) navigate("/logout")
}