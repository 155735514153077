export const DefaultAvatar = ({ src = '', type = 'b2b', ...props }) => {
	return (
		<object data={src} type='image/png' {...props}>
			<img
				alt='avatar'
				style={{ ...props.styles, objectFit: 'cover' }}
				src={require(`../../icons/default-avatar-${type}.svg`)}
				{...props}
			/>
		</object>
	)
}
