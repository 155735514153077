import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { AppInput } from '../../../components/ui/AppInput'
import { COLORS, SOURCE } from '../../../constants'
import { showErrorMsg, showSuccessMsg } from '../../../store/slices/alertSlice'
import { setAuth } from '../../../store/slices/userSlice'
import { Loader } from '../../../components/Loader'
import { useSearchParams } from "react-router-dom"

const url = SOURCE.url

export const EnterWithMail = () => {
  const [state, setState] = useState('')
  const [codeIsSend, setCodeIsSend] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(state => state.userSlice.user)

  useEffect(() => {
    async function f() {
      if (state) {
        const code = await getEnterCode(
          user.access_token,
          setState,
          dispatch,
          state
        )
        setCodeIsSend(code)
      }
    }
    f()
  }, [state])

  return state && codeIsSend ? (
    <EnterMail mail={state} />
  ) : (
    <InputMail setState={setState} />
  )
}

const InputMail = ({ setState }) => {
  const [mail, setMail] = useState('')

  const refreshMail = () => {
    setState(mail)
  }
  return (
    <form
      onSubmit={e => e.preventDefault()}
      className='container d-flex flex-column justify-content-center w-100 my-auto'
      style={{ maxWidth: '900px' }}
    >
      <p className='text-center mb-3 fs-1' style={{ fontWeight: '600' }}>
        Вход по почте
      </p>
      <p className='text-center mb-5 fs-5' style={{ fontWeight: '300' }}>
        Мы отправим тебе на почту код подтверждения, скорее вводи её ниже
      </p>
      <AppInput className='w-50 mx-auto' label='Почта' value={mail} onChange={setMail} />

      <div className='container' style={{ maxWidth: '600px' }}>
        <div className='d-flex justify-content-between align-items-center mb-4 flex-wrap'>
          <button
            onClick={refreshMail}
            className='btn btn-md py-2 text-nowrap mx-auto primary px-sm-5 p-2 rounded-pill'
          >
            Отправить код
          </button>
        </div>
      </div>
    </form>
  )
}
export const EnterMail = ({ mail }) => {
  const [state, setState] = useState({
    code: '',
    disabled: false,
  })
  const user = useSelector(state => state.userSlice.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (state.disabled)
      setTimeout(() => {
        setState(p => ({ ...p, disabled: false }))
      }, 30000)
  }, [state.disabled])

  return (
    <form
      onSubmit={e => e.preventDefault()}
      className='container d-flex flex-column justify-content-center w-100 my-auto'
      style={{ maxWidth: '900px' }}
    >
      <p className='text-center mb-3 fs-1' style={{ fontWeight: '600' }}>
        Вход по почте
      </p>
      <p className='text-center mb-5 fs-5' style={{ fontWeight: '300' }}>
        Мы отправим тебе на{' '}
        <span className='fw-bolder' style={{ color: COLORS.purple }}>
          {mail}
        </span>{' '}
        код подтверждения, скорее вводи его здесь
      </p>
      <SeparateCodeInput
        isLoading={state.isLoading}
        callback={code =>
          submitConfirmCode(
            code,
            mail,
            navigate,
            dispatch,
            setState
          )
        }
      />
    </form>
  )
}

export const SeparateCodeInput = ({ isLoading, callback }) => {
  const [code, setCode] = useState('')
  const CODE_LENGTH = 6
  // Refs to control each digit input element
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]

  // Reset all inputs and clear state
  const resetCode = () => {
    inputRefs.forEach(ref => {
      ref.current.value = ''
    })
    inputRefs[0].current.focus()
    setCode('')
  }

  // Call our callback when code = 6 chars
  useEffect(() => {
    if (code.length === CODE_LENGTH) {
      if (typeof callback === 'function') callback(code)
      resetCode()
    }
  }, [code]) //eslint-disable-line

  function handleInput(e, index) {
    const input = e.target
    const previousInput = inputRefs[index - 1]
    const nextInput = inputRefs[index + 1]
    if (!/^[0-9]\d*$/.test(input.value) && input.value !== '') return
    // Update code state with single digit
    const newCode = [...code]
    newCode[index] = input.value
    setCode(newCode.join(''))

    input.select()

    if (input.value === '') {
      // If the value is deleted, select previous input, if exists
      if (previousInput) {
        previousInput.current.focus()
      }
    } else if (nextInput) {
      // Select next input on entry, if exists
      nextInput.current.select()
    }
  }

  // Select the contents on focus
  function handleFocus(e) {
    e.target.select()
  }

  // Handle backspace key
  function handleKeyDown(e, index) {
    const input = e.target
    const previousInput = inputRefs[index - 1]
    const nextInput = inputRefs[index + 1]

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === '') {
      e.preventDefault()
      setCode(prevCode => prevCode.slice(0, index) + prevCode.slice(index + 1))
      if (previousInput) {
        previousInput.current.focus()
      }
    }
  }

  // Capture pasted characters
  const handlePaste = e => {
    const pastedCode = e.clipboardData.getData('text')
    if (pastedCode.length === CODE_LENGTH) {
      setCode(pastedCode)
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index)
      })
    }
  }
  return (
    <div className='mx-auto d-flex justify-content-center mt-5 mb-4'>
      {[...Array(CODE_LENGTH).keys()].map(index => (
        <>
          <input
            className='form-control fs-4 px-2 text-center mx-1 mx-sm-2 rounded-2'
            key={index}
            style={{ color: COLORS.primary, maxWidth: '40px' }}
            type='text'
            inputmode='numeric'
            maxLength={1}
            value={code[index] || ''}
            onChange={e => handleInput(e, index)}
            ref={inputRefs[index]}
            autoFocus={index === 0}
            onFocus={handleFocus}
            onKeyDown={e => handleKeyDown(e, index)}
            onPaste={handlePaste}
            disabled={isLoading}
          />
          {index === 2 && <p className='fs-2'>-</p>}
        </>
      ))}
    </div>
  )
}

export async function getEnterCode(access_token, setState, dispatch, mail) {
  const response = await fetch(SOURCE.new_url + 'auth/getEmailCode', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: access_token,
    },
    body: JSON.stringify({
      email: mail,
      role: 'EMPLOYER',	
    }),
  }).catch(e => {
    dispatch(showErrorMsg('Ошибка на сервере!'))
    return false
  })

  if (response.ok) {
    // setState(prev => ({
    // 	...prev,
    // 	disabled: true,
    // }))
    dispatch(showSuccessMsg('Код отправлен'))
    return true
  } else {
    dispatch(showErrorMsg('Почта не подтверждена!'))
    return false
  }
}

async function submitConfirmCode(code, mail, navigate, dispatch) {
  try {
    const response = await fetch(SOURCE.new_url + "auth/withEmailCode", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: mail,
          code: code,
          role: 'EMPLOYER'
        })
    })
    if (!response.ok && response.status !== 401) return false
    const result = await response.json()
    if (result.token) {
      dispatch(setAuth({ access_token: result.token }))
      navigate('/auth/next', { state: { step: 'login' } })
    }
    return result
} catch {
    dispatch(showErrorMsg('Неверный код!'))
    return false
}
}

export const ApplyEnterMail = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const code = searchParams.get('code')
  const email = searchParams.get('email')
  const dispatch = useDispatch()
  useEffect(() => {
      submitConfirmCode(code, email, navigate, dispatch)
  }, [])
  return <Loader />
}