import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { COLORS } from '../../constants'

const VideoPlayer = ({ url }) => {
  const [error, setError] = useState('')
  return error ? <>
    <p className='fs-6 text-center my-5' style={{ color: COLORS.danger }}>{error}</p>
    <p style={{ cursor: 'pointer' }} className='fs-6 text-center' onClick={() => window.open(url)}>Посмотреть по ссылке</p>
  </>
    : <div className='w-100 rounded-4' style={{ overflow: 'hidden' }}>
      <ReactPlayer
        url={url} // замените на URL вашего видео
        controls={true}
        playing={true}
        width='100%'
        onL
        onError={() => setError('Ваш браузер не поддерживает этот видеоформат ;(')}
      />
    </div>
}

export default VideoPlayer