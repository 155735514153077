import {Navigate, Route, Routes} from "react-router"
import {useDispatch, useSelector} from "react-redux"
import {Main} from "./layouts/main/Main"
import {Settings} from "./layouts/settings/Settings"
import {Vacancy} from "./vacancy/Vacancy"
import {Loader} from "../../components/Loader"
import {Meetings} from "./layouts/Meetings"
import {EditUserCard} from "./layouts/edit_page/EditUserCard"
import MyVacancies from "./layouts/main/MyVacancies/MyVacancies"
import { NotFoundPage } from "../system/NotFoundPage"
import { useEffect } from "react"
import { getMyVacanciesAsync } from "../../store/slices/vacanciesSlice"
import { EditVacancy } from "./vacancy/EditVacancy/EditVacancy"


export const AccountScreen = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.userSlice.user)
    const vacanciesSlice = useSelector(state => state.vacanciesSlice)
    useEffect(() => {}, [])
    return (
        user.access_token === undefined ? (
            <Navigate to='/'/>
        ) : (
            !(user.loaded && vacanciesSlice.vacanciesInfo.loaded) ? (
                <Loader/>
            ) : (
                <div className="mb-5">

                    <Routes>
                        <Route path='/' element={<Main/>}/>
                        <Route path='/vacancies' element={<MyVacancies short={false}/>}/>
                        <Route path='/vacancies/:id/*' element={<Vacancy/>}/>
                        <Route path='meetings' element={<Meetings/>}/>
                        <Route path='settings' element={<Settings/>}/>
                        <Route path='edit_user_card' element={<EditUserCard/>}/>
                <Route path='/*' element={<NotFoundPage/>}/>
                    </Routes>
                </div>
            )
                
        )
    )
}


